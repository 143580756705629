import { fetchWithPost } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';

export const verifyPhoneNumber = async (data: any): Promise<IResponse<any>> => {
    const response = await fetchWithPost(`verify-phone`, data);
    return response;
};

export const verifyPhoneByOtp = async (data: any): Promise<IResponse<any>> => {
    const response = await fetchWithPost('verify-phone-otp', data);
    return response;
};
export const registerAccountByUserId = async (
    data: any
): Promise<IResponse<any>> => {
    const response = await fetchWithPost('register', data);
    return response;
};
