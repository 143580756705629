import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getActivityPerformance } from '../../api/reports';
import {
    IActivityData,
    IActivityListData,
    ICoinPerformanceListData
} from '../../components/Chart/ActivityChart/ActivityChart.type';
import { showToast } from '../../utils/notifications.util';

type InitialState = {
    loading: boolean;
    fulfilled: boolean;
    failed: boolean;
    performanceChartLineColor: string;
    activityChartLineColor: string;
    MultiChartLineColor: string;
    data: IActivityData;
};
const initialState: InitialState = {
    data: {
        beginning_value: 0,
        ending_value: 0,
        gain_loss: 0,
        net_additions: 0,
        activity_list: []
    },
    performanceChartLineColor: '#3556F2',
    activityChartLineColor: '#27C51D',
    MultiChartLineColor: '#58508d',
    loading: false,
    fulfilled: false,
    failed: false
};

export interface IFetchReportProps {
    duration: string;
    marketValue: string;
    userId: string;
    selectedHoldingId?: string;
    accountId?: string;
}

export const fetchReportAsync = createAsyncThunk(
    'reports/fetchAsync',
    async ({
        duration,
        marketValue,
        userId,
        selectedHoldingId,
        accountId
    }: IFetchReportProps) => {
        const response = await getActivityPerformance(
            duration,
            marketValue ?? 'emv',
            userId ?? '',
            selectedHoldingId ?? '',
            accountId ?? ''
        );
        if (!response.success) {
            showToast(false, response.message);
        }
        return response.success ? response.data : initialState.data;
    }
);

export const activityPerformanceSlice = createSlice({
    name: 'ActivityPerformance',
    initialState,
    reducers: {
        getPerformanceData: (state, action) => {
            console.log('Reducer', action);
        },
        resetPerformanceData: (state, action) => {
            state = { ...initialState };
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchReportAsync.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            fetchReportAsync.fulfilled,
            (state, action: PayloadAction<any>) => {
                const btcPerformance = new Map();
                const btcPrice = new Map();
                // // TODO: Issue with array at 0 index. It should be directly accessible
                if (action?.payload?.activity_list.length > 0) {
                    action.payload.coin_performance[0].forEach(
                        (element: ICoinPerformanceListData) => {
                            btcPerformance.set(
                                element.date,
                                element.performance
                            );
                            btcPrice.set(element.date, element.price);
                        }
                    );
                    const newActivityList = action.payload.activity_list.map(
                        (element: IActivityListData) => {
                            return {
                                ...element,
                                btc_performance: btcPerformance.get(
                                    element.date
                                ),
                                btc_price: btcPrice.get(element.date)
                            };
                        }
                    );
                    return {
                        ...state,
                        failed: false,
                        fulfilled: true,
                        loading: false,
                        data: {
                            ...action.payload,
                            activity_list: newActivityList
                        }
                    };
                }
                if (action?.payload?.activity_list.length <= 0) {
                    const newActivityList: any = [];
                    return {
                        ...state,
                        failed: false,
                        fulfilled: true,
                        loading: false,
                        data: {
                            ...action.payload,
                            activity_list: newActivityList
                        }
                    };
                }
            }
        );
        builder.addCase(
            fetchReportAsync.rejected,
            (state, action: PayloadAction<any>) => {
                return {
                    ...state,
                    failed: true,
                    fulfilled: false,
                    loading: false,
                    data: initialState.data
                };
            }
        );
    }
});

export const { getPerformanceData, resetPerformanceData }: any =
    activityPerformanceSlice.actions;
export default activityPerformanceSlice.reducer;
