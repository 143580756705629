import DateSelector from '../../components/DateSelector';
import HOCGainLoss from '../../components/GainLoss/HOCGainLoss';
import Layout from '../../components/Layout';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
const GainLossPage = () => {
    const { accountId }: any = useParams();
    return (
        <Layout activeItem='Gain/Loss'>
            <DateSelector
                {...{
                    title: 'Gain/Loss',
                    page: `gain-loss`
                }}
            />

            <div className={styles.mainContainer}>
                <HOCGainLoss
                    isOverviewHeader={false}
                    showMethodSelection={true}
                    accountId={accountId}
                />
            </div>
        </Layout>
    );
};

export default GainLossPage;
