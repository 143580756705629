import React, { useEffect, useState } from 'react';
import { LS_THEME_KEY, LS_USER_KEY } from '../utils/constants.util';
import { getLocalValue, setLocalValue } from '../utils/local-storage.util';

type AppSettingValueContextType = {
    dateFormat?: string;
    setDateFormat: Function;
    marketValue?: string;
    setMarketValue: Function;
    reportingCurrency?: string | null;
    setReportingCurrency: Function;
    portfolioName?: string | null;
    setPortfolioName: Function;
    assetAllocation: number;
    setAssetAllocation: Function;
    costBasis?: string;
    setCostBasis: Function;
    theme?: string;
    setTheme: Function;
    generateReportLoading: boolean;
    setGenerateReportLoading: Function;
};
//   date_format                dateFormat
//   name                       marketValue
//   reporting_currency         reportingCurrency
//   portfolio_name             portfolioName
//   asset_other_percentage     assetAllocation
//   cost_basis_method          costBasis
//   generateReportLoading      Gain/Loss generateReport

let DateContext = React.createContext<AppSettingValueContextType>(null!);

export const DateProvider: React.FC = ({ children }) => {
    const user =
        getLocalValue(LS_USER_KEY) && JSON.parse(getLocalValue(LS_USER_KEY));

    const defaultTheme: string | undefined | null =
        getLocalValue(LS_THEME_KEY) && getLocalValue(LS_THEME_KEY);

    const settings = user.settings;

    const [dateFormat, setDateFormat] = useState(
        settings?.date_format ? settings?.date_format : 'MM-DD-YYYY'
    );
    const [marketValue, setMarketValue] = useState(
        settings?.name ? settings?.name : 'emv'
    );
    const [generateReportLoading, setGenerateReportLoading] = useState(
        settings?.generateReportLoading
            ? settings?.generateReportLoading
            : false
    );
    const [reportingCurrency, setReportingCurrency] = useState(
        settings?.reporting_currency ? settings?.reporting_currency : 'USD'
    );
    const [portfolioName, setPortfolioName] = useState(
        settings?.portfolio_name ? settings?.portfolio_name : ''
    );
    const [assetAllocation, setAssetAllocation] = useState(
        settings?.asset_other_percentage ? settings?.asset_other_percentage : 1
    );
    const [costBasis, setCostBasis] = useState(
        settings?.cost_basis_method ? settings?.cost_basis_method : 'FIFO'
    );

    const defaultDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
    ).matches;

    const [theme, setTheme] = useState(() => {
        if (defaultTheme != null && defaultTheme !== '') {
            return defaultTheme;
        } else {
            return defaultDark ? 'dark' : 'light';
        }
    });

    useEffect(() => {
        setLocalValue(
            LS_USER_KEY,
            JSON.stringify({
                ...user,
                settings: {
                    ...user.settings,
                    date_format: dateFormat,
                    name: marketValue,
                    asset_other_percentage: assetAllocation,
                    reporting_currency: reportingCurrency,
                    portfolio_name: portfolioName,
                    cost_basis_method: costBasis,
                    generateReportLoading: generateReportLoading,
                    configuration:
                        marketValue === 'fmv'
                            ? 0
                            : marketValue === 'bmv'
                            ? 1
                            : 2
                }
            })
        );
    }, [
        assetAllocation,
        dateFormat,
        portfolioName,
        costBasis,
        reportingCurrency,
        marketValue,
        generateReportLoading
    ]);

    useEffect(() => {
        setLocalValue(LS_THEME_KEY, theme);
    }, [theme]);

    const date: AppSettingValueContextType = {
        dateFormat,
        setDateFormat,
        setMarketValue,
        marketValue,
        reportingCurrency,
        setReportingCurrency,
        portfolioName,
        setPortfolioName,
        assetAllocation,
        setAssetAllocation,
        costBasis,
        setCostBasis,
        theme,
        setTheme,
        generateReportLoading,
        setGenerateReportLoading
    };

    return <DateContext.Provider value={date}>{children}</DateContext.Provider>;
};

export const useAppSetting = () => {
    return React.useContext(DateContext);
};
