import CryptoJS from 'crypto-js';

export const encryption = (value: string, key: string) => {
    const encryptToken = CryptoJS.AES.encrypt(value, key).toString();
    return encryptToken;
};
export const decryption = (value: string, key: string) => {
    const decryptValue = CryptoJS.AES.decrypt(value, key);
    const decryptToken = decryptValue.toString(CryptoJS.enc.Utf8);
    return decryptToken;
};
