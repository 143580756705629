import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userSetting } from '../../api/user';
import Layout from '../../components/Layout';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import { IUserSetting } from '../../interfaces/user';
import { COST_BASIS_METHOD, THEME_TYPES } from '../../utils/constants.util';
import { showToast } from '../../utils/notifications.util';
import styles from './index.module.css';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { type } from 'os';
const { Option } = Select;

const schema = yup.object().shape({
    portfolioName: yup.string(),
    assetAllocation: yup
        .number()
        .required('field required ')
        .min(1, `Min number should be ${1}`)
        .max(100, `Max number should be ${100}`)
});

const UserSetting = () => {
    const { push } = useHistory();
    const { register, handleSubmit, formState } = useForm<any>({
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const {
        setDateFormat,
        setMarketValue,
        dateFormat,
        marketValue,
        reportingCurrency,
        setReportingCurrency,
        costBasis,
        setCostBasis,
        assetAllocation,
        setAssetAllocation,
        portfolioName,
        setPortfolioName,
        setTheme,
        theme
    } = useAppSetting();

    const [dateFormatter, setDateFormatter] = useState(dateFormat);
    const [loading, setLoading] = useState(false);
    const [assetAllocationValue, setAssetAllocationValue] =
        useState(assetAllocation);
    const [marketFormatter, setMarketFormatter] = useState(marketValue);
    const [reportingCurrencyValue, setReportingCurrencyValue] =
        useState(reportingCurrency);
    const [costBasisValue, setCostBasisValue] = useState(costBasis);
    const [themeValue, setThemeValue] = useState(theme);

    useEffect(() => {
        document
            .getElementsByTagName('HTML')[0]
            .setAttribute('data-theme', `${theme}`);
    }, []);

    const setUserSetting: SubmitHandler<any> = async (data: any) => {
        setTheme(themeValue);
        setLoading(true);
        const newData: IUserSetting = {
            name: marketFormatter,
            configuration:
                marketFormatter === 'FMV'
                    ? 0
                    : marketFormatter === 'BMV'
                    ? 1
                    : 2,
            date_format: dateFormatter,
            cost_basis_method: costBasisValue,
            asset_other_percentage: data.assetAllocation,
            reporting_currency: reportingCurrencyValue,
            portfolio_name: data.portfolioName
        };

        try {
            const result = await userSetting(newData);
            if (result.success) {
                setDateFormat(dateFormatter);
                setMarketValue(marketFormatter);
                setReportingCurrency(reportingCurrencyValue);
                setCostBasis(costBasisValue);
                setPortfolioName(data.portfolioName);
                setAssetAllocation(data.assetAllocation);
                setAssetAllocationValue(data.assetAllocation);
                push(`/overview`);
                setTheme(themeValue);
                showToast(true, result.message);
            } else {
                showToast(false, result.message);
            }
        } catch (error) {
            console.log('error', error);
            showToast(false, 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout activeItem={'User Settings'} data-theme={theme}>
            <form
                className={styles.mainContainer}
                onSubmit={handleSubmit(setUserSetting)}
            >
                <h2 className='headingColor'>User Settings</h2>
                <h3 className={styles.link}>Reset Settings</h3>
                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Theme Color</h3>
                        <Select
                            value={themeValue}
                            className={styles.DropDownMenu}
                            onChange={(value: string) => setThemeValue(value)}
                        >
                            <Option value='' disabled>
                                Select theme
                            </Option>
                            {THEME_TYPES.map((item: string) => (
                                <Option value={item}>
                                    {item.toUpperCase()}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <span className='spanColor'>Select the color theme</span>
                </div>
                <br />
                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Date Formatting</h3>
                        <Select
                            value={dateFormatter}
                            className={styles.DropDownMenu}
                            onChange={(value: string) =>
                                setDateFormatter(value)
                            }
                        >
                            <Option value='' disabled>
                                Select Date Format
                            </Option>
                            {[
                                'MM-DD-YYYY',
                                'MM-DD-YY',
                                'MM/DD/YYYY',
                                'MM/DD/YY'
                            ].map((item: string) => (
                                <Option value={item}>
                                    {item.toLowerCase()}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <span className='spanColor'>
                        Select the format in which the dates will be displayed
                    </span>
                </div>
                <br />

                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Market Value</h3>
                        <Select
                            value={marketFormatter}
                            className={styles.DropDownMenu}
                            onChange={(value: string) =>
                                setMarketFormatter(value)
                            }
                        >
                            <Option value='' disabled>
                                Select Option
                            </Option>
                            {['fmv', 'bmv', 'emv'].map((item: string) => (
                                <Option value={item}>
                                    {item.toUpperCase()}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <span className='spanColor'>
                        Select the base market value for report calculation.
                    </span>
                </div>
                <br />

                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Reporting Currency</h3>
                        <Select
                            value={reportingCurrencyValue}
                            className={styles.DropDownMenu}
                            onChange={(value: string) =>
                                setReportingCurrencyValue(value)
                            }
                        >
                            <Option value='' disabled>
                                Select Reporting Currency
                            </Option>
                            {['USD', 'USDT', 'BTC'].map((item: string) => (
                                <Option value={item}>{item}</Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <span className='spanColor'>
                        Select the Currency for report calculation
                    </span>
                </div>
                <br />

                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Portfolio Name</h3>
                        <input
                            className={styles.inputStyles}
                            placeholder='Please Enter Name'
                            defaultValue={portfolioName ? portfolioName : ''}
                            {...register('portfolioName')}
                        />
                    </div>
                    {errors?.portfolioName && (
                        <span className='error'>
                            {errors?.portfolioName.message}
                        </span>
                    )}
                    <br />
                    <span className='spanColor'>Add the Portfolio Name</span>
                </div>
                <br />

                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Asset Allocation</h3>
                        <input
                            type={'number'}
                            className={styles.inputStyles}
                            defaultValue={assetAllocationValue}
                            {...register('assetAllocation')}
                            placeholder='Please enter Asset Allocation'
                        />
                    </div>
                    {errors?.assetAllocation && (
                        <span className='error'>
                            {errors?.assetAllocation.message}
                        </span>
                    )}
                    <br />
                    <span className='spanColor'>
                        Add the Asset Allocation Other Category
                    </span>
                </div>
                <br />

                <div className={styles.formattingContainer}>
                    <div className={styles.formattingContainerSelector}>
                        <h3 className={'headingColor'}>Cost Basis Method</h3>
                        <Select
                            value={costBasisValue}
                            className={styles.DropDownMenu}
                            onChange={(value: string) =>
                                setCostBasisValue(value)
                            }
                        >
                            <Option value='' disabled>
                                Select Cost Basis Method
                            </Option>
                            {COST_BASIS_METHOD.map((item: string) => (
                                <Option value={item}>{item}</Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <span className='spanColor'>
                        Select the Cost Basis for report calculation
                    </span>
                </div>
                <br />

                <div className={styles.btnContainer}>
                    <Button
                        type={'primary'}
                        htmlType='submit'
                        className={styles.btn}
                        loading={loading}
                    >
                        Save Changes
                    </Button>
                </div>
            </form>
        </Layout>
    );
};

export default UserSetting;
