import { Avatar, List, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { createAccount } from '../../api/account';
import { ICreataAccountData } from '../../interfaces/account';
import { BASE_URL } from '../../utils/constants.util';
import styles from './CreateAccount.module.css';

const CreateAccount = (props: any) => {
    const { push } = useHistory();

    const handleOnCustodianSelection = async (custodian_id: any) => {
        try {
            const data: ICreataAccountData = {
                custodian_id: custodian_id
            };
            props.setVisible(false);
            props.setLoading(true);
            const result = await createAccount(data);
            if (result.success) {
                push(`/csv-import/${result.data.id}`);
            } else {
                props.setVisible(true);
                message.error(result.message);
            }
        } catch (error) {
            props.setVisible(true);
            message.error('Something went wrong!!!');
        } finally {
            props.setLoading(false);
        }
    };

    return props.data.length !== 0 ? (
        <List
            className={styles.listWrapper}
            itemLayout='horizontal'
            dataSource={props.data}
            loading={props.loading}
            renderItem={(item: any) => {
                return (
                    <div
                        className={styles.listItem}
                        onClick={() => handleOnCustodianSelection(item.id)}
                    >
                        <Avatar
                            size={32}
                            src={`${BASE_URL}/${item.image_link}`}
                        />
                        <div>{item.name}</div>
                    </div>
                );
            }}
        />
    ) : (
        <span className={styles.textBackgroundColor}>
            Your are already registered in all of our accounts
        </span>
    );
};

export default CreateAccount;
