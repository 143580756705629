import Layout from '../../components/Layout';
import DateSelector from '../../components/DateSelector';
import styles from './index.module.css';
import { useEffect, useState } from 'react';
import PerformanceChart from '../../components/Chart/PerformanceChart';
import { useParams } from 'react-router-dom';

const PerformancePage = () => {
    const [duration, setDuration] = useState('as_of_date');
    const { accountId }: any = useParams();

    useEffect(() => {}, [duration]);

    return (
        <Layout activeItem={'Portfolio Performance'}>
            <DateSelector
                {...{
                    title: 'Portfolio Performance',
                    duration,
                    setDuration,
                    page: 'performance'
                }}
            />
            <div className={styles.mainContainer}>
                <PerformanceChart
                    label={''}
                    defaultDataRepresentation={'chart'}
                    showTable={true}
                    duration={duration}
                    size={'large'}
                    accountId={accountId}
                    isOverviewHeader={false}
                />
            </div>
        </Layout>
    );
};

export default PerformancePage;
