import React, { useState } from 'react';

type DataSettingContextType = {
    navbarOpen: boolean;
    setNavbarOpen: Function;
    navbarClose: boolean;
    setNavbarClose: Function;
};

let DataSetting = React.createContext<DataSettingContextType>(null!);

export const DataSettingProvider: React.FC = ({ children }) => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [navbarClose, setNavbarClose] = useState(false);

    const data: DataSettingContextType = {
        navbarOpen,
        setNavbarOpen,
        navbarClose,
        setNavbarClose
    };

    return <DataSetting.Provider value={data}>{children}</DataSetting.Provider>;
};

export const useDataSetting = () => {
    return React.useContext(DataSetting);
};
