export const PLATFORM_NAME = process.env.REACT_APP_PLATFORM_NAME;
export const ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = BASE_URL + '/api/v1/';
export const SECRET_TOKEN_KEY = process.env.REACT_APP_SECRET_TOKEN_KEY;
export const OTP_MAX_DIGIT: string = process.env.REACT_OTP_MAX_DIGIT ?? '6';
export const OTP_MAX_ATTEMPT: string = process.env.REACT_OTP_MAX_ATTEMPT ?? '3';
export const MIN_PASSWORD_CHAR: string =
    process.env.REACT_MIN_PASSWORD_CHAR ?? '8';
export const MAX_PASSWORD_CHAR: string =
    process.env.REACT_MAX_PASSWORD_CHAR ?? '124';
export const DEFAULT_PAGE_SIZE = 10;
export const PASSWORD_REGEX = /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

// Local Storage keys
export const LS_ACCESS_TOKEN_KEY = 'at';
export const LS_REFRESH_TOKEN_KEY = 'rt';
export const LS_USER_KEY = 'u';
export const LS_THEME_KEY = 'theme';
export const LS_ACCOUNT_KEY = 'ac';

export const DURATION_LIST = [
    { label: 'As of Date', value: 'as_of_date' },
    { label: 'MTD', value: 'mtd' },
    { label: 'Last 7 days', value: 'last_7d' },
    { label: 'Last 14 days', value: 'last_14d' },
    { label: 'QTD', value: 'qtd' },
    { label: 'YTD', value: 'ytd' },
    { label: 'Last 12 Months', value: 'last_12m' },
    { label: 'Since Inception', value: 'since_inception' }
];

export const COST_BASIS_METHOD = ['FIFO', 'LIFO'];
export const THEME_TYPES = ['dark', 'light'];
export const COST_BASIS_METHOD_ENUM = [
    { label: 'FIFO', value: 'FIFO' },
    { label: 'LIFO', value: 'LIFO' }
];
export const POSITION_TABLE_FILTERS = [
    { label: 'None', value: 'none' },
    { label: 'Top Performer', value: 'performer' },
    { label: 'Top Gainers', value: 'gainer' },
    { label: 'Top Losers', value: 'loser' },
    { label: 'Top By Volume', value: 'volume' }
];
export const POSITION_TABLE_COLUMN = [
    { label: 'Name', value: 'asset' },
    { label: 'Quantity', value: 'amount' },
    { label: 'Last Price', value: 'previous_rate' },
    { label: '$ Change', value: 'change_in_dollars' },
    { label: '% Change', value: 'change_in_percentage' },
    { label: 'Current Value', value: 'current_value' },
    { label: '$ Daily gain/loss', value: 'daily_gain_loss_dollars' },
    { label: '% Daily gain/loss', value: 'daily_gain_loss_percentage' },
    { label: '$ Total gain/loss', value: 'total_gain_loss_dollars' },
    { label: '% Total gain/loss', value: 'total_gain_loss_percentage' },
    { label: 'Cost basis per share', value: 'cost_basis_per_share' },
    { label: 'Cost basis Total', value: 'cost_basis_total' }
];

// For Responsiveness
export const TABLET_SIZE = 770;
