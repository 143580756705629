import { currencyToUSD } from '../../utils/other.util';
import { IGainLossProps } from './GainLoss.type';
import styles from './index.module.css';

const HeadingData = ({ label = '', value = 0, isOverviewHeader=false }) => {
    return (
        <div>
            <span className={isOverviewHeader?styles.headingOverview:styles.heading}>{label}</span>
            <br />
            <span className={isOverviewHeader?styles.headingValueOverview:styles.headingValue}>{currencyToUSD(value)}</span>
        </div>
    );
};
const FooterData = ({ label = '', value = '', type = '', isOverviewHeader=false }) => {
    return (
        <div className={styles.footer}>
            {isOverviewHeader?
            <div
            className={styles.footerHeadingOverview}
        >
            {label}
        </div>:<h3
                className={
                    type === 'heading'
                        ? `${styles.footerMainHeading}`
                        : `${styles.footerHeading}`
                }
            >
                {label}
            </h3>
            }
            {isOverviewHeader?
            <div
            className={styles.footerHeadingOverview}
        >
            {value}
        </div>:
            <p
            className={
                type === 'heading'
                    ? `${styles.footerMainHeading}`
                    : `${styles.footerHeading}`
            }
        >
            {value}
        </p>
            }
            
        </div>
    );
};

const GainLossComponent: React.FC<IGainLossProps> = ({
    data,
    isOverviewHeader = false
}: IGainLossProps) => {
    return (
        <div className={styles.mainContainer}>
            <div
                className={
                    isOverviewHeader
                        ? styles.headingWrapperOverview
                        : styles.headingWrapper
                }
            >
                <HeadingData
                    isOverviewHeader={isOverviewHeader}
                    label='Unrealized'
                    value={data?.urgl_in_usd}
                />
                <span className={styles.headingValue}>+</span>
                <HeadingData
                    isOverviewHeader={isOverviewHeader}
                    label='Realized'
                    value={data?.rgl_in_usd}
                />
                <span className={styles.headingValue}>=</span>
                <HeadingData
                    isOverviewHeader={isOverviewHeader}
                    label='Total'
                    value={data?.urgl_in_usd + data?.rgl_in_usd}
                />
            </div>
            <br />

            <div>
                {isOverviewHeader?  null:<FooterData
                    label={'Unrealized Gain/Loss'}
                    value={currencyToUSD(data?.urgl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'heading'}
                />}
                
                <FooterData
                    label={isOverviewHeader? 'Short-Term  ( URGL )':'Short-Term'}
                    value={currencyToUSD(data?.st_ugl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'notHeading'}
                />
                <FooterData
                    label={isOverviewHeader? 'Long-Term ( URGL )': 'Long-Term'}
                    value={currencyToUSD(data?.lt_ugl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'notHeading'}
                />
                <div className={isOverviewHeader?styles.marginBottomFooterOverview:styles.marginBottomFooter}></div>
                {isOverviewHeader? null:<FooterData
                    label={'Realized Gain/Loss'}
                    value={currencyToUSD(data?.rgl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'heading'}
                />}
                
                <FooterData
                    label={isOverviewHeader? 'Short-Term ( RGL )': 'Short-Term '}
                    value={currencyToUSD(data?.st_rgl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'notHeading'}
                />
                <FooterData
                    label={isOverviewHeader? 'Long-Term ( RGL )': 'Long-Term'}
                    value={currencyToUSD(data?.lt_rgl_in_usd)}
                    isOverviewHeader={isOverviewHeader}
                    type={'notHeading'}
                />
            </div>
        </div>
    );
};

export default GainLossComponent;
