import React from 'react';
import { useAuth } from '../../hooks/useAuth.hook';
import { useAppDispatch } from '../../app/redux-hooks';
import { resetPerformanceData } from '../../app/slices/activityPerformance.slice';
import { resetFeeAllocationData } from '../../app/slices/feeAllocation.slice';
import { resetAssetAllocationGraphData } from '../../app/slices/assetAllocation.slice';
const Logout: React.FC = () => {
    const dispatch = useAppDispatch();
    const auth = useAuth();
    React.useEffect(() => {
        dispatch(resetPerformanceData());
        dispatch(resetFeeAllocationData());
        dispatch(resetAssetAllocationGraphData());
        auth.logout(() => {
            window.location.reload();
        });
    }, [auth.user?.user?.id]);

    return <></>;
};

export default Logout;
