import { Layout } from 'antd';
import styles from './index.module.css';
import { useAuth } from '../../../hooks/useAuth.hook';
import DrawerPage from '../../Drawer/Drawer';
import { getHoldingAllocation } from '../../../api/holding';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { currencyToUSD } from '../../../utils/other.util';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';

const { Header } = Layout;

export interface ITopNavBarProps {
    title?: string;
    activeItem?: string;
}

const TopNavBar: React.FC<ITopNavBarProps> = ({
    title,
    activeItem
}: ITopNavBarProps) => {
    const { portfolioName, marketValue } = useAppSetting();
    const auth = useAuth();
    const { isLoading, isFetching, data } = useQuery<any, any>(
        ['AssetAllocation'],
        () => getHoldingAllocation(auth.user?.user?.id ?? '', marketValue)
    );

    const portfolioValue = useMemo(() => {
        if (isLoading || isFetching) return;
        const { success, data: allocationData } = data;
        if (!success) return;
        let sum = 0;
        allocationData.forEach((item: any) => {
            sum += item.amount_in_usd;
        });
        return sum;
    }, [isLoading, isFetching]);

    return (
        <Header className={styles.headerContainer}>
            <DrawerPage activeItem={activeItem} />
            <h3 className={styles.mainHeading}>
                {title != null ? (
                    <>{title}</>
                ) : (
                    <>
                        Welcome, {}
                        {portfolioName === ''
                            ? `${auth.user?.user.first_name} ${auth.user?.user.last_name} Portfolio`
                            : `${portfolioName}`}
                    </>
                )}{' '}
                | {currencyToUSD(portfolioValue ?? 0)}
            </h3>
            <div className={styles.subscriptionText}>
                {auth.user?.role} Subscription
            </div>
        </Header>
    );
};

export default TopNavBar;
