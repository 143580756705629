import React, { useEffect, useMemo, useState } from 'react';
import { IAssetAllocationData } from '../../components/Chart/AssetAllocation/index.type';
import AssetAllocation from '../../components/Chart/AssetAllocation';
import DataTable from '../../components/DataTable';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth.hook';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import { currencyToUSD } from '../../utils/other.util';
import styles from './index.module.css';
import { useAppDispatch, useAppSelector } from '../../app/redux-hooks';
import {
    fetchAssetAllocationAsync,
    IFetchAssetAllocationProps
} from '../../app/slices/assetAllocation.slice';
import Spinner from '../../components/Spinner';
import AssetAllocationDropdown, {
    IAssetAllocationDropdownProps
} from '../../components/Chart/Dropdown/AllocationDropdown';
import CardHeader from '../../components/Common/CardHeader';
import DateSelector from '../../components/DateSelector';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';

const pieChartColumns = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Quantity',
        dataIndex: 'amount',
        key: 'amount'
    },
    {
        title: 'Amount (USD)',
        dataIndex: 'amount_in_usd',
        key: 'amount_in_usd'
    },
    {
        title: 'Cost Basis (USD)',
        dataIndex: 'cost_basis_fifo',
        key: 'cost_basis_fifo'
    }
];

interface RenderAllAccountDataProps {
    showOtherCategory: boolean;
    categoryOption: string;
    data: IAssetAllocationData[];
}

const RenderAllAccountData: React.FC<RenderAllAccountDataProps> = ({
    showOtherCategory = true,
    categoryOption = 'Chart',
    data = []
}: RenderAllAccountDataProps) => {
    return (
        <Space
            direction='vertical'
            size='large'
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <Space
                direction='horizontal'
                size='large'
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Space direction='vertical'>
                    <h2
                        style={{ textAlign: 'center' }}
                        className={styles.textColor}
                    >
                        Asset Allocation (FMV)
                    </h2>
                    <AssetAllocation
                        pieChartSize='large'
                        holdingAllocationData={data}
                        showOtherCategory={showOtherCategory}
                    />
                </Space>

                <Space direction='vertical'>
                    <h2
                        style={{ textAlign: 'center' }}
                        className={styles.textColor}
                    >
                        Asset Allocation (Costbasis)
                    </h2>
                    <AssetAllocation
                        pieChartSize='large'
                        holdingAllocationData={data.map(
                            (item: IAssetAllocationData) => {
                                return {
                                    ...item,
                                    amount_in_usd: item.cost_basis_fifo
                                };
                            }
                        )}
                        showOtherCategory={showOtherCategory}
                    />
                </Space>
            </Space>
            <DataTable
                style={{
                    flex: 1
                }}
                data={data.map(
                    (dataItem: IAssetAllocationData, index: number) => {
                        return {
                            ...dataItem,
                            key: `${dataItem.id}-${index}`,
                            cost_basis_fifo: currencyToUSD(
                                dataItem.cost_basis_fifo
                            ),
                            amount_in_usd: currencyToUSD(dataItem.amount_in_usd)
                        };
                    }
                )}
                columnData={pieChartColumns}
            />
        </Space>
    );
};

const AssetAllocationPage = () => {
    const { marketValue } = useAppSetting();
    const { accountId }: any = useParams();

    const selector = useAppSelector((state) => state.assetAllocationSelector);

    const dispatch = useAppDispatch();

    const auth = useAuth();
    const userId = useMemo(() => auth.user?.user?.id, [auth]);

    const [categoryOption, setCategoryOption] = useState('Chart');

    useEffect(() => {
        dispatch(
            fetchAssetAllocationAsync({
                marketValue,
                userId,
                accountId
            } as IFetchAssetAllocationProps)
        );
    }, []);

    return (
        <Layout activeItem='Asset Allocation'>
            <DateSelector
                {...{
                    title: 'Asset Allocation',
                    duration: '',
                    page: 'asset-allocation'
                }}
            />
            <div className={styles.mainContainer}>
                <CardHeader title='' isOverviewHeader={false}>
                    <>
                        {!selector.loading && (
                            <AssetAllocationDropdown
                                {...({
                                    category: categoryOption,
                                    setCategory: setCategoryOption,
                                    showCategoryDropdown: false
                                } as IAssetAllocationDropdownProps)}
                            />
                        )}
                    </>
                </CardHeader>

                {selector.loading ? (
                    <Spinner />
                ) : (
                    <>
                        {accountId == null ? (
                            <div className={styles.mainChartContainer}>
                                <RenderAllAccountData
                                    showOtherCategory={true}
                                    data={selector.graphData}
                                    categoryOption={categoryOption}
                                />
                            </div>
                        ) : (
                            <RenderAllAccountData
                                showOtherCategory={true}
                                data={selector.graphData}
                                categoryOption={categoryOption}
                            />
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
};

export default AssetAllocationPage;
