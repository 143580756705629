import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { zeroPad } from '../../utils/other.util';
import { IDateTimeProps, ITimerProps } from './Timer.type';



const base = '00:00';
const TimerComponent = ({
    minutes = 2,
    reRender,
    handleEnd = () => {}
}: ITimerProps) => {
    const [dateTime, setDataTime] = useState<IDateTimeProps>({
        current: `${zeroPad(minutes.toString(), 2)}:00`,
        tempDateTime: `2022-08-12 00:${minutes}:00`
    });

    useEffect(() => {
        if (dateTime.current === base)
            setDataTime({
                current: `${zeroPad(minutes.toString(), 2)}:00`,
                tempDateTime: `2022-08-12 00:${minutes}:00`
            });
    }, [reRender]);

    useEffect(() => {
        if (dateTime.current === base) {
            handleEnd();
        } else {
            setTimeout(() => {
                const tempDate = dateTime.tempDateTime;
                setDataTime({
                    current: dayjs(tempDate).format('mm:ss'),
                    tempDateTime: dayjs(tempDate)
                        .add(-1, 'seconds')
                        .format('YYYY-MM-DD HH:mm:ss')
                });
            }, 1000);
        }
    }, [dateTime]);

    return <span>{dateTime.current}</span>;
};

export default TimerComponent;
