import React, { useState } from 'react';
import { CoinMarketProps } from './CoinMarket.type';
import styles from './CoinMarket.module.css';
import { useQuery } from 'react-query';
import { getCoinMarket } from '../../api/reports';
import { IHoldingData } from '../../interfaces/holding';
import { Button, message, Space } from 'antd';
import Spinner from '../Spinner';
const CoinMarket: React.FC<CoinMarketProps> = ({
    holdingData,
    coinGeckoName
}: CoinMarketProps) => {
    let apiSentData: string = `coins=${coinGeckoName}`;

    //  IN CASE WE CALL ALL DATA FROM THE API

    //  IN CASE WE CALL ALL DATA FROM THE API

    // // console.log(holdingData);
    // // console.log('holdingData');

    // const values = holdingData.map((obj) => {
    //     return obj.asset_id;
    // });
    // // console.log(values);
    // // console.log('values');
    // for (let i = 0; i < values.length; i++) {
    //     if (i !== values.length - 1) {
    //         apiSentData += `coins=${values[i]}&`;
    //     }
    //     if (i === values.length - 1) {
    //         apiSentData += `coins=${values[i]}`;
    //     }
    // }
    // // console.log(apiSentData);
    // // console.log('apiSentData');

    //SET DATA IN A STATE LETS SEE

    //SET DATA IN A STATE LETS SEE

    const { isLoading, data } = useQuery<any, any>('Coin Market', () =>
        getCoinMarket(apiSentData)
    );
    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {data?.success ? (
                        <div className={styles.mainContainerCoinMarket}>
                            <Space
                                className={styles.coinDataTab}
                                direction='vertical'
                                align='center'
                            >
                                <div className={styles.textBold}>
                                    {data?.data?.[0]?.high_24h}
                                </div>
                                <div className={styles.textNormal}>
                                    24 Hour Trading Vol
                                </div>
                            </Space>
                            <Space
                                className={styles.coinDataTab}
                                direction='vertical'
                                align='center'
                            >
                                <div className={styles.textBold}>
                                    {data?.data?.[0]?.max_supply}
                                </div>
                                <div className={styles.textNormal}>
                                    Max Supply
                                </div>
                            </Space>
                            <Space
                                className={styles.coinDataTab}
                                direction='vertical'
                                align='center'
                            >
                                <div className={styles.textBold}>
                                    {data?.data?.[0]?.total_supply}
                                </div>
                                <div className={styles.textNormal}>
                                    Total Supply
                                </div>
                            </Space>
                            <Space
                                className={styles.coinDataTab}
                                direction='vertical'
                                align='center'
                            >
                                <div className={styles.textBold}>
                                    {data?.data?.[0]?.total_volume}
                                </div>
                                <div className={styles.textNormal}>
                                    Total Volume
                                </div>
                            </Space>
                            <Space
                                className={styles.buttonTicker}
                                direction='vertical'
                                align='center'
                            >
                                <a
                                    href={`https://www.coingecko.com/en/coins/${coinGeckoName}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    style={{ color: '#fff' }}
                                >
                                    Legend ticker
                                </a>
                            </Space>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
};
export default CoinMarket;
