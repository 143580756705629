import { Space } from 'antd';
import { ReactChildren } from 'react';
import styles from './index.module.css';

interface CardHeaderProps {
    title: string;
    children?: JSX.Element;
    isOverviewHeader: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
    return (
        <div className={props.isOverviewHeader?styles.mainSmallHeadingContainer:styles.mainHeadingContainer}>
            {
                props.isOverviewHeader? <h3 className={styles.mainHeading}>{props.title}</h3> :<h1 className={styles.mainHeading}>{props.title}</h1>
            }
            {/* <h1 className={styles.mainHeading}>{props.title}</h1> */}
            {props.children && <Space>{props.children}</Space>}
        </div>
    );
};

export default CardHeader;
