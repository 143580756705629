import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import TimerComponent from '../Timer';
import { OTP_MAX_DIGIT } from '../../utils/constants.util';
import styles from './OtpVerification.module.css';
import { IOtpVerificationProps } from './OtpVerification.type';

const OtpVerification = ({
    timeInMinutes = 2,
    attempt = 5,
    handleSubmit = () => {},
    handleResend = () => {}
}: IOtpVerificationProps) => {
    const maxInput: number = parseInt(OTP_MAX_DIGIT);

    const [otpValue, setOtpValue] = useState<number>();
    const [otpLength, setOtpLength] = useState<number>(0);
    const [showResendButton, setShowResendButton] = useState<boolean>(false);

    if (otpLength >= maxInput) {
        handleSubmit(otpValue);
    }

    const handelOtpChange = (otp: number) => {
        setOtpValue(otp);
        setOtpLength(otp.toString().length);
    };

    const onResendClick = () => {
        setShowResendButton(false);
        handleResend();
    };

    return attempt > 0 ? (
        <div className={styles.otpVerificationContainer}>
            <h4 className={styles.otpVerificationHeading}>OTP verification</h4>
            <h4 className={styles.otpVerificationSubHeading}>
                Enter the OTP sent to your phone
            </h4>
            <OtpInput
                containerStyle={styles.otpContainer}
                className={styles.otpInputWrapper}
                isInputNum={true}
                value={otpValue?.toString()}
                onChange={(otp: number) => handelOtpChange(otp)}
                numInputs={maxInput}
                separator={<span className={styles.otpInputSp}></span>}
            />
            <span
                className={`${styles.otpResendTimer} ${
                    showResendButton === true ? 'd-none' : 'd-initial'
                }`}
            >
                Resend in{' '}
                <TimerComponent
                    reRender={showResendButton}
                    minutes={timeInMinutes}
                    handleEnd={() => {
                        setShowResendButton(true);
                    }}
                />
            </span>
            <span
                className={`${styles.otpResendButton} ${
                    showResendButton === true ? 'd-initial' : 'd-none'
                }`}
                onClick={() => onResendClick()}
            >
                Resend OTP
            </span>
            <span
                className={`${styles.remainingAttempts} ${
                    showResendButton === true ? 'd-none' : 'd-initial'
                }`}
            >
                (Attempts Remaining: {attempt})
            </span>
        </div>
    ) : (
        <div className={styles.otpVerificationContainer}>
            <h4 className={`${styles.otpVerificationHeading} text-danger`}>
                You've reached the maximum attempts. Try again
            </h4>
        </div>
    );
};

export default OtpVerification;
