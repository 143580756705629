import {
    API_BASE_URL,
    BASE_URL,
    LS_ACCESS_TOKEN_KEY,
    LS_REFRESH_TOKEN_KEY
} from './constants.util';

import {
    removeLocalValue,
    setEncValue,
    getEncValue
} from './local-storage.util';

import IResponse from '../interfaces/handle-response.interface';

export const postRequestFactory = (
    data: string | any,
    method: string,
    headers: any | {} = {}
) => {
    const decryptValue = getEncValue(LS_ACCESS_TOKEN_KEY);
    const bearer = `Bearer ${decryptValue}`;
    const isFile = data instanceof FormData;

    const inputHeaders = { ...headers };
    if (!isFile) inputHeaders['Content-Type'] = 'application/json';

    return {
        method, // Method itself,
        mode: 'cors',
        headers: {
            credentials: 'include',
            Authorization: bearer,
            ...inputHeaders
        },
        body: data instanceof FormData ? data : JSON.stringify(data)
    };
};

export const handleResponse = async (
    response: any
): Promise<IResponse<any>> => {
    try {
        const status = response.status;
        const json = await response.json();

        switch (true) {
            case status >= 200 && status < 300: // Valid response status
                return {
                    success: true,
                    message: json.detail,
                    data: json.data,
                    totalPages: json.total,
                    pages: json.pages
                };
            case status === 422: // Validation error form API
                return { success: false, message: 'Failed', data: {} };
            case status >= 400 && status < 500: // Unauthorized or Forbidden
                return { success: false, message: json.detail, data: {} };
            default:
                return { success: false, message: 'Server Error!', data: {} };
        }
    } catch (error) {
        return { success: false, message: JSON.stringify(error), data: {} };
    }
};

export const fetchWithPost = async (
    endpoint: any,
    data: any,
    header: any | null = null,
    method = 'POST'
): Promise<IResponse<any>> => {
    const responseInit: ResponseInit = postRequestFactory(data, method, header);
    const response = await fetch(API_BASE_URL + endpoint, responseInit);
    return await handleResponse(response);
};

export const getRequestFactory = (headers: any | {} = null) => {
    const decryptValue = getEncValue(LS_ACCESS_TOKEN_KEY);
    const bearer = `Bearer ${decryptValue}`;

    return {
        method: 'GET',
        headers: {
            credentials: 'include',
            Authorization: bearer,
            'Content-Type': 'application/json',
            ...headers
        }
    };
};

export const fetchWithGet = async (
    endpoint: string,
    header: any | null = null
): Promise<IResponse<any>> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                API_BASE_URL + endpoint,
                getRequestFactory(header)
            );

            const apiResponse = await handleResponse(response);

            if (apiResponse.message === 'Unauthorized.') {
                refreshToken(
                    async () => {
                        const responseAfterRefresh = await fetch(
                            API_BASE_URL + endpoint,
                            getRequestFactory(header)
                        );
                        resolve(await handleResponse(responseAfterRefresh));
                    },
                    () => {
                        removeLocalValue(LS_ACCESS_TOKEN_KEY);
                        removeLocalValue(LS_REFRESH_TOKEN_KEY);
                        window.location.reload();
                    }
                );
            } else resolve(apiResponse);
        } catch (error) {
            reject({ success: false, message: 'Server Error', data: {} });
        }
    });
};

const refreshToken = async (callBack: Function, error: Function) => {
    try {
        const decryptedRefreshToken = getEncValue(LS_REFRESH_TOKEN_KEY);

        const token = { token: decryptedRefreshToken };

        const response = await fetch(API_BASE_URL + 'refresh', {
            method: 'POST', // Method itself,
            headers: {
                credentials: 'include',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(token)
        });
        const result: IResponse<any> = await handleResponse(response);

        if (result.success) {
            setEncValue(LS_ACCESS_TOKEN_KEY, result.data.access_token);
            setEncValue(LS_REFRESH_TOKEN_KEY, result.data.refresh_token);
            return callBack();
        }
    } catch (err) {
        return error();
    }
    error();
};

export const fetchDownloadFile = async (
    endpoint: string,
    header = null,
    fileName = 'file'
): Promise<IResponse<any>> => {
    try {
        const responseInit: ResponseInit = getRequestFactory({
            responseType: 'blob',
            header
        });
        const response = await fetch(BASE_URL + endpoint, responseInit);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();

        return {
            success: true,
            message: 'Errors found in the file.',
            data: blob
        };
    } catch (error) {
        return { success: false, message: 'Server Error', data: {} };
    }
};
export const fetchGenerateReportDownloadFile = async (
    endpoint: string,
    header = null,
    fileName = 'report.pdf'
): Promise<IResponse<any>> => {
    try {
        const responseInit: ResponseInit = getRequestFactory({
            responseType: 'blob',
            method: 'GET',
            header
        });

        const response = await fetch(
            `${BASE_URL}/api/v1/${endpoint}`,
            responseInit
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        return {
            success: true,
            message: 'Report file generated.',
            data: { blob, url, fileName }
        };
    } catch (error) {
        return { success: false, message: 'Server Error', data: {} };
    }
};
