import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import LoginPage from './pages/login';
import PrivateRoute from './utils/protected-route.util';
import RegistrationAccount from './pages/registration_account';
import CreateAccountPage from './pages/create_account';
import Logout from './pages/logout';
import CsvImport from './pages/csv_import';
import OverviewPage from './pages/overview';
import SummaryPage from './pages/summary';
import PerformancePage from './pages/performance';
import ChangePassword from './pages/change_password';
import UserSetting from './pages/user_settings';
import AssetAllocation from './pages/asset_allocation';
import TransactionPage from './pages/transaction';
import AccountPage from './pages/accounts';
import GainLossPage from './pages/gain_loss';
import PerformanceHoldingPage from './pages/performance_holding';
import CustodianAllocationPage from './pages/custodian_allocation';
import FeeAllocationPage from './pages/fee_allocation';
import PositionPage from './pages/position';
import { useAppSetting } from './hooks/useAppSetting.hooks';

function App() {
    const { theme } = useAppSetting();
    const [themeValue, setThemeValue] = useState(theme ? theme : 'light');
    useEffect(() => {
        document
            .getElementsByTagName('HTML')[0]
            .setAttribute('data-theme', `${themeValue}`);
    }, [themeValue]);
    return (
        <Router>
            <Switch>
                <Route exact path='/'>
                    <Redirect to='/overview' />
                </Route>

                <Route
                    exact
                    path='/login/reset-password'
                    component={LoginPage}
                />
                <Route exact path='/login' component={LoginPage} />

                <Route
                    path='/registration-account'
                    component={RegistrationAccount}
                />

                <Route
                    path='/create-account/:id'
                    component={CreateAccountPage}
                />

                <PrivateRoute path='/logout' Component={Logout} />
                <PrivateRoute path='/accounts' Component={AccountPage} />

                <PrivateRoute
                    path='/change-password'
                    Component={ChangePassword}
                />

                <PrivateRoute
                    exact
                    path='/overview/:accountId'
                    Component={OverviewPage}
                />
                <PrivateRoute exact path='/overview' Component={OverviewPage} />

                <PrivateRoute
                    exact
                    path='/overview/:accountId'
                    Component={OverviewPage}
                />
                <PrivateRoute path='/user-settings' Component={UserSetting} />
                <PrivateRoute exact path='/position' Component={PositionPage} />
                <PrivateRoute
                    exact
                    path='/position/:accountId'
                    Component={PositionPage}
                />

                <PrivateRoute
                    exact
                    path='/summary/:accountId'
                    Component={SummaryPage}
                />
                <PrivateRoute exact path='/summary' Component={SummaryPage} />

                <PrivateRoute
                    exact
                    path='/transaction/:accountId'
                    Component={TransactionPage}
                />
                <PrivateRoute
                    exact
                    path='/transaction'
                    Component={TransactionPage}
                />

                <PrivateRoute
                    exact
                    path='/asset-allocation/:accountId'
                    Component={AssetAllocation}
                />
                <PrivateRoute
                    exact
                    path='/asset-allocation'
                    Component={AssetAllocation}
                />
                <PrivateRoute
                    exact
                    path='/custodian-allocation'
                    Component={CustodianAllocationPage}
                />
                <PrivateRoute
                    exact
                    path='/custodian-allocation/:accountId'
                    Component={CustodianAllocationPage}
                />
                <PrivateRoute
                    exact
                    path='/fee-allocation'
                    Component={FeeAllocationPage}
                />
                <PrivateRoute
                    exact
                    path='/fee-allocation/:accountId'
                    Component={FeeAllocationPage}
                />

                <PrivateRoute
                    exact
                    path='/gain-loss/:accountId'
                    Component={GainLossPage}
                />
                <PrivateRoute
                    exact
                    path='/gain-loss'
                    Component={GainLossPage}
                />

                <PrivateRoute
                    exact
                    path='/performance/:accountId'
                    Component={PerformancePage}
                />
                <PrivateRoute
                    exact
                    path='/performance'
                    Component={PerformancePage}
                />

                <PrivateRoute
                    path='/holding-performance'
                    Component={PerformanceHoldingPage}
                />
                <PrivateRoute path='/csv-import/:id' Component={CsvImport} />
            </Switch>
        </Router>
    );
}

export default App;
