import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.min.css';
import './index.css';
import { AuthProvider } from './hooks/useAuth.hook';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DateProvider } from './hooks/useAppSetting.hooks';
import { store } from './app/store';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Provider } from 'react-redux';
import { DataSettingProvider } from './hooks/useDataSetting.hooks';

const queryClient = new QueryClient();

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);
ReactDOM.render(
    <AuthProvider>
        <QueryClientProvider client={queryClient}>
            <DateProvider>
                <DataSettingProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </DataSettingProvider>
            </DateProvider>
        </QueryClientProvider>
    </AuthProvider>,
    document.getElementById('root')
);
