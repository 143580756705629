import { fetchWithPost } from '../utils/fetch.utils';

export const resendOtp = async (data: any): Promise<any> => {
    const response = await fetchWithPost(`resend-otp`, data);
    return response;
};
export const verifyOtp = async (data: any): Promise<any> => {
    const response = await fetchWithPost(`verify-otp`, data);
    return response;
};

export const resendPhoneOtp = async (data: any): Promise<any> => {
    const response = await fetchWithPost(`resend-phone-otp`, data);
    return response;
};