import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth.hook';

interface IProtectedRoutesProps {
    path: string;
    permitOnly?: string;
    exact?: boolean;
    Component: any;
}

const ProtectedRoutes = (props: IProtectedRoutesProps) => {
    const { Component, ...rest } = props;
    const auth = useAuth();

    return (
        <Route
            {...rest}
            component={(props: any) =>
                auth.isLoggedIn() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/login' />
                )
            }
        />
    );
};

export default ProtectedRoutes;
