import { Table } from 'antd';
import React from 'react';

const DataTable = (props: any) => {
    return (
        <Table dataSource={props.data} columns={props.columnData} {...props} />
    );
};

export default DataTable;
