import DateSelector from '../../components/DateSelector';
import Layout from '../../components/Layout';
import PositionComponent from '../../components/Position';
import styles from './index.module.css';
const PositionPage = () => {
    return (
        <Layout activeItem='Positions'>
            <DateSelector
                {...{
                    title: 'Positions',
                    duration: '',
                    page: 'position'
                }}
            />
            <div className={styles.mainContainer}>
                <PositionComponent showMethodSelection={true} />
            </div>
        </Layout>
    );
};

export default PositionPage;
