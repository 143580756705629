import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { showToast } from '../../../utils/notifications.util';
import { Col, Row } from 'antd';
// Custom components
import CustomInput from '../../CustomInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import CustomButton from '../../CustomButton';
import { registerAccountByUserId } from '../../../api/registration';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    MAX_PASSWORD_CHAR,
    MIN_PASSWORD_CHAR,
    PASSWORD_REGEX,
    PLATFORM_NAME
} from '../../../utils/constants.util';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import CoinCalcuBlack from '../../../assets/COINCALCU-black.png';
import CoinCalcuWhite from '../../../assets/COINCALCU-white.png';
import styles from './index.module.css';

const schema = yup.object().shape({
    first_name: yup.string().required('field required '),
    last_name: yup.string().required('field required '),
    email: yup
        .string()
        .email('must be a valid email')
        .required('field required '),
    password: yup
        .string()
        .required('Password is mandatory')
        .min(
            JSON.parse(MIN_PASSWORD_CHAR),
            `Min character should be ${MIN_PASSWORD_CHAR}`
        )
        .max(
            JSON.parse(MAX_PASSWORD_CHAR),
            `Max character should be ${MAX_PASSWORD_CHAR}`
        )
        .matches(
            PASSWORD_REGEX,
            'special characters and numbers are required.'
        ),
    confirm_password: yup
        .string()
        .required('Password is mandatory')
        .oneOf([yup.ref('password')], 'Passwords does not match')
});

type Inputs = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    confirm_password: string;
};

const CreateAccount = () => {
    const { theme } = useAppSetting();
    const [loading, setLoading] = useState(false);
    const { id }: any = useParams();
    const { push } = useHistory();

    const { register, handleSubmit, formState } = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const handleFormSubmit: SubmitHandler<Inputs> = async (userData: any) => {
        setLoading(true);
        const newData = {
            first_name: userData.first_name.trim(),
            last_name: userData.last_name.trim(),
            email: userData.email.trim(),
            password: userData.password,
            confirm_password: userData.confirm_password,
            user_id: id
        };

        try {
            const result = await registerAccountByUserId(newData);
            if (result.success) {
                push(`/login`);

                showToast(true, result.message);
            } else {
                showToast(false, result.message);
            }
        } catch (error) {
            console.log('error', error);
            showToast(false, 'Something went wrong');
        }

        setLoading(false);
    };

    return (
        <div className='rightContainer'>
            <h2 className={styles.logo}>
                {theme === 'dark' ? (
                    <img
                        className={styles.headingContainer}
                        src={CoinCalcuWhite}
                        alt='logo'
                        height={130}
                    />
                ) : (
                    <img
                        className={styles.headingContainer}
                        src={CoinCalcuBlack}
                        alt='logo'
                        height={130}
                    />
                )}
            </h2>
            <div className='accountCardContainer'>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <CustomInput
                                withForm={true}
                                name='first_name'
                                withLabel={true}
                                label='First Name'
                                placeholder='xxxxxxxxx'
                                register={register}
                            />
                            {errors?.first_name && (
                                <span className='error'>
                                    {errors?.first_name.message}
                                </span>
                            )}
                        </Col>
                        <br />
                        <Col span={12}>
                            <CustomInput
                                withForm={true}
                                name='last_name'
                                withLabel={true}
                                label='Last Name'
                                placeholder='xxxxxxxxx'
                                register={register}
                            />

                            {errors?.last_name && (
                                <span className='error'>
                                    {errors?.last_name.message}
                                </span>
                            )}
                        </Col>

                        <br />
                        <Col span={12}>
                            <CustomInput
                                withForm={true}
                                name='password'
                                withLabel={true}
                                label='Password'
                                placeholder='xxxxxxx'
                                register={register}
                                type='password'
                            />
                            {errors?.password && (
                                <span className='error'>
                                    {errors?.password.message}
                                </span>
                            )}
                        </Col>

                        <br />
                        <Col span={12}>
                            <CustomInput
                                withForm={true}
                                name='confirm_password'
                                withLabel={true}
                                label='Confirm Password'
                                placeholder='xxxxxxx'
                                register={register}
                                type='password'
                            />
                            {errors?.confirm_password && (
                                <span className='error'>
                                    {errors?.confirm_password.message}
                                </span>
                            )}
                        </Col>

                        <br />

                        <Col span={24}>
                            <CustomInput
                                withForm={true}
                                name='email'
                                withLabel={true}
                                label='Email'
                                placeholder='xxxxxxx'
                                register={register}
                                type='text'
                            />
                            {errors?.email && (
                                <span className='error'>
                                    {errors?.email.message}
                                </span>
                            )}
                        </Col>

                        <br />
                        <Col span={24}>
                            <CustomButton
                                withForm={true}
                                name='Create  Account'
                                loading={loading}
                            />
                        </Col>

                        <br />
                    </Row>
                    <br />
                    <div className={styles.footerText}>
                        Already have an account?{' '}
                        <Link className={styles.primaryText} to='/login'>
                            Log in
                        </Link>
                    </div>
                    <br />
                    <div className={styles.footerText}>
                        By logging in to {PLATFORM_NAME} you agree to our <br />
                        <a
                            href='https://coincalcu.com/terms-of-use-service/'
                            target={'_blank'}
                            rel='noreferrer'
                            className={styles.primaryText}
                        >
                            Terms of Use & Service
                        </a>{' '}
                        and{' '}
                        <a
                            href='https://coincalcu.com/data-use-statement/'
                            target={'_blank'}
                            rel='noreferrer'
                            className={styles.primaryText}
                        >
                            Data Use Statement
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAccount;
