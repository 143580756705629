import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDataSetting } from '../../../hooks/useDataSetting.hooks';
import SideBarTypes from '../../../interfaces/sidebarType';
import { PLATFORM_NAME } from '../../../utils/constants.util';
import { SideBarData } from '../../../utils/sideBarData.util';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import CoinCalcuBlack from '../../../assets/COINCALCU-black.png';
import CoinCalcuWhite from '../../../assets/COINCALCU-white.png';

import styles from './SideBar.module.css';

const SideBarPage = ({ activeItem }: any) => {
    const { navbarOpen, navbarClose, setNavbarClose, setNavbarOpen } =
        useDataSetting();
    const { theme } = useAppSetting();
    const { push } = useHistory();
    const handleOnChange = (title: string) => {
        setNavbarClose(true);
        setNavbarOpen(false);
        push(title);
    };

    return (
        <div className={styles.sidebarContainer}>
            <div
                className={!navbarOpen ? styles.sidebar : styles.drawerSidebar}
            >
                <h2 className={styles.sidebarLogo}>
                    {theme === 'dark' ? (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuWhite}
                            alt='logo'
                            height={130}
                        />
                    ) : (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuBlack}
                            alt='logo'
                            height={130}
                        />
                    )}
                </h2>
                {/* <h2 className={styles.sidebarLogo}>{PLATFORM_NAME}</h2> */}
                <div className={styles.divider} />
                <ul className={styles.sidebarList}>
                    {SideBarData.map((item: SideBarTypes, index: number) => {
                        return item.link === '' ? (
                            <li
                                key={`${item.title}-${index}`}
                                className={styles.sidebarListHeadingItem}
                            >
                                <h4 className={styles.sidebarListHeading}>
                                    {item.title}
                                </h4>
                            </li>
                        ) : (
                            <li
                                key={`${item.title}-${index}`}
                                onClick={() => handleOnChange(item.link)}
                                className={
                                    activeItem === item.title
                                        ? `${styles.sidebarListItem} ${styles.sidebarListItemActive}`
                                        : `${styles.sidebarListItem}`
                                }
                            >
                                <img
                                    className={styles.sidebarListItemIcon}
                                    src={item.icons}
                                    alt='icons'
                                />
                                <div>{item.title}</div>
                            </li>
                        );
                    })}
                </ul>
                <div className={styles.sidebarListLogout}>
                    <Button
                        className={styles.sidebarListLogoutBtn}
                        type={'primary'}
                        onClick={() => handleOnChange('/logout')}
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SideBarPage;
