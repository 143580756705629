import dayjs from 'dayjs';

export const squareBracketsToJsonObj = (obj: any) => {
    let plain = { ...obj };
    Object.keys(plain).forEach((k) => {
        const path = k.replace(/\[/g, '.').replace(/\]/g, '').split('.'),
            last = path.pop();

        if (path.length) {
            path.reduce((o, p) => (o[p] = o[p] || {}), plain)[last!!] =
                plain[k];
            delete plain[k];
        }
    });

    return plain;
};

export const zeroPad = (str: string, length: number): string => {
    if (length <= 1 || str.length === length) return str;

    let newStr = str;
    while (newStr.length < length) newStr = '0' + str;
    return newStr;
};

export const formateDate = (
    date: string = '',
    formatStr: string = 'MM-DD-YYYY'
) => {
    // const dateFormats = ['MM-DD-YYYY', 'MM-DD-YY', 'MM/DD/YYYY', 'MM/DD/YY'];
    // if (!dateFormats.includes(formatStr)) return date;
    return dayjs(date).format(formatStr);
};

export const fixedDecimal = (value: number, fixedNumbers: number = 2) =>
    !isNaN(value) ? Number(value).toFixed(fixedNumbers) : value;

export const currencyToUSD = (value: Number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    }).format(Number(value));

export const ucFirst = (str?: string) => {
    if (!str) return '';

    return str
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
};

export const timeStamp = (timestamp: string) =>
    new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    })
        .format(new Date(timestamp))
        .split(',');

export function getEveryNthItem<T>(arr: Array<T>, n: number): Array<T> {
    const newArray: Array<T> = [];
    arr.forEach((i: T, index: number) => {
        if (index % n === 0) newArray.push(i);
    });

    if (arr.length % n !== 0) newArray.push(arr[arr.length - 1]);

    return newArray;
}

export function generateRandomColor() {
    let maxVal = 0xffffff; // 16777215
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    let randomNumberStr = randomNumber.toString(16);
    let randColor = randomNumberStr.padStart(6, '0');
    return `#${randColor.toUpperCase()}`;
}

export function blankOrNull(
    str: string | undefined | null,
    defaultValue: any = ''
): any {
    if (str != null && str !== '') return str;
    return defaultValue;
}

export function getRandomColorUsingIndex(index: number) {
    const colorHexArr = [
        '#A1A6CD',
        '#054A5A',
        '#0E3627',
        '#A9793C',
        '#3A89AD',
        '#01CF6F',
        '#8FDD0E',
        '#02A739',
        '#9603E9',
        '#D3B675',
        '#4C098F',
        '#CED20D',
        '#BB60AB',
        '#51444F',
        '#188FF7',
        '#201298',
        '#A42998',
        '#DC7535',
        '#5CDC8D',
        '#28416F',
        '#B3192A',
        '#D3B11B',
        '#5E5FDE',
        '#9740AF',
        '#7ADE88',
        '#0DC889',
        '#77D823',
        '#772B77',
        '#BF60DA',
        '#C24FE7',
        '#A0DEAB',
        '#D47B5C',
        '#29D882',
        '#1586D3',
        '#19C547',
        '#0216BB',
        '#963ACC',
        '#C78817',
        '#D5B772',
        '#AD2886',
        '#84B977',
        '#9EAA4A',
        '#047A86',
        '#D93805',
        '#B83494',
        '#DE5974',
        '#729E3B',
        '#756CAB',
        '#545544',
        '#3A9053',
        '#EF61A8',
        '#F59B39',
        '#6DC35A',
        '#EF5B47',
        '#0BF847',
        '#BBCEEE',
        '#1F17CD',
        '#2305C4',
        '#631FB2',
        '#052498',
        '#8EAD2B',
        '#037841',
        '#735FAB',
        '#FA35EE',
        '#0ECEDF',
        '#D4D6E9',
        '#201D67',
        '#817C72',
        '#E36FD1',
        '#35EEAE',
        '#309373',
        '#5E515E',
        '#F9F916',
        '#8502E7',
        '#19D92E',
        '#730949',
        '#85A88D',
        '#CD742B',
        '#A171B1',
        '#E93A18',
        '#3F8BF8',
        '#0CB59F',
        '#CF15DD',
        '#2617C5',
        '#34813F',
        '#061DEC',
        '#2F70CC',
        '#B0985E',
        '#B69B8D',
        '#E285C8',
        '#127F36',
        '#D61DAD',
        '#D4294B',
        '#EF2BC4',
        '#7D352C',
        '#9A59C9',
        '#0A92FD',
        '#56EC09',
        '#68A3F7',
        '#8F7E70'
    ];

    // Try Catch for index out of bound
    try {
        return colorHexArr[index];
    } catch (error) {
        return generateRandomColor();
    }
}
