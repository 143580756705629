import { configureStore } from '@reduxjs/toolkit';
import activityPerformanceSlice from './slices/activityPerformance.slice';
import assetAllocationSlice from './slices/assetAllocation.slice';
import feeAllocationSlice from './slices/feeAllocation.slice';
import { combineReducers } from 'redux';

const reducer = combineReducers({
    activityPerformanceSelector: activityPerformanceSlice,
    assetAllocationSelector: assetAllocationSlice,
    feeAllocationSelector: feeAllocationSlice,
});

export const store = configureStore({ reducer });
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
