import { encryption, decryption } from './crypto.util';
import { SECRET_TOKEN_KEY } from './constants.util';

export const setEncValue = (key: string, value: string) => {
    const encValue = encryption(value, SECRET_TOKEN_KEY || '');
    localStorage.setItem(key, encValue);
};

export const getEncValue = (key: string): string => {
    const decryptValue = decryption(getLocalValue(key), SECRET_TOKEN_KEY || '');
    return decryptValue;
};

export const setLocalValue = (key: any, value: string) => {
    localStorage.setItem(key, value);
};

export const getLocalValue = (key: string): string => {
    return localStorage.getItem(key) || '';
};

export const removeLocalValue = (key: any) => {
    localStorage.removeItem(key);
};
