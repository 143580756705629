import { InboxOutlined } from '@ant-design/icons';
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Space,
    Tooltip,
    Upload,
    Modal
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import { BASE_URL } from '../../utils/constants.util';
import { fetchDownloadFile } from '../../utils/fetch.utils';
import { showToast } from '../../utils/notifications.util';
import { Tabs } from 'antd';
import styles from './csv_import.module.css';
import {
    getAccountById,
    uploadCsvFile,
    uploadCsvLink,
    updateAccountUsingApi
} from '../../api/account';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { ICsvData, IDataMapper } from './csv_import.type';
import IResponse from '../../interfaces/handle-response.interface';
import { IAccountData } from '../../interfaces/account';
import { Select } from 'antd';
import DataTable from '../../components/DataTable';
import { useAuth } from '../../hooks/useAuth.hook';
import Spinner from '../../components/Spinner';

const { TabPane } = Tabs;

const { Dragger } = Upload;
const { Option } = Select;

const csvColumns = [
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp'
    },
    {
        title: 'Type',
        dataIndex: 'transaction_kind',
        key: 'transaction_kind'
    },

    {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency'
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount'
    },
    {
        title: 'To Amount',
        dataIndex: 'to_amount',
        key: 'to_amount'
    },
    {
        title: 'To Currency',
        dataIndex: 'to_currency',
        key: 'to_currency'
    }
];
interface IAccountInfoData {
    accountInfo: IAccountData;
    csvFields: Array<string>;
    csvOptionalFields: Array<string>;
    descriptions: Array<string>;
    descriptionsOptional: Array<string>;
}
interface IAccountUpdateUsingApi {
    secretKey?: string;
    apiKey?: string;
}

const TemplateFileSection: React.FC<IAccountInfoData> = (
    props: IAccountInfoData
) => {
    return (
        <>
            <h3 className='headingColor'>Template file:</h3>
            <span className={styles.templateFileSection}>
                Click{' '}
                <a
                    href={`${BASE_URL}/${props.accountInfo.csv_link}`}
                    download
                    target='_blank'
                >
                    here
                </a>{' '}
                to download the template file for{' '}
                <em>{props.accountInfo.display_name}</em>.
            </span>
        </>
    );
};

const CsvImport: React.FC = () => {
    const { push } = useHistory();
    const { id }: any = useParams();
    const auth = useAuth();
    const [form] = Form.useForm();

    const [error, setError] = useState<string | null | any>(null);

    const [labelMapperVisibility, setLabelMapperVisibility] = useState(false);
    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const [newDataMapper, setNewDataMapper] = useState<IDataMapper>({
        csv_field: [],
        Labels: {},
        DataTypes: {},
        csv_headers: []
    });
    const [csvHeader, setCsvHeader] = useState<string[]>();
    const [nonMatchedCsvHeader, setNonMatchedCsvHeader] = useState<string[]>(
        []
    );
    const [csvSummary, setCsvSummary] = useState<ICsvData>({
        duplicate_data: [],
        clean_csv_link: '',
        clean_data: [],
        error_csv_link: '',
        errors: []
    });

    const [accountData, setAccountData] = useState<IAccountInfoData>();

    const [file, setFile] = useState<RcFile>();
    const [visible, setVisible] = useState(false);

    const fetchAccountById = async () => {
        try {
            const result: IResponse<IAccountData> = await getAccountById(id);

            if (result.success) {
                const oldCsvParsed = JSON.parse(result.data.csv_fields ?? '');
                const csvFields = Object.keys(oldCsvParsed.Labels);
                const csvOptionalFields = Object.keys(
                    oldCsvParsed.OptionalLabels ?? {}
                );

                const descriptions: string[] = Object.values(
                    oldCsvParsed.Description ?? {}
                );
                const descriptionsOptional: string[] = Object.values(
                    oldCsvParsed.OptionalDescription ?? {}
                );

                setAccountData({
                    csvFields: csvFields,
                    accountInfo: result.data,
                    csvOptionalFields: csvOptionalFields,
                    descriptions: descriptions,
                    descriptionsOptional: descriptionsOptional
                });
            } else {
                showToast(false, result.message);
            }
        } catch (error) {
            showToast(false, 'Something went wrong');
        }
    };

    useEffect(() => {
        fetchAccountById();
    }, []);

    const props: UploadProps = {
        name: 'file',
        accept: '.csv',
        multiple: false,
        maxCount: 1,
        beforeUpload(file: RcFile) {
            setFile(file);
            return false;
        },
        onDrop(e: any) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const buildFormDataObj = (data: any) => {
        let labelsObj: { [key: string]: string } = {};
        let datatypesObj: { [key: string]: string } = {};

        const csvField = data.csv_field;
        const datatypes = newDataMapper.DataTypes;
        const labels = newDataMapper.Labels;

        csvField?.forEach((el: any) => {
            if (el != null && el.field) {
                labelsObj[el?.field] = labels[el.mapField];
                datatypesObj[el?.field] = datatypes[el.mapField];
            }
        });

        return JSON.stringify({
            Labels: labelsObj,
            DataTypes: datatypesObj,
            OptionalDataTypes: {},
            OptionalLabels: {}
        });
    };

    const formatFileData = (file: RcFile) => {
        const formData = new FormData();
        formData.append('file', file);
        return formData;
    };

    const downloadCsv = async (csvLink?: string, name?: string) => {
        csvLink && (await fetchDownloadFile(csvLink, null, name));
    };

    const preparedError = (
        message: string,
        errorList: string[] | null | undefined
    ) => {
        return (
            <>
                <p>{message}</p>
                <ul>
                    {errorList?.map((error: string) => (
                        <li>{error}</li>
                    ))}
                </ul>
            </>
        );
    };

    const handleOnSelect = (selectedOption: string) => {
        if (!nonMatchedCsvHeader || nonMatchedCsvHeader.length <= 0) return;

        const newData: Array<string> = nonMatchedCsvHeader.filter(
            (item: string) => {
                return item !== selectedOption;
            }
        );

        setCsvHeader(newData);
    };

    const handleFormSubmit = async (data?: any) => {
        try {
            let mapper: string | null = null;
            if (data != null) {
                mapper = buildFormDataObj(data);
            }

            if (!file) {
                showToast(false, 'Please upload a CSV file to proceed.');
                return;
            }

            const formData = file ? formatFileData(file) : new FormData();

            setLoading(true);
            const response = await uploadCsvFile(id, formData, mapper);

            if (!response.success) {
                showToast(false, response.message);
                return;
            }

            if (response.data.error_csv_link) {
                setCsvSummary(response?.data);
                if (response.data.errors.length >= 0) {
                    setError(
                        preparedError(response.message, response.data.errors)
                    );
                    downloadCsv(
                        response.data.error_csv_link,
                        `error_report-${accountData?.accountInfo?.display_name}.csv`
                    );
                    setVisible(true);
                    setLabelMapperVisibility(false);

                    return;
                }
            }

            if (response.data.Labels) {
                const data = response.data;
                let csvHeader = data.csv_headers;
                const header: Array<string> = [];
                const _Labels = Object.keys(data.Labels);

                csvHeader.sort(
                    (a: string, b: string) => a.localeCompare(b) //using String.prototype.localCompare()
                );

                csvHeader.forEach((item: any) => {
                    const _item = item.substring(1);
                    if (item[0] === '*' && _Labels.includes(_item)) {
                        header.push(_item);
                    }
                });
                const nonMatchedColumns = csvHeader.filter((item: any) => {
                    return item[0] !== '*';
                });

                setCsvHeader(nonMatchedColumns);
                setNonMatchedCsvHeader(nonMatchedColumns);
                const requiredLabels = _Labels.sort(
                    (a: string, b: string) => a.localeCompare(b) //using String.prototype.localCompare()
                );

                let ind = 0;
                setNewDataMapper({
                    csv_field: requiredLabels.map((item: string) => {
                        if (header.includes(item)) {
                            return {
                                mapField: item,
                                field: header[ind++]
                            };
                        } else
                            return {
                                mapField: item
                            };
                    }),
                    Labels: data.Labels,
                    DataTypes: data.DataTypes
                });

                setError(response.message);
                setLabelMapperVisibility(true);
                setVisible(false);

                return;
            }

            if (
                response.data?.errors == undefined ||
                response.data?.errors?.length > 0
            ) {
                const errors = preparedError(
                    response.message + ' ' + response?.data?.message == null
                        ? ''
                        : response?.data?.message,
                    response.data?.errors
                );
                setError(errors);

                setCsvSummary({
                    ...csvSummary,
                    ...response?.data
                });

                setLabelMapperVisibility(false);
                setVisible(true);
            }
        } catch (error) {
            showToast(false, `${error}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitCleanCsvLink = async () => {
        try {
            setLoading(true);

            const response = await uploadCsvLink(
                id,
                csvSummary?.clean_csv_link
            );
            if (response.success) {
                showToast(true, response.message);

                push('/');
            } else {
                showToast(false, response.message);
            }
        } catch (error) {
            showToast(false, `${error}`);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values: IAccountUpdateUsingApi) => {
        setLoading(true);
        const account_id: string = accountData
            ? accountData.accountInfo.id
            : '';

        try {
            const result = await updateAccountUsingApi(
                account_id,
                values?.apiKey,
                values?.secretKey
            );
            if (result.success) {
                setLoading(false);
                setModal(true);
                form.resetFields();
                setTimeout(() => {
                    fetchAccountById();
                }, 3000);
            } else {
                setLoading(false);
                showToast(false, 'Something went wrong');
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
            showToast(false, 'Something went wrong');
        }
    };

    return (
        <Layout activeItem={'Accounts'}>
            {!labelMapperVisibility ? (
                <div className={styles.mainContainer}>
                    {visible ? (
                        <>
                            <h1 className='headingColor'>CSV Report:</h1>
                            <Divider />
                            <h2 className='headingColor'>Error Rows:</h2>
                            {csvSummary?.errors?.length > 0 ? (
                                <>
                                    <Alert
                                        closable={true}
                                        message={`${csvSummary?.errors?.length} rows are incorrect and contain some errors.`}
                                        description={error}
                                        type='error'
                                        showIcon
                                    />
                                    {csvSummary?.error_csv_link === '' ? (
                                        <></>
                                    ) : (
                                        <div className={styles.errorButtonDiv}>
                                            <a
                                                className={
                                                    styles.csvErrorButton
                                                }
                                                href={`${csvSummary?.error_csv_link}`}
                                                download
                                                target='_blank'
                                            >
                                                <Button>
                                                    Download error file
                                                </Button>
                                            </a>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <h3 className={styles.textColor}>
                                    No error found in the imported CSV.
                                </h3>
                            )}
                            <Divider />
                            <h2 className='headingColor'>Correct Rows:</h2>
                            <h3 className={styles.textColor}>
                                {csvSummary?.clean_data.length} rows are correct
                                and ready to import.
                            </h3>
                            <DataTable
                                data={csvSummary?.clean_data}
                                columnData={csvColumns}
                            />
                            <Divider />
                            <h2 className='headingColor'>Duplicated Rows:</h2>
                            <h3 className={styles.textColor}>
                                {' '}
                                {csvSummary?.duplicate_data.length} rows are
                                correct and already in our system
                            </h3>
                            <DataTable
                                data={csvSummary?.duplicate_data}
                                columnData={csvColumns}
                            />
                            <Button
                                type='primary'
                                onClick={() => handleSubmitCleanCsvLink()}
                                loading={loading}
                                htmlType='submit'
                                disabled={
                                    csvSummary?.clean_data.length === 0 &&
                                    csvSummary?.duplicate_data.length === 0
                                }
                                className={styles.btn}
                            >
                                Import
                            </Button>
                        </>
                    ) : (
                        <Tabs defaultActiveKey='1'>
                            <TabPane tab='Import CSV' key='1'>
                                <div>
                                    <div>
                                        <h3 style={{ color: 'red' }}>
                                            Note: The first row of your CSV data
                                            should contain the headers.
                                        </h3>
                                        {accountData?.accountInfo?.csv_link && (
                                            <TemplateFileSection
                                                {...(accountData as IAccountInfoData)}
                                            />
                                        )}
                                        <br />
                                        <br />
                                        <h3 className='headingColor'>
                                            Requirements:{' '}
                                        </h3>
                                        <ul>
                                            <li className={styles.textColor}>
                                                <b className={styles.textColor}>
                                                    The first row (i.e. header)
                                                    must exactly match the
                                                    following:
                                                </b>
                                                <br />
                                                <small>
                                                    <em
                                                        className={
                                                            styles.textColor
                                                        }
                                                    >
                                                        (Fields starting with{' '}
                                                        <span
                                                            style={{
                                                                color: '#df4759 !important'
                                                            }}
                                                        >
                                                            *
                                                        </span>{' '}
                                                        are required)
                                                    </em>
                                                </small>
                                                <ul>
                                                    {accountData?.csvFields &&
                                                        accountData.csvFields.map(
                                                            (
                                                                item: string,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <li
                                                                        className={
                                                                            styles.textColor
                                                                        }
                                                                        key={`${item}-${index}`}
                                                                    >
                                                                        <Space size='small'>
                                                                            <span
                                                                                className={
                                                                                    styles.textColor
                                                                                }
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        color: '#df4759 !important'
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </span>{' '}
                                                                                {
                                                                                    item
                                                                                }
                                                                            </span>
                                                                            <Tooltip
                                                                                title={
                                                                                    accountData
                                                                                        .descriptions?.[
                                                                                        index
                                                                                    ] ??
                                                                                    ''
                                                                                }
                                                                            >
                                                                                <QuestionCircleOutlined
                                                                                    className={
                                                                                        styles.textColor
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        </Space>
                                                                    </li>
                                                                );
                                                            }
                                                        )}

                                                    {accountData?.csvOptionalFields &&
                                                        accountData.csvOptionalFields.map(
                                                            (
                                                                item: string,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <li
                                                                        className={
                                                                            styles.textColor
                                                                        }
                                                                        key={`${item}-${index}`}
                                                                    >
                                                                        <Space size='small'>
                                                                            <span
                                                                                className={
                                                                                    styles.textColor
                                                                                }
                                                                            >
                                                                                {
                                                                                    item
                                                                                }
                                                                            </span>
                                                                            <Tooltip
                                                                                title={
                                                                                    accountData
                                                                                        .descriptionsOptional?.[
                                                                                        index
                                                                                    ] ??
                                                                                    ''
                                                                                }
                                                                            >
                                                                                <QuestionCircleOutlined
                                                                                    className={
                                                                                        styles.textColor
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        </Space>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                </ul>
                                            </li>
                                            <li className={styles.textColor}>
                                                <b className={styles.textColor}>
                                                    The required date formats
                                                    are:
                                                </b>
                                                <ul
                                                    className={styles.textColor}
                                                >
                                                    <li>MM/DD/YYYY HH:MM:SS</li>
                                                    <li>MM/DD/YY HH:MM:SS</li>
                                                    <li>MM-DD-YYYY HH:MM:SS</li>
                                                    <li>MM-DD-YY HH:MM:SS</li>
                                                </ul>
                                            </li>
                                            <li className={styles.textColor}>
                                                <b className={styles.textColor}>
                                                    Currency symbols must be
                                                    from the list of{' '}
                                                    <a
                                                        href='https://www.coingecko.com/'
                                                        target='_blank'
                                                    >
                                                        coingecko.com
                                                    </a>
                                                </b>
                                            </li>
                                        </ul>
                                    </div>

                                    <br />

                                    <h3 className='headingColor'>
                                        Upload your CSV file here
                                    </h3>
                                    <Dragger {...props}>
                                        <p className='ant-upload-drag-icon'>
                                            <InboxOutlined />
                                        </p>
                                        <p
                                            // className='ant-upload-text'
                                            className={styles.textColor}
                                        >
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                        {/* <p className='ant-upload-hint'> */}
                                        <p className={styles.textColor}>
                                            Support for a single or bulk upload.
                                            Strictly prohibit from uploading
                                            company data or other band files
                                        </p>
                                    </Dragger>
                                    <br />
                                    <Button
                                        type='primary'
                                        onClick={() => handleFormSubmit()}
                                        loading={loading}
                                        htmlType='submit'
                                        className={styles.btn}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </TabPane>
                            <TabPane tab='Submit API' key='2'>
                                {accountData?.accountInfo?.api_key === '' ||
                                accountData?.accountInfo?.api_key == null ? (
                                    <div>
                                        <br />
                                        <h3 className={styles.textColorNormal}>
                                            You will be redirected to{' '}
                                            <em
                                                style={{
                                                    fontWeight: '600'
                                                }}
                                            >
                                                {
                                                    accountData?.accountInfo
                                                        ?.display_name
                                                }
                                            </em>{' '}
                                            to grant{' '}
                                            <span
                                                style={{
                                                    fontWeight: '600'
                                                }}
                                            >
                                                read-only{' '}
                                            </span>
                                            read-only access to your account.
                                            This
                                            <span
                                                style={{
                                                    fontWeight: '600'
                                                }}
                                            >
                                                {' '}
                                                does not
                                            </span>{' '}
                                            give us access to move your funds.
                                        </h3>
                                        <br />
                                        <h3 className={styles.textColorPrimary}>
                                            Insert your API Key and your Secret
                                        </h3>
                                        <br />
                                        <Form
                                            form={form}
                                            name='selectApi'
                                            onFinish={onFinish}
                                            // scrollToFirstError
                                            autoComplete='off'
                                        >
                                            <div
                                                className={
                                                    styles.selectApiFormInput
                                                }
                                            >
                                                <div
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <h3
                                                        className={
                                                            styles.textColorNormal
                                                        }
                                                    >
                                                        Your Api Key
                                                    </h3>

                                                    <Form.Item
                                                        name='apiKey'
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please input your API Key!'
                                                            }
                                                        ]}
                                                    >
                                                        <input
                                                            autoComplete='off'
                                                            style={{
                                                                color: 'red !important'
                                                            }}
                                                            placeholder='API Key'
                                                            className={
                                                                styles.inputStyles
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    <h3
                                                        className={
                                                            styles.textColorNormal
                                                        }
                                                    >
                                                        Your Secret Key
                                                    </h3>
                                                    <Form.Item
                                                        name='secretKey'
                                                        style={{
                                                            width: '100% !important'
                                                        }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please input your API Key!'
                                                            }
                                                        ]}
                                                    >
                                                        <input
                                                            type={'password'}
                                                            autoComplete='new-password'
                                                            style={{
                                                                color: 'red !important'
                                                            }}
                                                            placeholder='XXXXXXXXX'
                                                            className={
                                                                styles.inputStyles
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <br />

                                            <Form.Item>
                                                {loading ? (
                                                    <Spinner />
                                                ) : (
                                                    <Button
                                                        type='primary'
                                                        loading={loading}
                                                        htmlType='submit'
                                                        className={styles.btn}
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Form.Item>
                                        </Form>
                                        {modal && (
                                            <Modal
                                                title='API key submitted'
                                                visible={modal}
                                                closable={true}
                                                maskClosable={false}
                                                onCancel={() => setModal(false)}
                                                centered={true}
                                                footer={[]}
                                                className={styles.modelText}
                                            >
                                                <div>
                                                    Your account has been
                                                    successfully updated. Please
                                                    note that it may take up to
                                                    48 hours for the changes to
                                                    take effect.
                                                </div>
                                            </Modal>
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.apiAlreadySubmitted}>
                                        You have already submitted API keys.
                                    </div>
                                )}
                            </TabPane>
                        </Tabs>
                    )}
                </div>
            ) : (
                <div className={styles.mainContainer}>
                    <Form
                        name='dynamic_form_nest_item'
                        initialValues={newDataMapper}
                        layout='vertical'
                        autoComplete='off'
                        style={{ padding: '2rem', backgroundColor: 'white' }}
                        onFinish={handleFormSubmit}
                    >
                        {error != null && (
                            <Alert
                                closable={true}
                                message='Error'
                                description={error}
                                type='error'
                                showIcon
                            />
                        )}
                        <br />

                        <b>
                            Below is your data as it was imported from the file
                            you uploaded. Please assign the correct fields in
                            your imported file.
                        </b>

                        <br />
                        <br />

                        <Form.List name='csv_field'>
                            {(fields) => {
                                return (
                                    <>
                                        <Row
                                            key={'first_row-0'}
                                            gutter={[24, 24]}
                                        >
                                            <Col span={12}>
                                                Write CSV header of your file in
                                                these fields.
                                            </Col>
                                            <Col span={12}>
                                                The column on the left will be
                                                assigned to these fields.
                                            </Col>
                                        </Row>

                                        <div style={{ marginBottom: '8px' }} />

                                        {fields.map(
                                            ({ key, name, ...restField }) => {
                                                return (
                                                    <Row
                                                        key={key}
                                                        gutter={[24, 24]}
                                                    >
                                                        <Col span={12}>
                                                            <Form.Item
                                                                name={[
                                                                    key,
                                                                    'field'
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required:
                                                                            true
                                                                    }
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder='Assign a CSV field.'
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleOnSelect(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    {csvHeader?.map(
                                                                        (
                                                                            item: string,
                                                                            index: number
                                                                        ) => (
                                                                            <Option
                                                                                key={`${index}-${item}`}
                                                                                value={
                                                                                    item
                                                                                }
                                                                            >
                                                                                {
                                                                                    item
                                                                                }
                                                                            </Option>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    key,
                                                                    'mapField'
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder='Field Name'
                                                                    readOnly
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                );
                                            }
                                        )}
                                    </>
                                );
                            }}
                        </Form.List>
                        <Form.Item>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                    className={styles.btn}
                                    disabled={loading ? false : true}
                                >
                                    Submit
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            )}
        </Layout>
    );
};

export default CsvImport;
