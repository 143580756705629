import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDataSetting } from '../../../hooks/useDataSetting.hooks';
import SideBarTypes from '../../../interfaces/sidebarType';
import { PLATFORM_NAME } from '../../../utils/constants.util';
import { AccountSideBarData } from '../../../utils/sideBarData.util';
import styles from './AccountSidebar.module.css';
import CoinCalcuBlack from '../../../assets/COINCALCU-black.png';
import CoinCalcuWhite from '../../../assets/COINCALCU-white.png';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
const AccountSideBarPage = ({ activeItem }: any) => {
    const { navbarOpen } = useDataSetting();
    const { theme } = useAppSetting();
    const { push } = useHistory();
    const handleOnChange = (title: string) => {
        push(title);
    };
    return (
        <div className={styles.sidebarContainer}>
            <div
                className={!navbarOpen ? styles.sidebar : styles.drawerSidebar}
            >
                {/* <h1 className={styles.sidebarLogo}>{PLATFORM_NAME}</h1> */}
                <h2 className={styles.sidebarLogo}>
                    {theme === 'dark' ? (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuWhite}
                            alt='logo'
                            height={130}
                        />
                    ) : (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuBlack}
                            alt='logo'
                            height={130}
                        />
                    )}
                </h2>
                <div className={styles.divider} />
                <ul className={styles.sidebarList}>
                    {AccountSideBarData.map(
                        (item: SideBarTypes, index: number) => {
                            return item.link === '' ? (
                                <>
                                    <li
                                        className={
                                            styles.sidebarListHeadingItem
                                        }
                                    >
                                        <h4
                                            className={
                                                styles.sidebarListHeading
                                            }
                                        >
                                            Account Settings
                                        </h4>
                                    </li>
                                </>
                            ) : (
                                <li
                                    key={`${item.title}-${index}`}
                                    onClick={() => handleOnChange(item.link)}
                                    className={
                                        activeItem === item.title
                                            ? `${styles.sidebarListItem} ${styles.sidebarListItemActive}`
                                            : `${styles.sidebarListItem}`
                                    }
                                >
                                    <img
                                        className={styles.sidebarListItemIcon}
                                        src={item.icons}
                                        alt='icons'
                                    />
                                    <div>{item.title}</div>
                                </li>
                            );
                        }
                    )}
                </ul>
                <div className={styles.sidebarListLogout}>
                    <Button
                        className={styles.sidebarListLogoutBtn}
                        type={'primary'}
                        onClick={() => handleOnChange('/logout')}
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AccountSideBarPage;
