import { fetchWithPost } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';
import { IUserData, IUserSetting } from '../interfaces/user';
import { ILoginResponse } from '../interfaces/auth';

export const userLogin = async (
    data: IUserData
): Promise<IResponse<ILoginResponse>> => {
    const response = await fetchWithPost(`login/customer`, data);
    return response;
};
export const userSetting = async (
    data: any
): Promise<IResponse<IUserSetting>> => {
    const response = await fetchWithPost(`user_setting/update`, data);
    return response;
};
