import SideBarType from '../interfaces/sidebarType';
import Overview from '../assets/overview.svg';
import Summary from '../assets/summary.svg';
import AssetAllocation from '../assets/asset_allocation.svg';
import Performance from '../assets/performance.svg';
import Transaction from '../assets/transaction.svg';
import ChangePassword from '../assets/change_password.svg';
import UserSetting from '../assets/user_setting.svg';
import GainLoss from '../assets/gain_loss.svg';

export const SideBarData: SideBarType[] = [
    {
        title: 'Portfolio',
        link: ''
    },
    {
        title: 'Dashboard',
        link: '/overview',
        icons: Overview
    },
    {
        title: 'Positions',
        link: '/position',
        icons: Transaction
    },
    {
        title: 'Activity Summary',
        link: '/summary',
        icons: Summary
    },
    {
        title: 'Asset Allocation',
        link: '/asset-allocation',
        icons: AssetAllocation
    },
    {
        title: 'Custodian Allocation',
        link: '/custodian-allocation',
        icons: AssetAllocation
    },
    {
        title: 'Fee Allocation',
        link: '/fee-allocation',
        icons: AssetAllocation
    },
    {
        title: 'Gain/Loss',
        link: '/gain-loss',
        icons: GainLoss
    },
    {
        title: 'Portfolio Performance',
        link: '/performance',
        icons: Performance
    },
    {
        title: 'Holding Performance',
        link: '/holding-performance',
        icons: Performance
    },

    {
        title: 'Transactions',
        link: '/transaction',
        icons: Transaction
    },
    {
        title: 'Account Settings',
        link: ''
    },
    {
        title: 'Accounts',
        link: '/accounts',
        icons: Overview
    },
    {
        title: 'Change Password',
        link: '/change-password',
        icons: ChangePassword
    },
    {
        title: 'User Settings',
        link: '/user-settings',
        icons: UserSetting
    }
];

export const AccountSideBarData: SideBarType[] = [
    {
        title: 'Account Settings',
        link: ''
    },
    {
        title: 'Accounts',
        link: '/accounts',
        icons: Overview
    },
    {
        title: 'Change Password',
        link: '/change-password',
        icons: ChangePassword
    },
    {
        title: 'User Settings',
        link: '/user-settings',
        icons: UserSetting
    }
];
