import { useState } from 'react';
import { Form, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { userLogin } from '../../../api/user';
import { showToast, showInfoToast } from '../../../utils/notifications.util';
import { OTP_MAX_ATTEMPT, PLATFORM_NAME } from '../../../utils/constants.util';
import OtpVerification from '../../../components/OtpVerification';
import { resendOtp, verifyOtp } from '../../../api/otp';
import { whoami } from '../../../api/auth';

// Custom components
import { useAuth } from '../../../hooks/useAuth.hook';
import CustomInput from '../../../components/CustomInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import CustomButton from '../../../components/CustomButton';
import ForgetPassword from '../../../components/Forms/ForgetPassword';
import ResetPassword from '../../../components/Forms/ResetPassword';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { getTransaction } from '../../../api/transaction';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import CoinCalcuBlack from '../../../assets/COINCALCU-black.png';
import CoinCalcuWhite from '../../../assets/COINCALCU-white.png';
import styles from './index.module.css';
type Inputs = {
    email: string;
    password: string;
};

const schema = yup.object().shape({
    email: yup
        .string()
        .email(' must be a valid email')
        .required('field required '),
    password: yup.string().required('Password is mandatory')
});

const LoginComponent = () => {
    const auth = useAuth();
    const { theme } = useAppSetting();

    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleForget, setVisibleForget] = useState(false);
    const [otpToken, setOtpToken] = useState('');
    const [attempt, setAttempt] = useState<number>(parseInt(OTP_MAX_ATTEMPT));

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    const handleFormSubmit: SubmitHandler<Inputs> = async (userData: any) => {
        setLoading(true);
        const result = await userLogin(userData);
        if (result.success) {
            setVisible(true);
            setOtpToken(result.data.otp_token);
            // showInfoToast(`OTP: ${result.data.otp} ${result.message}`);
        } else {
            showToast(false, `Invalid Credentials`);
        }

        setLoading(false);
    };

    const handleSubmitOtp = async (otp: number) => {
        setVisible(false);
        setLoading(true);

        const result = await verifyOtp({ otp, otp_token: otpToken });

        if (result.success) {
            auth.login(
                result.data.access_token,
                result.data.refresh_token,
                async () => {
                    const whoamiData = await whoami(result.data.access_token);
                    const transactionsResult = await getTransaction(
                        whoamiData.data.user.id,
                        1,
                        2,
                        false,
                        null,
                        'as_of_date',
                        '',
                        result.data.access_token
                    );
                    showToast(true, result.message);
                    setLoading(false);
                    return {
                        whoami: whoamiData.data,
                        hasTransactions: transactionsResult.data.length > 0
                    };
                }
            );
        } else {
            setAttempt(attempt - 1);
            setLoading(false);
            setVisible(true);
            showToast(false, result.message);
        }
    };

    const handleResendOpt = async () => {
        try {
            setAttempt(attempt - 1);
            const result = await resendOtp({ otp_token: otpToken });
            // showInfoToast(`OTP: ${result.data.otp} ${result.message}`);
        } catch (error) {
            showToast(false, 'Something went wrong.');
        }
    };

    return (
        <div className='rightContainer'>
            <h2 className={styles.headingContainer}>
                <h2 className={styles.logo}>
                    {theme === 'dark' ? (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuWhite}
                            alt='logo'
                            height={170}
                        />
                    ) : (
                        <img
                            className={styles.headingContainer}
                            src={CoinCalcuBlack}
                            alt='logo'
                            height={170}
                        />
                    )}
                </h2>
            </h2>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <CustomInput
                    withForm={true}
                    name='email'
                    withLabel={true}
                    label='Email'
                    placeholder='xxxxxxx'
                    register={register}
                    type='email'
                />
                {errors?.email && (
                    <span className='error'>{errors?.email.message}</span>
                )}
                <br />
                <CustomInput
                    withForm={true}
                    name='password'
                    withLabel={true}
                    label='Password'
                    placeholder='xxxxxxx'
                    register={register}
                    type='password'
                />
                {errors?.password && (
                    <span className='error'>{errors?.password.message}</span>
                )}

                <Form.Item className={styles.forgetPasswordContainer}>
                    <span onClick={() => setVisibleForget(!visibleForget)}>
                        Forgot password
                    </span>
                </Form.Item>

                <CustomButton withForm={true} name='Login' loading={loading} />
                <br />
                <div className={styles.TextCentered}>
                    Don't have an account?
                    <Link to='/registration-account' className='headingColor'>
                        {' '}
                        Create an account
                    </Link>
                </div>
                <br />
                <div className={styles.TextCentered}>
                    By logging in to {PLATFORM_NAME} you agree to our <br />
                    <a
                        href='https://coincalcu.com/terms-of-use-service/'
                        target={'_blank'}
                        className={styles.primaryText}
                        rel='noreferrer'
                    >
                        Terms of Use & Service
                    </a>{' '}
                    and{' '}
                    <a
                        href='https://coincalcu.com/data-use-statement/'
                        target={'_blank'}
                        className={styles.primaryText}
                        rel='noreferrer'
                    >
                        Data Use Statement
                    </a>
                </div>
            </form>
            {visible && (
                <Modal
                    title='Two-Factor authentication'
                    visible={visible}
                    closable={false}
                    maskClosable={false}
                    onCancel={() => setVisible(false)}
                    centered={true}
                    footer={[]}
                >
                    <OtpVerification
                        timeInMinutes={1}
                        attempt={attempt}
                        handleSubmit={(otp: number) => handleSubmitOtp(otp)}
                        handleResend={() => {
                            handleResendOpt();
                        }}
                    />
                </Modal>
            )}
            {visibleForget && (
                <Modal
                    title={'Password Recover'}
                    visible={visibleForget}
                    closable={false}
                    maskClosable={false}
                    onCancel={() => setVisibleForget(false)}
                    centered={true}
                    footer={[]}
                    width='425px'
                    className={styles.TextCentered}
                >
                    <ForgetPassword />
                    <br />
                    <div className={styles.TextCentered}>
                        {`Want to come back?  `}
                        <span
                            onClick={() => setVisibleForget(false)}
                            className={`${styles.forgetPasswordContainer} ${styles.linkText}`}
                        >
                            Login
                        </span>
                    </div>
                </Modal>
            )}
            {query.get('token') && (
                <Modal
                    title={'Change Password '}
                    visible={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    footer={[]}
                    width='425px'
                    className={styles.TextCentered}
                >
                    <ResetPassword token={query.get('token')} />
                    <br />
                    <div className={styles.TextCentered}>
                        {`Want to come back?  `}
                        <Link className={styles.linkText} to='/login'>
                            Login
                        </Link>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default LoginComponent;
