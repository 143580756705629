import AuthWrapper from '../../components/AuthWrapper';
import { IAuthWrapperProps } from '../../components/AuthWrapper/AuthWrapper.type';
import CreateAccountForm from '../../components/Forms/CreateAccountForm';

const CreateAccountPage = () => {
    const authWrapperProps: IAuthWrapperProps = {
        leftBackgroundUrlImage:
            'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
        children: <CreateAccountForm />
    };

    return <AuthWrapper {...authWrapperProps} />;
};

export default CreateAccountPage;
