import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout';

import { Button, Col, message, Modal, Row } from 'antd';

import { getCustodian, getAccounts } from '../../api/account';

import { useAuth } from '../../hooks/useAuth.hook';
import styles from './overview.module.css';
import { Link, useParams } from 'react-router-dom';
import { IAccountData, ICustodianAccountData } from '../../interfaces/account';
import CreateAccount from '../../components/CreateAccount';

import Spinner from '../../components/Spinner';
import PerformanceChart from '../../components/Chart/PerformanceChart';
import AssetAllocation from '../../components/Chart/AssetAllocation';
import { useQuery } from 'react-query';
import ActivityChart from '../../components/Chart/ActivityChart';
import { useAppDispatch, useAppSelector } from '../../app/redux-hooks';
import {
    fetchAssetAllocationAsync,
    IFetchAssetAllocationProps
} from '../../app/slices/assetAllocation.slice';
import AssetAllocationDropdown, {
    IAssetAllocationDropdownProps
} from '../../components/Chart/Dropdown/AllocationDropdown';
import HOCGainLoss from '../../components/GainLoss/HOCGainLoss';
import CardHeader from '../../components/Common/CardHeader';
import DateSelector from '../../components/DateSelector';
import { getFeeAllocation } from '../../api/reports';
import FeeAllocation from '../../components/Chart/FeeAllocation';
import { IFeeAllocationData } from '../../components/Chart/FeeAllocation/index.type';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import { getTransaction } from '../../api/transaction';

interface IAccountIdProps {
    accountId: string | null;
    title?: string;
    duration?: string;
    flag?: boolean;
    isOverviewHeader: boolean;
}

const HOCAssetAllocationComponent: React.FC<IAccountIdProps> = ({
    accountId = null,
    title = '',
    flag = true,
    isOverviewHeader = false
}: IAccountIdProps) => {
    const dispatch = useAppDispatch();
    const auth = useAuth();

    const { marketValue } = useAppSetting();

    useEffect(() => {
        dispatch(
            fetchAssetAllocationAsync({
                marketValue,
                userId: auth.user?.user?.id,
                account: false,
                accountId
            } as IFetchAssetAllocationProps)
        );
    }, []);

    const [categoryOption, setCategoryOption] = useState('Chart');
    const selector = useAppSelector((state) => state.assetAllocationSelector);

    return (
        <>
            <CardHeader title={title} isOverviewHeader={isOverviewHeader}>
                <AssetAllocationDropdown
                    {...({
                        category: categoryOption,
                        setCategory: setCategoryOption,
                        showCategoryDropdown: false,
                        showGroupDropdown: false
                    } as IAssetAllocationDropdownProps)}
                />
            </CardHeader>
            {selector.loading ? (
                <Spinner />
            ) : (
                <>
                    <AssetAllocation
                        pieChartSize='small'
                        holdingAllocationData={
                            flag
                                ? selector?.graphData
                                : selector?.accountGraphData
                        }
                        showOtherCategory={false}
                    />
                </>
            )}
        </>
    );
};

const HOCFeeAllocationComponent = () => {
    const auth = useAuth();
    let { accountId }: any = useParams();
    const [feeAllocation, setFeeAllocation] = useState([]);

    if (accountId === undefined) accountId = null;

    const { isLoading, isFetching, data } = useQuery<any, any>(
        ['FeeAllocation'],
        () => getFeeAllocation(auth.user?.user?.id ?? '', true, accountId)
    );
    useEffect(() => {
        const response = data?.data ?? null;
        if (!response || !data.success) return;
        const accountToAsset: any = response?.map(
            (item: IFeeAllocationData) => {
                return {
                    asset: item.account,
                    fee: item.fee
                };
            }
        );
        setFeeAllocation(accountToAsset);
    }, [isLoading, isFetching]);
    const [categoryOption, setCategoryOption] = useState('Chart');

    return (
        <>
            <CardHeader isOverviewHeader={true} title={'Fee Allocation'}>
                <AssetAllocationDropdown
                    {...({
                        category: categoryOption,
                        setCategory: setCategoryOption,
                        showCategoryDropdown: false,
                        showGroupDropdown: false
                    } as IAssetAllocationDropdownProps)}
                />
            </CardHeader>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <FeeAllocation
                        pieChartSize='small'
                        holdingAllocationData={feeAllocation}
                        showLegend={false}
                    />
                </>
            )}
        </>
    );
};

const OverviewPage: React.FC = () => {
    const auth = useAuth();

    let { accountId }: any = useParams();
    if (accountId === undefined) accountId = null;

    const [visible, setVisible] = useState(false);
    const ref = useRef(false);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState('as_of_date');

    const [custodianData, setCustodianData] = useState<ICustodianAccountData[]>(
        []
    );

    const [accountData, setAccountData] = useState<IAccountData[]>([]);

    const getAllCustodian = async (userId: string) => {
        try {
            const result = await getCustodian(userId, 1);
            if (result.success) {
                setCustodianData(result.data);
            }
        } catch (error) {
            message.error('Something went wrong!!!');
        }
    };

    const fetchAccounts = async () => {
        try {
            const result = await getAccounts(1, 100);
            if (result.success) {
                setAccountData(result.data);
                if (result.data.length > 0) {
                    const transactionResult = await getTransaction(
                        auth?.user?.user?.id,
                        1,
                        10,
                        true
                    );
                    const hasTransactions: boolean =
                        transactionResult.data.length > 0;
                    auth.setHasTransactionsFlag(hasTransactions);
                } else {
                    auth.setHasTransactionsFlag(false);
                }
            }
        } catch (error) {
            message.error('Something went wrong!!!');
        }
    };

    const loadInitialData = async () => {
        try {
            setLoading(true);
            await fetchAccounts();
            await getAllCustodian(auth?.user?.user?.id || '');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        ref.current === false && loadInitialData();
        ref.current = true;
    }, []);

    return (
        <Layout activeItem={'Dashboard'}>
            {auth.hasTransactions ? (
                <>
                    <DateSelector
                        {...{
                            title: '',
                            duration,
                            setDuration,
                            page: `overview`
                        }}
                    />
                    <div style={{ width: '100%' }}>
                        <>
                            <Row gutter={[8, 8]}>
                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/summary'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <ActivityChart
                                                    label={'Activity Summary'}
                                                    showTable={false}
                                                    duration={duration}
                                                    accountId={accountId}
                                                    isOverviewHeader={true}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>

                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/performance'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <PerformanceChart
                                                    label={
                                                        'Portfolio Performance'
                                                    }
                                                    defaultDataRepresentation={
                                                        'chart'
                                                    }
                                                    showTable={false}
                                                    duration={duration}
                                                    accountId={accountId}
                                                    isOverviewHeader={true}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>

                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/gain-loss'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <HOCGainLoss
                                                    label='Gain / Loss'
                                                    showMethodSelection={false}
                                                    accountId={accountId}
                                                    isOverviewHeader={true}
                                                />
                                            </div>
                                        </Link>
                                        <br />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ marginBottom: '8px' }} />
                            <Row gutter={[8, 8]}>
                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/asset-allocation'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <HOCAssetAllocationComponent
                                                    accountId={accountId}
                                                    title='Asset Allocation'
                                                    isOverviewHeader={true}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>

                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/fee-allocation'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <HOCFeeAllocationComponent />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>

                                <Col
                                    xs={{ span: 8 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                >
                                    <div
                                        className={styles.cardContainerWrapper}
                                    >
                                        <Link to='/custodian-allocation'>
                                            <div
                                                className={
                                                    styles.cardContainerHeight
                                                }
                                            >
                                                <HOCAssetAllocationComponent
                                                    accountId={accountId}
                                                    title='Custodian Allocation'
                                                    flag={false}
                                                    isOverviewHeader={true}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </div>
                </>
            ) : (
                <div className={styles.accountMainContainerWrapper}>
                    <div className={styles.accountContainerWrapperWelcome}>
                        <div>
                            <h1 className={styles.accountHeading}>
                                {`Welcome ${auth.user?.user.first_name}
                                    ${auth.user?.user.last_name},`}
                            </h1>
                            <p className='spanColor'>
                                Connect your custodian or wallet so we can
                                calculate your cryptocurrency taxes and generate
                                your tax forms
                            </p>

                            <Button
                                type={'primary'}
                                className={styles.accountBtn}
                                disabled={loading}
                                onClick={() => setVisible(true)}
                            >
                                {!loading ? 'Create Account' : 'Loading...'}
                            </Button>
                        </div>
                    </div>
                    {visible && (
                        <Modal
                            title='Select Custodian'
                            visible={visible}
                            closable={true}
                            maskClosable={false}
                            onCancel={() => setVisible(false)}
                            centered={true}
                            footer={[]}
                            className={styles.modelText}
                        >
                            {loading === false && (
                                <CreateAccount
                                    data={custodianData}
                                    setVisible={setVisible}
                                    setLoading={setLoading}
                                    fetchAccounts={fetchAccounts}
                                />
                            )}
                        </Modal>
                    )}
                </div>
            )}
        </Layout>
    );
};

export default OverviewPage;
