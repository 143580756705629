import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFeeAllocation } from '../../api/reports';
import { IFeeAllocationData } from '../../components/Chart/FeeAllocation/index.type';

type InitialState = {
    loading: boolean;
    fulfilled: boolean;
    failed: boolean;
    fetching: boolean;
    accountData: IFeeAllocationData[];
    data: IFeeAllocationData[];
};
const initialState: InitialState = {
    data: [],
    accountData: [],
    fetching: true,
    loading: true,
    fulfilled: false,
    failed: false
};

export interface IFetchFeeAllocationProps {
    userId: string;
    accountId: string;
    account: boolean;
}

export const fetchFeeAllocationAsync = createAsyncThunk(
    'feeAllocation/fetchAsync',
    async ({ userId, accountId }: IFetchFeeAllocationProps) => {
        const resFeeData = await getFeeAllocation(
            userId ?? '',
            false,
            accountId
        );

        const resFeeAccount = await getFeeAllocation(
            userId ?? '',
            true,
            accountId
        );

        return Object.freeze({
            feeData: resFeeData.success ? resFeeData.data : [],
            feeAccount: resFeeAccount.success ? resFeeAccount.data : []
        });
    }
);

export const feeAllocationSlice = createSlice({
    name: 'FeeAllocation',
    initialState,
    reducers: {
        getFeeAllocationData: (state, action) => {
            if (!state.fetching) {
                state.data = [];
                state.accountData = [];
                return;
            }

            const prevData = state.data;
        },
        resetFeeAllocationData: (state, action) => {
            state = { ...initialState };
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchFeeAllocationAsync.pending, (state) => {
            state.fetching = true;
            state.loading = true;
        });
        builder.addCase(
            fetchFeeAllocationAsync.fulfilled,
            (state, action: PayloadAction<any>) => {
                const newPl = action.payload;

                const feeData: IFeeAllocationData[] = newPl.feeData;
                const feeAccount: IFeeAllocationData[] = newPl.feeAccount;

                const newState = {
                    ...state,
                    fetching: false,
                    failed: false,
                    fulfilled: true,
                    loading: false,
                    data: feeData,
                    accountData: feeAccount
                };

                return newState;
            }
        );
        builder.addCase(
            fetchFeeAllocationAsync.rejected,
            (state, action: PayloadAction<any>) => {
                return {
                    ...state,
                    failed: true,
                    fetching: false,
                    fulfilled: false,
                    loading: false,
                    data: initialState.data
                };
            }
        );
    }
});

export const { getFeeAllocationData, resetFeeAllocationData }: any =
    feeAllocationSlice.actions;
export default feeAllocationSlice.reducer;
