import React, { useEffect, useMemo, useState } from 'react';

import { Line } from 'react-chartjs-2';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import { useAuth } from '../../../hooks/useAuth.hook';
import { IActivityListData, TActivityChartProps } from './ActivityChart.type';

import {
    blankOrNull,
    currencyToUSD,
    fixedDecimal,
    formateDate,
    timeStamp
} from '../../../utils/other.util';

import Spinner from '../../Spinner';
import DataTable from '../../DataTable';
import { Button, Modal, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/redux-hooks';
import {
    fetchReportAsync,
    IFetchReportProps
} from '../../../app/slices/activityPerformance.slice';
import dayjs from 'dayjs';
import { getTransactionById } from '../../../api/transaction';
import CardHeader from '../../Common/CardHeader';
import SubscriptionWrapper from '../../SubscriptionWrapper/SubscriptionWrapper';
import styles from './index.module.css';
import { showToast } from '../../../utils/notifications.util';
import { ITransactionData } from '../../../pages/transaction/transaction.type';

const transactionColumn = [
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        fixed: 'left',
        width: 180
    },
    {
        title: 'Account',
        dataIndex: 'account_name',
        key: 'account_name'
    },
    {
        title: 'Type',
        dataIndex: 'transaction_type_name',
        key: 'transaction_type_name'
    },
    {
        title: 'Sent Currency',
        dataIndex: 'from_currency',
        key: 'from_currency',
        // sorter: (a: ITransactionData, b: ITransactionData) =>{return a.from_currency.localeCompare(b.from_currency)},
        render: (currency: string) => {
            return currency === '' ? '-' : currency;
        }
    },
    {
        title: 'Sent Amount',
        dataIndex: 'from_amount',
        key: 'from_amount',
        render: (amount: string, rowData: ITransactionData) => {
            const fromCurrency = rowData?.from_currency;
            return rowData?.from_currency === 'USD'
                ? currencyToUSD(rowData?.from_amount)
                : fixedDecimal(rowData?.from_amount);
            // return amount === '' ? '-' : amount;
        }
    },
    {
        title: 'Received Currency',
        dataIndex: 'to_currency',
        key: 'to_currency',
        render: (currency: string) => {
            return currency === '' ? '-' : currency;
        }
    },
    {
        title: 'Received Amount',
        dataIndex: 'to_amount',
        key: 'to_amount',
        render: (amount: string, rowData: ITransactionData) => {
            return rowData?.to_currency === 'USD'
                ? currencyToUSD(rowData?.to_amount)
                : fixedDecimal(rowData?.to_amount);
            // return amount === '' ? '-' : amount;
        }
    },
    {
        title: 'Fee Currency',
        dataIndex: 'fee_currency',
        key: 'fee_currency',
        render: (currency: string, rowData: ITransactionData) => {
            return rowData?.fee_currency === '' ||
                rowData?.fee_currency === null
                ? '-'
                : currency;
        }
    },
    {
        title: 'Fee Amount',
        dataIndex: 'fee',
        key: 'fee',
        render: (amount: string) => {
            return amount === '' ? '-' : amount;
        }
    }
];

const ActivityChart: React.FC<TActivityChartProps> = ({
    label = '',
    showTable = true,
    duration = 'as_of_date',
    size = 'small',
    accountId = null,
    isOverviewHeader = false
}: TActivityChartProps) => {
    const activityColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Net Addition',
            dataIndex: 'cost_basis',
            key: 'cost_basis'
        },
        {
            title: 'Market Value',
            dataIndex: 'market_value',
            key: 'market_value'
        },
        {
            title: 'Net Proceed',
            dataIndex: 'net_proceed',
            key: 'net_proceed'
        },
        {
            title: 'Return',
            dataIndex: 'return',
            key: 'return'
        },
        {
            title: 'Transaction',
            // dataIndex: 'transaction_id',
            key: 'transaction_id',
            render: ({ transaction_id }: any) => {
                return transaction_id === '' ? (
                    <></>
                ) : (
                    <>
                        <Button
                            className={styles.transactionModelButtonActive}
                            onClick={() => {
                                showModal(transaction_id);
                            }}
                        >
                            Details
                        </Button>
                    </>
                );
            }
        }
    ];
    const { dateFormat, marketValue } = useAppSetting();
    const auth = useAuth();

    const dispatch = useAppDispatch();

    const [chartData, setChartData] = useState<any>(null);
    const userId = useMemo(() => auth.user?.user?.id, [auth]);
    const selector = useAppSelector(
        (state) => state.activityPerformanceSelector
    );
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState<[ITransactionData]>();
    const [modalLoading, setModalLoading] = useState(false);

    const showModal = async (record: string) => {
        setModal(true);
        setModalLoading(true);
        try {
            const result = await getTransactionById(record);
            if (result.success) {
                setModalLoading(false);
                setModalData([result?.data as ITransactionData]);
            } else {
                setModalLoading(false);
                showToast(false, 'Something went wrong');
            }
        } catch (error) {
            console.log('error', error);
            setModalLoading(false);

            showToast(false, 'Something went wrong');
        }
    };
    useEffect(() => {
        dispatch(
            fetchReportAsync({
                duration,
                marketValue,
                userId,
                accountId
            } as IFetchReportProps)
        );
    }, [duration, accountId]);

    const line1C: string = useMemo<string>(
        () => selector.performanceChartLineColor,
        [selector.performanceChartLineColor]
    );

    const line2C: string = useMemo<string>(
        () => selector.activityChartLineColor,
        [selector.activityChartLineColor]
    );

    const line3C: string = useMemo<string>(
        () => selector.MultiChartLineColor,
        [selector.MultiChartLineColor]
    );

    useEffect(() => {
        const list = selector?.data?.activity_list ?? [];

        setChartData({
            labels: list.map((value: IActivityListData) =>
                formateDate(value.date, dateFormat)
            ),
            datasets: [
                {
                    label: 'Net Addition',
                    data: list.map(
                        (value: IActivityListData) => value.cost_basis
                    ),
                    borderColor: line1C,
                    backgroundColor: line1C,
                    borderWidth: -2
                },
                {
                    label: 'Market Value',
                    data: list.map(
                        (value: IActivityListData) => value.market_value
                    ),
                    borderColor: line2C,
                    backgroundColor: line2C,
                    borderWidth: -2
                },
                {
                    label: 'Net Proceed',
                    data: list.map(
                        (value: IActivityListData) => value.net_proceed
                    ),
                    borderColor: line3C,
                    backgroundColor: line3C,
                    borderWidth: -2
                }
            ]
        });
    }, [selector.loading]);
    const prepareOption = () => {
        if (chartData === null) return {};
        return {
            responsive: true,
            interaction: {
                mode: 'index' as const,
                intersect: false
            },
            stacked: false,
            plugins: {
                legend: {
                    display: isOverviewHeader ? false : true,
                    position: 'top' as const
                },
                tooltip: {
                    callbacks: {
                        beforeTitle: function (context: any) {
                            try {
                                return `Date: ${dayjs(
                                    selector.data.activity_list[
                                        context[0].dataIndex
                                    ].date
                                ).format(dateFormat)}`;
                            } catch (e) {
                                return '';
                            }
                        },
                        title: function (_: any) {
                            return '';
                        },
                        label: function (context: any) {
                            let label = ` ${context.dataset.label}`;
                            const value = context.parsed.y;
                            if (value != null) {
                                label += `: ${currencyToUSD(value)}`;
                            }
                            return label;
                        }
                    }
                }
            },
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20,
                        labelOffset: 25
                    }
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (
                            value: any,
                            index: number,
                            ticks: any
                        ) {
                            return currencyToUSD(parseInt(value, 10));
                        }
                    }
                }
            }
        };
    };

    return (
        <SubscriptionWrapper duration={duration} loading={selector.loading}>
            <CardHeader isOverviewHeader={isOverviewHeader} title={label} />
            <>
                {selector.loading || chartData == null ? (
                    <Spinner />
                ) : (
                    <Space
                        direction='vertical'
                        size={'large'}
                        style={{ width: '100%' }}
                    >
                        <Line
                            options={prepareOption()}
                            data={chartData}
                            height={size === 'large' ? '100%' : '150px'}
                        />
                        <>
                            {showTable && (
                                <DataTable
                                    data={selector.data.activity_list.map(
                                        (
                                            item: IActivityListData,
                                            index: number
                                        ) => {
                                            return {
                                                ...item,
                                                key: `${item.date}-${index}`,
                                                date: formateDate(
                                                    item.date,
                                                    dateFormat
                                                ),
                                                cost_basis: currencyToUSD(
                                                    item.cost_basis
                                                ),
                                                market_value: currencyToUSD(
                                                    item.market_value
                                                ),
                                                net_proceed: currencyToUSD(
                                                    item.net_proceed
                                                ),
                                                transaction_type_name:
                                                    blankOrNull(
                                                        item.transaction_type_name,
                                                        'N / A'
                                                    ),
                                                return: currencyToUSD(
                                                    item.return
                                                ),
                                                timestamp:
                                                    item.timestamp === ''
                                                        ? 'N / A'
                                                        : formateDate(
                                                              item.timestamp,
                                                              `${dateFormat} hh:mm A`
                                                          )
                                            };
                                        }
                                    )}
                                    columnData={activityColumns}
                                />
                            )}
                        </>
                        {modal ? (
                            <Modal
                                title={'Transaction History'}
                                visible={modal}
                                closable={true}
                                // maskClosable={false}
                                onCancel={() => setModal(false)}
                                centered={true}
                                footer={[]}
                                width='80%'
                            >
                                {modalLoading ? (
                                    <Spinner />
                                ) : (
                                    // <div>{modalData}</div>
                                    <>
                                        <DataTable
                                            data={modalData?.map(
                                                (items: ITransactionData) => {
                                                    const newDataTimeFormat =
                                                        timeStamp(
                                                            items.timestamp
                                                        );
                                                    const updatedTimeStampValue =
                                                        formateDate(
                                                            newDataTimeFormat[0],
                                                            dateFormat
                                                        );

                                                    return {
                                                        ...items,
                                                        native_amount_usd:
                                                            currencyToUSD(
                                                                items.native_amount_usd
                                                            ),
                                                        timestamp:
                                                            updatedTimeStampValue.concat(
                                                                newDataTimeFormat[1]
                                                            )
                                                    };
                                                }
                                            )}
                                            // sticky
                                            columnData={transactionColumn}
                                            pagination={false}
                                            // scroll={{ x: 800 }}
                                        />
                                    </>
                                )}
                            </Modal>
                        ) : (
                            <></>
                        )}
                    </Space>
                )}
            </>
        </SubscriptionWrapper>
    );
};

export default ActivityChart;
