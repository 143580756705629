import React, { useEffect, useMemo, useState } from 'react';

import DataTable from '../../components/DataTable';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth.hook';
import { useParams } from 'react-router-dom';
import { currencyToUSD } from '../../utils/other.util';
import styles from './index.module.css';

import Spinner from '../../components/Spinner';
import AllocationDropdown, {
    IAssetAllocationDropdownProps
} from '../../components/Chart/Dropdown/AllocationDropdown';
import CardHeader from '../../components/Common/CardHeader';
import DateSelector from '../../components/DateSelector';
import { IFeeAllocationData } from '../../components/Chart/FeeAllocation/index.type';
import FeeAllocation from '../../components/Chart/FeeAllocation';
import { useAppDispatch, useAppSelector } from '../../app/redux-hooks';
import {
    fetchFeeAllocationAsync,
    IFetchFeeAllocationProps
} from '../../app/slices/feeAllocation.slice';
// } from '../../app/slices/assetAllocation.slice';
import { Space } from 'antd';

const pieChartColumns = [
    {
        title: 'Name',
        dataIndex: 'asset',
        key: 'asset'
    },
    {
        title: 'Fee',
        dataIndex: 'fee',
        key: 'fee'
    }
];
const pieChartAccountColumns = [
    {
        title: 'Name',
        dataIndex: 'account',
        key: 'account'
    },
    {
        title: 'Fee',
        dataIndex: 'fee',
        key: 'fee'
    }
];

interface RenderAllAccountDataProps {
    showTable: boolean;
    data: IFeeAllocationData[];
    isAccount: boolean;
}

const RenderAllAccountData: React.FC<RenderAllAccountDataProps> = ({
    showTable = false,
    data,
    isAccount
}: RenderAllAccountDataProps) => {
    const accountToAsset: any = data?.map((item) => {
        return {
            asset: item.account,
            fee: item.fee
        };
    });
    return (
        <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
            {isAccount ? (
                <FeeAllocation
                    pieChartSize='large'
                    holdingAllocationData={accountToAsset}
                />
            ) : (
                <FeeAllocation
                    pieChartSize='large'
                    holdingAllocationData={data}
                />
            )}
            {showTable && (
                <DataTable
                    style={{
                        flex: 1
                    }}
                    data={data.map((value: IFeeAllocationData) => {
                        return {
                            ...value,
                            fee: currencyToUSD(value.fee)
                        };
                    })}
                    columnData={
                        isAccount ? pieChartAccountColumns : pieChartColumns
                    }
                />
            )}
        </Space>
    );
};

const FeeAllocationPage = () => {
    const auth = useAuth();
    const userId = useMemo(() => auth.user?.user?.id, [auth]);
    let { accountId }: any = useParams();

    if (accountId === undefined) accountId = null;
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state.feeAllocationSelector);
    useEffect(() => {
        dispatch(
            fetchFeeAllocationAsync({
                userId,
                accountId
            } as IFetchFeeAllocationProps)
        );
    }, []);
    return (
        <Layout activeItem='Fee Allocation'>
            <DateSelector
                {...{
                    title: 'Fee Allocation',
                    duration: '',
                    page: 'fee-allocation'
                }}
            />
            <div className={styles.mainContainer}>
                <CardHeader title='' isOverviewHeader={false} />
                {selector.loading ? (
                    <Spinner />
                ) : (
                    <div className={styles.mainChartContainer}>
                        {selector.data.length > 0 ? (
                            <RenderAllAccountData
                                data={selector?.data}
                                showTable={true}
                                isAccount={false}
                            />
                        ) : null}
                        <RenderAllAccountData
                            data={selector?.accountData}
                            showTable={true}
                            isAccount={true}
                        />
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default FeeAllocationPage;
