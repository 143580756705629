import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Modal, Popconfirm, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteAccount, getAccounts, getCustodian } from '../../api/account';
import CreateAccount from '../../components/CreateAccount';
import CustomButton from '../../components/CustomButton';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/useAuth.hook';
import { IAccountData, ICustodianAccountData } from '../../interfaces/account';
import styles from './index.module.css';
const AccountPage = () => {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [custodianData, setCustodianData] = useState<ICustodianAccountData[]>(
        []
    );
    const [accountData, setAccountData] = useState<IAccountData[]>([]);

    const getAllCustodian = async () => {
        setLoading(true);
        try {
            const result = await getCustodian(auth.user?.user?.id, 1);
            if (result.success) {
                setCustodianData(result.data);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            message.error('Something went wrong!!!');
        } finally {
            setLoading(false);
        }
    };

    const fetchAccounts = async () => {
        try {
            setLoading(true);
            const result = await getAccounts(1);
            if (result.success) {
                setAccountData(result.data);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            message.error('Something went wrong!!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllCustodian();
        fetchAccounts();
        setDeleted(false);
    }, [deleted]);

    const _deleteAccount = async (accountId: string, userId: string) => {
        try {
            setLoading(true);
            const result = await deleteAccount(accountId, userId);
            if (!result.success) {
                message.error(result.message);
            } else {
                setDeleted(true);
            }
        } catch (error) {
            message.error(`${error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout activeItem='Accounts'>
            {loading ? (
                <div
                    className={styles.loaderBackground}
                    style={{
                        width: '100%',
                        padding: '2rem'
                    }}
                >
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className={styles.dashboardWrapper}>
                        {accountData.length === 0 ? (
                            <>
                                <h1 className={styles.textNoAccount}>
                                    No Account there
                                </h1>
                            </>
                        ) : (
                            <Row gutter={[16, 16]}>
                                {accountData.map(
                                    (account: IAccountData, index: number) => (
                                        <Col
                                            key={account.id}
                                            xs={{ span: 24 }}
                                            lg={{ span: 12 }}
                                        >
                                            <Card
                                                className={styles.cardMapping}
                                            >
                                                <div>
                                                    <Link
                                                        to={`/csv-import/${account.id}`}
                                                    >
                                                        <h2
                                                            className={
                                                                styles.textNoAccount
                                                            }
                                                        >
                                                            {
                                                                account.display_name
                                                            }
                                                        </h2>
                                                    </Link>

                                                    <Popconfirm
                                                        title={
                                                            'Are you sure you want to delete this account?'
                                                        }
                                                        onConfirm={() => {
                                                            _deleteAccount(
                                                                account.id ??
                                                                    '',
                                                                account.user_id ??
                                                                    ''
                                                            );
                                                        }}
                                                        okText='Yes'
                                                        cancelText='No'
                                                    >
                                                        <CustomButton
                                                            withForm={false}
                                                            name='Delete'
                                                            type='text'
                                                            danger={true}
                                                            loading={loading}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                )}
                            </Row>
                        )}
                    </div>
                    <Button
                        onClick={() => setShowModal(true)}
                        type='primary'
                        className={styles.btn}
                    >
                        Import Account
                    </Button>
                    {showModal && (
                        <Modal
                            title='Account'
                            visible={showModal}
                            closable={true}
                            maskClosable={false}
                            onCancel={() => setShowModal(false)}
                            centered={true}
                            footer={[]}
                            className={styles.textCentered}
                        >
                            <CreateAccount
                                data={custodianData}
                                setVisible={setShowModal}
                                setLoading={setLoading}
                            />
                        </Modal>
                    )}
                </>
            )}
        </Layout>
    );
};

export default AccountPage;
