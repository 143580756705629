import {
    fetchWithGet,
    fetchGenerateReportDownloadFile
} from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';

export const getPerformanceChartData = async (
    curr: string,
    from: string,
    to: string,
    duration: string,
    id: any
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `reports/activity_performance?user_id=${id}&market_value_param=emv`
    );
    return response;
};

export const getCoinMarket = async (data: any): Promise<IResponse<any>> => {
    const url = `reports/coin/market?${data}`;
    const response = await fetchWithGet(`${url}`);
    return response;
};

export const getActivityPerformance = async (
    duration: string,
    market_value_param: string,
    user_id: string,
    holding_id?: string,
    account_id?: string
): Promise<IResponse<any>> => {
    let url = `reports/activity_performance?user_id=${user_id}&market_value_param=${market_value_param}&duration=${duration}`;

    if (holding_id != null && holding_id !== '')
        url += `&holding_id=${holding_id}`;

    if (account_id != null && account_id !== '')
        url += `&account_id=${account_id}`;

    const response = await fetchWithGet(url);
    return response;
};

export const getPerformanceALLData = async (
    from: string,
    to: string,
    duration: string,
    id: any
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `reports/activity_performance?user_id=${id}&market_value_param=emv`
    );
    return response;
};
export const getActivityALLData = async (
    from: string,
    to: string,
    duration: string,
    id: any
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `reports/activity_performance?user_id=${id}&market_value_param=emv`
    );
    return response;
};
export const getActivityChartData = async (
    curr: string,
    from: string,
    to: string,
    duration: string,
    id: any
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `reports/activity_performance?user_id=${id}&market_value_param=emv`
    );
    return response;
};

export const getGLData = async (
    id: string,
    method: string = 'FIFO',
    accountId: string | null,
    duration: string = 'as_of_date'
): Promise<IResponse<any>> => {
    let accountIdParams = accountId != null ? `&account_id=${accountId}` : '';
    const response = await fetchWithGet(
        `reports/gain_loss?user_id=${id}&market_value_param=emv&method=${method}&duration=${duration}${accountIdParams}`
    );

    return response;
};

export const getFeeAllocation = async (
    id: any,
    account: boolean = true,
    accountId?: string | null
): Promise<IResponse<any>> => {
    let accountIdParams = accountId != null ? `&account_id=${accountId}` : '';
    const response = await fetchWithGet(
        `reports/fee_allocation?user_id=${id}&account=${account}&${accountIdParams}`
    );
    return response;
};
export const getPosition = async (
    id: any,
    method: string = 'LIFO',
    accountId?: string | null
): Promise<IResponse<any>> => {
    let accountIdParams = accountId != null ? `&account_id=${accountId}` : '';
    const response = await fetchWithGet(
        `reports/position?user_id=${id}&method=${method}&${accountIdParams}`
    );
    return response;
};
export const generateReport = async (): Promise<IResponse<any>> => {
    const response = await fetchGenerateReportDownloadFile(
        `reports/generate-report`
    );
    return response;
};
