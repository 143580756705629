import React, { useEffect, useMemo, useState } from 'react';

import { Line } from 'react-chartjs-2';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import { useAuth } from '../../../hooks/useAuth.hook';
import { IActivityListData } from '../ActivityChart/ActivityChart.type';
import {
    blankOrNull,
    currencyToUSD,
    formateDate
} from '../../../utils/other.util';
import Spinner from '../../Spinner';
import { Select, Space, Empty } from 'antd';
import { TPerformanceChartProps } from './PerformanceChart.type';
import DataTable from '../../DataTable';
import styles from './PerformanceChart.module.css';
import { useAppDispatch, useAppSelector } from '../../../app/redux-hooks';
import {
    fetchReportAsync,
    IFetchReportProps
} from '../../../app/slices/activityPerformance.slice';
import dayjs from 'dayjs';
import CardHeader from '../../Common/CardHeader';
import SubscriptionWrapper from '../../SubscriptionWrapper';
import CoinMarket from '../../CoinMarket';
const { Option } = Select;

const performanceColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date'
    },
    {
        title: 'Market Value',
        dataIndex: 'market_value',
        key: 'market_value'
    },
    {
        title: 'Return',
        dataIndex: 'return',
        key: 'return'
    },
    {
        title: 'Performance (%)',
        dataIndex: 'performance',
        key: 'performance'
    }
];

const GRAPH_LABEL_1 = 'Performance';
const GRAPH_LABEL_2 = 'BTC Performance';

const PerformanceChart: React.FC<TPerformanceChartProps> = ({
    label = '',
    showTable = false,
    showSelectionHolding = [],
    duration = 'as_of_date',
    size = 'small',
    accountId = null,
    isOverviewHeader = false
}: TPerformanceChartProps) => {
    const { dateFormat, marketValue } = useAppSetting();
    const auth = useAuth();
    const dispatch = useAppDispatch();

    const [chartData, setChartData] = useState<any>(null);

    const [selectedHoldingId, setSelectedHoldingId] = useState(
        showSelectionHolding.length > 0 ? showSelectionHolding[0].id : ''
    );
    const [selectedGeckoName, setSelectedGeckoName] = useState(
        showSelectionHolding.length > 0
            ? showSelectionHolding[0].coin_gecko_id
            : ''
    );

    const selector = useAppSelector(
        (state) => state.activityPerformanceSelector
    );
    useEffect(() => {
        dispatch(
            fetchReportAsync({
                duration,
                marketValue,
                userId: auth.user?.user?.id,
                selectedHoldingId,
                accountId
            } as IFetchReportProps)
        );
    }, [duration, selectedHoldingId, accountId]);

    const line1C: string = useMemo<string>(
        () => selector.performanceChartLineColor,
        [selector.performanceChartLineColor]
    );
    const line2C: string = useMemo<string>(
        () => selector.activityChartLineColor,
        [selector.activityChartLineColor]
    );

    const handleSelectedHolding = (targetValue: string) => {
        for (let i = 0; i < showSelectionHolding.length; i++) {
            if (showSelectionHolding[i].id === targetValue) {
                setSelectedGeckoName(showSelectionHolding[i].coin_gecko_id);
                break;
            }
        }
        setSelectedHoldingId(targetValue);
    };
    useEffect(() => {
        const list = selector?.data?.activity_list ?? [];

        setChartData({
            labels: list?.map((value: IActivityListData) =>
                formateDate(value.date, dateFormat)
            ),
            datasets: [
                {
                    label: GRAPH_LABEL_1,
                    data: list.map(
                        (value: IActivityListData) => value.performance
                    ),
                    borderColor: line1C,
                    backgroundColor: line1C,
                    borderWidth: -1
                },
                {
                    label: GRAPH_LABEL_2,
                    data: list.map(
                        (value: IActivityListData) => value.btc_performance
                    ),
                    borderColor: line2C,
                    backgroundColor: line2C,
                    borderWidth: -1
                }
            ]
        });
    }, [selector.loading]);

    const prepareOption = () => {
        if (chartData === null) return {};
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                    display: isOverviewHeader ? false : true
                },
                tooltip: {
                    callbacks: {
                        beforeTitle: function (context: any) {
                            try {
                                return `Date: ${dayjs(
                                    selector.data.activity_list[
                                        context[0].dataIndex
                                    ].date
                                ).format(dateFormat)}`;
                            } catch (e) {
                                return '';
                            }
                        },
                        title: function (_: any) {
                            return '';
                        },
                        label: function (context: any) {
                            let label = ` ${context.dataset.label}`;
                            const value = context.parsed.y;
                            if (value != null) {
                                if (label === ` ${GRAPH_LABEL_1}`) {
                                    label += `: ${value.toFixed(
                                        2
                                    )}%\n Return: ${currencyToUSD(
                                        selector.data.activity_list?.[
                                            context.dataIndex
                                        ]?.return
                                    )}`;
                                }
                                if (label === ` ${GRAPH_LABEL_2}`) {
                                    label += `: ${value.toFixed(
                                        2
                                    )}%\n Price: ${currencyToUSD(
                                        selector.data.activity_list?.[
                                            context.dataIndex
                                        ]?.btc_price
                                    )}`;
                                }
                            }

                            return label;
                        }
                    }
                }
            },
            interaction: {
                mode: 'index' as const,
                intersect: false
            },
            stacked: false,
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20,
                        labelOffset: 25
                    }
                },
                y: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (
                            value: any,
                            index: number,
                            ticks: any
                        ) {
                            return `${value}%`;
                        }
                    }
                }
            }
        };
    };
    return (
        <div>
            {selector?.data?.activity_list.length === 0 || chartData == null ? (
                <SubscriptionWrapper
                    duration={duration}
                    loading={selector.loading}
                >
                    <CardHeader
                        isOverviewHeader={isOverviewHeader}
                        title={label}
                    >
                        {selector.loading ? (
                            <></>
                        ) : (
                            <Space
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline'
                                }}
                            >
                                {showSelectionHolding.length > 0 && (
                                    <Select
                                        value={selectedHoldingId}
                                        onChange={(targetValue) =>
                                            handleSelectedHolding(targetValue)
                                        }
                                        className={styles.dropDownMenu}
                                    >
                                        {showSelectionHolding.map(
                                            (item: any) => (
                                                <Option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.asset}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Space>
                        )}
                    </CardHeader>
                    <Space
                        direction='vertical'
                        size='large'
                        align='center'
                        style={{ width: '100%' }}
                    >
                        <Empty />
                        {/* <div>No data found</div> */}
                    </Space>
                </SubscriptionWrapper>
            ) : (
                <SubscriptionWrapper
                    duration={duration}
                    loading={selector.loading}
                >
                    <CardHeader
                        isOverviewHeader={isOverviewHeader}
                        title={label}
                    >
                        {selector.loading || chartData == null ? (
                            <></>
                        ) : (
                            <Space
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline'
                                }}
                            >
                                {showSelectionHolding.length > 0 && (
                                    <Select
                                        value={selectedHoldingId}
                                        onChange={(targetValue) =>
                                            handleSelectedHolding(targetValue)
                                        }
                                        className={styles.dropDownMenu}
                                    >
                                        {showSelectionHolding.map(
                                            (item: any) => (
                                                <Option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.asset}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Space>
                        )}
                    </CardHeader>

                    {selector.loading ? (
                        <Spinner />
                    ) : (
                        <Space
                            direction='vertical'
                            size='large'
                            style={{ width: '100%' }}
                        >
                            <Line
                                options={prepareOption()}
                                data={chartData}
                                height={size === 'large' ? '100%' : '150px'}
                            />
                            {selectedGeckoName ? (
                                <CoinMarket
                                    holdingData={showSelectionHolding}
                                    coinGeckoName={selectedGeckoName}
                                />
                            ) : (
                                <></>
                            )}
                            {showTable && (
                                <div className={styles.mainContainerDataTable}>
                                    <DataTable
                                        data={selector.data?.activity_list.map(
                                            (
                                                item: IActivityListData,
                                                index: number
                                            ) => {
                                                return {
                                                    ...item,
                                                    key: `${item.date}-${index}`,
                                                    transaction_type_name:
                                                        blankOrNull(
                                                            item.transaction_type_name,
                                                            'N / A'
                                                        ),
                                                    return: currencyToUSD(
                                                        item.return
                                                    ),
                                                    performance: `${item.performance.toFixed(
                                                        4
                                                    )}%`,
                                                    net_proceed: currencyToUSD(
                                                        item.net_proceed
                                                    ),
                                                    market_value: currencyToUSD(
                                                        item.market_value
                                                    ),
                                                    cost_basis: currencyToUSD(
                                                        item.cost_basis
                                                    ),
                                                    date: formateDate(
                                                        item.date,
                                                        dateFormat
                                                    ),
                                                    timestamp:
                                                        item.timestamp === ''
                                                            ? 'N / A'
                                                            : formateDate(
                                                                  item.timestamp,
                                                                  `${dateFormat} hh:mm A`
                                                              )
                                                };
                                            }
                                        )}
                                        columnData={performanceColumns}
                                    />
                                </div>
                            )}
                        </Space>
                    )}
                </SubscriptionWrapper>
            )}
        </div>
    );
};

export default PerformanceChart;
