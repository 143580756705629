import React from 'react';
import { Card } from 'antd';
import styles from './index.module.css';
import Spinner from '../Spinner';
import { ISubscriptionWrapperProps } from './SubscriptionWrapper.type';
import { getLocalValue } from '../../utils/local-storage.util';
import { LS_USER_KEY } from '../../utils/constants.util';
const SubscriptionWrapper = (props: ISubscriptionWrapperProps) => {
    const user =
        getLocalValue(LS_USER_KEY) && JSON.parse(getLocalValue(LS_USER_KEY));
    const userRole = user?.role;
    const permission: any = [];
    for (var i = 0; i < user?.permissions?.length; i++) {
        for (var key in user.permissions[i]) {
            if (user.permissions[i][key].indexOf(props.duration) != -1) {
                permission.push(user.permissions[i]);
            }
        }
    }
    // if (props.duration === 'last_12m' && permission.length === 0) {
    //     permission.push({
    //         datatype: 'boolean',
    //         type_name: 'last_12m',
    //         type_value: 'false'
    //     });
    // }
    return props.loading ? (
        <Spinner />
    ) : permission[0]?.type_value === 'false' || permission.length === 0 ? (
        <div className={styles.cardMainContainer}>
            <Card className={styles.cardContainer}>
                <h3>Offer a special "subscribers only" incentive.</h3>
                <button className={styles.btn}>Upgrade</button>
            </Card>
        </div>
    ) : (
        <>{props.children}</>
    );
};

export default SubscriptionWrapper;
