import { Select } from 'antd';
import styles from './AllocationDropdown.module.css';
const { Option } = Select;

export interface IAssetAllocationDropdownProps {
    category: 'Chart' | 'Details';
    setCategory: Function;
    showCategoryDropdown: boolean;
}

const AssetAllocationDropdown: React.FC<IAssetAllocationDropdownProps> = (
    props: IAssetAllocationDropdownProps
) => {
    return (
        <div className={styles.selectionContainer}>
            {props.showCategoryDropdown && (
                <div>
                    <Select
                        value={props.category}
                        onChange={(cat) => props.setCategory(cat)}
                        className='headingColor'
                    >
                        {['Chart', 'Details'].map((items: string) => (
                            <Option value={items}>{items}</Option>
                        ))}
                    </Select>
                </div>
            )}
        </div>
    );
};

export default AssetAllocationDropdown;
