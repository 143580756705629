import { Pagination, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTransaction, getTransactionList } from '../../api/transaction';
import CardHeader from '../../components/Common/CardHeader';
import DataTable from '../../components/DataTable';
import DateSelector from '../../components/DateSelector';
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/useAuth.hook';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import {
    currencyToUSD,
    formateDate,
    timeStamp,
    fixedDecimal
} from '../../utils/other.util';
import styles from './transaction.module.css';
import { ITransactionData } from './transaction.type';
import dayjs from 'dayjs';
import SubscriptionWrapper from '../../components/SubscriptionWrapper';

const { Option } = Select;

const transactionColumn = [
    {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        fixed: 'left',
        sorter: (a: ITransactionData, b: ITransactionData) =>
            dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1,
        width: 180
    },
    {
        title: 'Account',
        dataIndex: 'account_name',
        key: 'account_name',
        sorter: (a: ITransactionData, b: ITransactionData) =>
            a.account_name.length - b.account_name.length
    },
    {
        title: 'Type',
        dataIndex: 'transaction_type_name',
        key: 'transaction_type_name',
        sorter: (a: ITransactionData, b: ITransactionData) =>
            a.transaction_type_name.length - b.transaction_type_name.length
    },
    {
        title: 'Sent Currency',
        dataIndex: 'from_currency',
        key: 'from_currency',
        sorter: (a: ITransactionData, b: ITransactionData) => {
            return a.from_currency.localeCompare(b.from_currency);
        },
        // sorter: (a: ITransactionData, b: ITransactionData) =>{return a.from_currency.localeCompare(b.from_currency)},
        render: (currency: string) => {
            return currency === '' ? '-' : currency;
        }
    },
    {
        title: 'Sent Amount',
        dataIndex: 'from_amount',
        key: 'from_amount',
        render: (amount: string, rowData: ITransactionData) => {
            const fromCurrency = rowData?.from_currency;
            return rowData?.from_currency === 'USD'
                ? currencyToUSD(rowData?.from_amount)
                : fixedDecimal(rowData?.from_amount);
            // return amount === '' ? '-' : amount;
        },
        sorter: (a: ITransactionData, b: ITransactionData) =>
            a.from_amount - b.from_amount
    },
    {
        title: 'Received Currency',
        dataIndex: 'to_currency',
        key: 'to_currency',
        sorter: (a: ITransactionData, b: ITransactionData) => {
            return a?.to_currency?.localeCompare(b?.to_currency);
        },
        render: (currency: string) => {
            return currency === '' ? '-' : currency;
        }
    },
    {
        title: 'Received Amount',
        dataIndex: 'to_amount',
        key: 'to_amount',
        render: (amount: string, rowData: ITransactionData) => {
            return rowData?.to_currency === 'USD'
                ? currencyToUSD(rowData?.to_amount)
                : fixedDecimal(rowData?.to_amount);
            // return amount === '' ? '-' : amount;
        },
        sorter: (a: ITransactionData, b: ITransactionData) =>
            a.to_amount - b.to_amount
    },
    {
        title: 'Fee Currency',
        dataIndex: 'fee_currency',
        key: 'fee_currency',
        sorter: (a: ITransactionData, b: ITransactionData) => {
            if (
                a &&
                a.fee_currency &&
                a.fee_currency.length &&
                b &&
                b.fee_currency &&
                b.fee_currency.length
            ) {
                return a?.fee_currency?.localeCompare(b?.fee_currency);
            } else if (a && a.fee_currency && a.fee_currency.length) {
                return -1;
            } else if (b && b.fee_currency && b.fee_currency.length) {
                return 1;
            }
            return 0;
        },
        // sorter: (a: ITransactionData, b: ITransactionData) =>{return a.fee_currency.localeCompare(b.fee_currency)},
        render: (currency: string, rowData: ITransactionData) => {
            return rowData?.fee_currency === '' ||
                rowData?.fee_currency === null
                ? '-'
                : currency;
        }
    },
    {
        title: 'Fee Amount',
        dataIndex: 'fee',
        key: 'fee',
        sorter: (a: ITransactionData, b: ITransactionData) => a.fee - b.fee,
        render: (amount: string) => {
            return amount === '' ? '-' : amount;
        }
    }
];

interface TransactionDataState {
    data: Array<ITransactionData>;
    currentPage: number;
    totalPage: number;
    ascending: boolean;
}

const TransactionPage = () => {
    const { dateFormat } = useAppSetting();
    const { accountId }: any = useParams();
    const PAGE_SIZE = 2000;
    const [duration, setDuration] = useState('as_of_date');
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionSelectList, setTransactionSelectList] = useState<any>();
    const [transaction_type_id, setTransaction_type_id] = useState('');

    const [dataState, setDataState] = useState<TransactionDataState>({
        data: [],
        currentPage: 1,
        totalPage: 1,
        ascending: true
    });

    const auth = useAuth();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await getTransaction(
                    auth.user?.user.id,
                    dataState.currentPage,
                    PAGE_SIZE,
                    dataState.ascending,
                    accountId,
                    duration,
                    transaction_type_id
                );
                const transactionResultList = await getTransactionList();
                const transactionTypeSelect: any = [];
                transactionResultList?.data.forEach((item: any) => {
                    transactionTypeSelect.push({
                        value: item.name,
                        key: item.id
                    });
                });
                setTransactionSelectList(transactionTypeSelect);

                if (result.success) {
                    setDataState({
                        data: result.data,
                        totalPage: result?.totalPages ?? 1,
                        currentPage: dataState.currentPage,
                        ascending: dataState.ascending
                    });
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [
        dataState.ascending,
        dataState.currentPage,
        duration,
        transaction_type_id
    ]);

    const handlePagination = (pagination: number) => {
        setDataState({
            ...dataState,
            currentPage: pagination
        });
    };

    return (
        <Layout activeItem={'Transactions'}>
            <DateSelector
                {...{
                    title: 'Transactions',
                    duration,
                    setDuration,
                    page: `transaction`
                }}
            />
            <div className={styles.mainContainer}>
                <div className={styles.transactionTypeSelection}>
                    <Select
                        value={transaction_type_id}
                        onChange={(e) => {
                            setTransaction_type_id(e);
                        }}
                        style={{ width: '200px' }}
                    >
                        <Option disabled>Select Transaction Type</Option>
                        <Option value=''>None</Option>
                        {transactionSelectList?.map((elem: any) => {
                            return <Option key={elem.key}>{elem.value}</Option>;
                        })}
                    </Select>
                </div>
                <SubscriptionWrapper duration={duration} loading={loading}>
                    <CardHeader isOverviewHeader={false} title='' />
                    {!loading ? (
                        <>
                            <DataTable
                                data={dataState.data.map(
                                    (items: ITransactionData) => {
                                        const newDataTimeFormat = timeStamp(
                                            items.timestamp
                                        );
                                        const updatedTimeStampValue =
                                            formateDate(
                                                newDataTimeFormat[0],
                                                dateFormat
                                            );

                                        return {
                                            ...items,
                                            // from_amount:
                                            //     items.from_currency === 'USD'
                                            //         ? currencyToUSD(
                                            //               items.from_amount
                                            //           )
                                            //         : items.from_amount,
                                            // to_amount:
                                            //     items.to_currency === 'USD'
                                            //         ? currencyToUSD(items.to_amount)
                                            //         : items.to_amount,
                                            native_amount_usd: currencyToUSD(
                                                items.native_amount_usd
                                            ),
                                            timestamp:
                                                updatedTimeStampValue.concat(
                                                    newDataTimeFormat[1]
                                                )
                                        };
                                    }
                                )}
                                // sticky
                                columnData={transactionColumn}
                                pagination={false}
                                // scroll={{ x: 800 }}
                            />
                            <br />
                            <div style={{ textAlign: 'center' }}>
                                <Pagination
                                    defaultPageSize={PAGE_SIZE}
                                    current={dataState.currentPage}
                                    onChange={handlePagination}
                                    total={dataState.totalPage}
                                />
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </SubscriptionWrapper>
            </div>
        </Layout>
    );
};

export default TransactionPage;
