import { fetchWithPost, fetchWithGet } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';

import { DEFAULT_PAGE_SIZE } from '../utils/constants.util';
import { IAccountData, ICreataAccountData } from '../interfaces/account';

export const getAccounts = async (
    page: Number,
    size: Number = DEFAULT_PAGE_SIZE,
    token?: string
): Promise<IResponse<any>> => {
    let response = null;
    const url = `account?page=${page}&size=${size}`;

    if (token) {
        response = await fetchWithGet(url, {
            Authorization: `Bearer ${token}`
        });
    } else response = await fetchWithGet(url);
    return response;
};

export const getCustodian = async (
    id: any,
    page: Number,
    size: Number = DEFAULT_PAGE_SIZE
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `custodian?page=${page}&size=${size}&user_id=${id}`
    );
    return response;
};

export const createAccount = async (
    data: ICreataAccountData
): Promise<IResponse<any>> => {
    const response = await fetchWithPost(`account/create`, data);
    return response;
};

export const deleteAccount = async (
    account_id: string,
    user_id: string
): Promise<IResponse<any>> => {
    const response = await fetchWithPost(
        `account/delete?account_id=${account_id}`,
        null,
        null,
        'DELETE'
    );
    return response;
};

export const getAccountById = async (
    id: string
): Promise<IResponse<IAccountData>> => {
    const response = await fetchWithGet(`account/${id}`);
    return response;
};

export const uploadCsvFile = async (
    accountId: string,
    formData: FormData,
    mapper?: string | null
): Promise<IResponse<any>> => {
    let url = `csv/${accountId}/validate`;

    if (mapper != null && mapper !== '') url += `?mapper=${mapper}`;
    const response = await fetchWithPost(url, formData);
    return response;
};

export const uploadCsvLink = async (
    accountId: string,
    csv_link?: string | null
): Promise<IResponse<any>> => {
    let url = `csv/${accountId}/upload`;
    const response = await fetchWithPost(url, { csv_link });
    return response;
};
export const updateAccountUsingApi = async (
    accountId: string,
    api_key?: string | null,
    secret_key?: string | null
): Promise<IResponse<any>> => {
    let url = `account/${accountId}/update`;
    const response = await fetchWithPost(
        url,
        { api_key, secret_key },
        null,
        'PATCH'
    );
    return response;
};
