import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from '../../CustomInput';
import CustomButton from '../../CustomButton';
import { useHistory } from 'react-router-dom';
import { resetPassword } from '../../../api/auth';
import { showToast } from '../../../utils/notifications.util';
import {
    MIN_PASSWORD_CHAR,
    MAX_PASSWORD_CHAR,
    PASSWORD_REGEX
} from '../../../utils/constants.util';
import styles from './index.module.css';

const ResetPassword = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();

    const formSchema = Yup.object().shape({
        new_password: Yup.string()
            .required('Password is required')
            .min(
                JSON.parse(MIN_PASSWORD_CHAR),
                `Min character should be ${MIN_PASSWORD_CHAR}`
            )
            .max(
                JSON.parse(MAX_PASSWORD_CHAR),
                `Max character should be ${MAX_PASSWORD_CHAR}`
            )
            .matches(
                PASSWORD_REGEX,
                'minimum of special characters and numbers are required.'
            ),
        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .min(
                JSON.parse(MIN_PASSWORD_CHAR),
                `Min character should be ${MIN_PASSWORD_CHAR}`
            )
            .max(
                JSON.parse(MAX_PASSWORD_CHAR),
                `Max character should be ${MAX_PASSWORD_CHAR}`
            )
            .oneOf([Yup.ref('new_password')], 'Passwords do not match')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(formSchema)
    });

    const handlePasswordFormSubmit = async (obj: any) => {
        setLoading(true);
        const newData = {
            token: props.token,
            ...obj
        };
        const result = await resetPassword(newData);

        if (result.success) {
            push('/');
            showToast(true, result.message);
        } else {
            if (result?.message !== '' || result?.message != null) {
                showToast(false, `${result?.message}`);
            } else showToast(false, `Invalid Credentials`);
        }

        setLoading(false);
    };

    return (
        <form
            onSubmit={handleSubmit(handlePasswordFormSubmit)}
            className='formContainer'
        >
            <CustomInput
                withForm={true}
                name='new_password'
                withLabel={true}
                label='NEW PASSWORD'
                register={register}
                type='password'
            />

            <div className='error'>{errors?.new_password?.message}</div>
            <br />
            <CustomInput
                withForm={true}
                name='confirm_password'
                withLabel={true}
                label='CONFIRM NEW PASSWORD'
                register={register}
                type='password'
            />
            <div className='error'>{errors?.confirm_password?.message}</div>
            <br />

            <CustomButton withForm={true} name='RESET' loading={loading} />
        </form>
    );
};

export default ResetPassword;
