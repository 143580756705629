import { useEffect, useState } from 'react';
import { getGLData, generateReport } from '../../../api/reports';
import GainLossComponent from '../../../components/GainLoss';
import { IGainLossData } from '../../../components/GainLoss/GainLoss.type';
import Spinner from '../../../components/Spinner';
import { useAuth } from '../../../hooks/useAuth.hook';
import { useQuery } from 'react-query';
import CardHeader from '../../../components/Common/CardHeader';
import { HOCGainLossProps } from './HOCGainLoss.type';
import { Select, Space, message, Button } from 'antd';
import { COST_BASIS_METHOD_ENUM } from '../../../utils/constants.util';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';
import styles from './index.module.css';

const { Option } = Select;

const HOCGainLoss: React.FC<HOCGainLossProps> = ({
    label = '',
    showMethodSelection = false,
    accountId = null,
    isOverviewHeader = false
}) => {
    const auth = useAuth();
    const { costBasis, generateReportLoading, setGenerateReportLoading } =
        useAppSetting();

    const [method, setMethod] = useState(costBasis);
    const [duration, setDuration] = useState('as_of_date');

    const { isLoading, isFetching, data } = useQuery<any, any>(
        ['GainLoss', method, duration],
        () => getGLData(auth.user?.user?.id ?? '', method, accountId, duration)
    );
    const generateReportFun = async () => {
        let result: any = {};
        try {
            message.success(
                'It may take sometime to generate report please wait.'
            );
            setGenerateReportLoading(true);
            result = await generateReport();
            if (result?.success) {
                var a = document.createElement('a');
                a.target = '_blank';
                a.href = result?.data?.url;
                a.download = result?.data?.fileName;
                a.click();
                a.remove();
                setGenerateReportLoading(false);
            } else {
                message.error('Something went wrong!!!');
                setGenerateReportLoading(false);
            }
        } catch (error) {
            setGenerateReportLoading(false);
            message.error('Something went wrong!!!');
        } finally {
            setGenerateReportLoading(false);
        }
    };

    const [lGData, setLGData] = useState<IGainLossData>({
        rgl_in_percentage: 0,
        rgl_in_usd: 0,
        urgl_in_percentage: 0,
        urgl_in_usd: 0,
        st_rgl_in_usd: 0,
        lt_ugl_in_usd: 0,
        st_ugl_in_usd: 0,
        lt_rgl_in_usd: 0
    });

    useEffect(() => {
        const response: IGainLossData = data?.data ?? null;
        if (!response || !data.success) return;

        setLGData(response);
    }, [data, method, duration]);

    return (
        <>
            <CardHeader title={label} isOverviewHeader={isOverviewHeader}>
                {showMethodSelection ? (
                    <Space>
                        <Button
                            disabled={generateReportLoading ? true : false}
                            className={styles.generateReportButton}
                            onClick={() => generateReportFun()}
                        >
                            {generateReportLoading ? (
                                <Spinner className={`whiteSpinner`} />
                            ) : (
                                `Generate Report`
                            )}
                        </Button>
                        <Select
                            value={duration}
                            onChange={setDuration}
                            style={{ width: '130px' }}
                        >
                            <Option value='' disabled>
                                Duration
                            </Option>
                            {[
                                { label: 'As of Date', value: 'as_of_date' },
                                { label: 'YTD', value: 'ytd' }
                            ].map((elem: { [key: string]: string }) => {
                                return (
                                    <Option key={elem.value} value={elem.value}>
                                        {elem.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Select
                            value={method}
                            onChange={setMethod}
                            style={{ width: '100px' }}
                        >
                            <Option value='' disabled>
                                Cost Basis Method
                            </Option>
                            {COST_BASIS_METHOD_ENUM.map(
                                (elem: { [key: string]: string }) => {
                                    return (
                                        <Option
                                            key={elem.value}
                                            value={elem.value}
                                        >
                                            {elem.label}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </Space>
                ) : (
                    <></>
                )}
            </CardHeader>
            {isLoading || isFetching ? (
                <Spinner />
            ) : (
                <>
                    <GainLossComponent
                        isOverviewHeader={isOverviewHeader}
                        data={lGData}
                    />
                </>
            )}
        </>
    );
};

export default HOCGainLoss;
