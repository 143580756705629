import React from 'react';
import { useAuth } from '../../hooks/useAuth.hook';
import { IAuthWrapperProps } from './AuthWrapper.type';
import { Redirect } from 'react-router';
import styles from './AuthWrapper.module.css';
const AuthWrapper: React.FC<IAuthWrapperProps> = ({
    leftBackgroundUrlImage = '',
    children
}) => {
    const auth = useAuth();

    return !auth.isLoggedIn() || !auth?.user || !auth?.user?.permissions ? (
        <div className={styles.mainContainerWrapper}>
            <div
                style={{
                    backgroundImage: `url(${leftBackgroundUrlImage})`
                }}
                className={styles.leftContainerWrapper}
            />
            <div className={styles.rightContainerWrapper}>{children}</div>
        </div>
    ) : (
        <Redirect to='/' />
    );
};

export default AuthWrapper;
