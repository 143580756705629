import { useState } from 'react';
import { Steps } from 'antd';

// Custom components
import { Link } from 'react-router-dom';
import CoinCalcuBlack from '../../assets/COINCALCU-black.png';
import CoinCalcuWhite from '../../assets/COINCALCU-white.png';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import RegistrationForm from '../../components/Forms/RegistrationForm/RegistrationForm';
import VerificationForm from '../../components/Forms/VerificationForm';
import styles from './index.module.css';
import { PLATFORM_NAME } from '../../utils/constants.util';
const { Step } = Steps;

const RegistrationComponent = () => {
    const [otpToken, setOtpToken] = useState();
    const { theme } = useAppSetting();

    const [current, setCurrent] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState(0);

    const steps = [
        {
            content: (
                <RegistrationForm
                    setCurrent={setCurrent}
                    setOtpToken={setOtpToken}
                    setPhoneNumber={setPhoneNumber}
                />
            )
        },
        {
            content: (
                <VerificationForm
                    otpToken={otpToken}
                    phoneNumber={phoneNumber}
                />
            )
        }
    ];

    return (
        <div className='rightContainer'>
            <h2 className={styles.logo}>
                {theme === 'dark' ? (
                    <img
                        className={styles.headingContainer}
                        src={CoinCalcuWhite}
                        alt='logo'
                        height={130}
                    />
                ) : (
                    <img
                        className={styles.headingContainer}
                        src={CoinCalcuBlack}
                        alt='logo'
                        height={130}
                    />
                )}
            </h2>
            <div className={styles.stepperWrapper}>
                <div>{steps[current].content}</div>
                <br />
                <br />
                <div className={styles.stepperContentWrapper}>
                    <Steps progressDot current={current}>
                        <Step />
                        <Step />
                    </Steps>
                </div>
                <div className={styles.backLoginMain}>
                    <div>Already have an account? </div>
                    <Link to='/login' className={styles.loginLink}>
                        Log in
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RegistrationComponent;
