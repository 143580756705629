import Layout from '../../components/Layout';
import DateSelector from '../../components/DateSelector';
import styles from './index.module.css';
import { useEffect, useState } from 'react';
import PerformanceChart from '../../components/Chart/PerformanceChart';

import { useQuery } from 'react-query';
import { getHoldingList } from '../../api/holding';
import { useAuth } from '../../hooks/useAuth.hook';
import { IHoldingData } from '../../interfaces/holding';

const PerformanceHoldingPage = () => {
    const auth = useAuth();
    const [duration, setDuration] = useState('as_of_date');
    const [holdingList, setHoldingList] = useState<IHoldingData[]>([]);
    const { data } = useQuery<any, any>('Holding Performance', () =>
        getHoldingList(auth.user?.user?.id ?? '', 1, 1000)
    );

    useEffect(() => {
        const response: any = data?.data ?? null;
        if (!response || !data.success) return;

        setHoldingList(response);
    }, [data]);

    return (
        <Layout activeItem={'Holding Performance'}>
            <DateSelector
                {...{ title: 'Holding Performance', duration, setDuration }}
            />
            <div className={styles.mainContainer}>
                {holdingList.length > 0 && (
                    <PerformanceChart
                        label={''}
                        defaultDataRepresentation={'chart'}
                        showTable={true}
                        showSelectionHolding={holdingList}
                        duration={duration}
                        size={'large'}
                        isOverviewHeader={false}
                    />
                )}
            </div>
        </Layout>
    );
};

export default PerformanceHoldingPage;
