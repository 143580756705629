import React from 'react';
import Layout from '../../components/Layout';
import { Button, Form, Input } from 'antd';
import styles from './index.module.css';
import ChangePasswordSVG from '../../assets/change_password_form.svg';
import { PASSWORD_REGEX } from '../../utils/constants.util';
import DateSelector from '../../components/DateSelector';

const ChangePassword = () => {
    const [form] = Form.useForm();

    const onFinish = (values: string[]) => {
        console.log('Received values of form: ', values);
    };
    return (
        <Layout activeItem='Change Password'>
            <DateSelector
                {...{
                    title: 'Change Password',
                    duration: ''
                }}
            />
            <div className={styles.mainContainer}>
                <div className={styles.imgContainer}>
                    <img
                        src={ChangePasswordSVG}
                        alt='ChangePasswordSVG'
                        height={140}
                    />
                </div>
                <Form
                    form={form}
                    name='register'
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <span className={styles.inputLabel}>Current Password</span>
                    <Form.Item
                        name='current password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your current password!'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            style={{ color: 'red !important' }}
                            placeholder='XXXXXXXXX'
                            className={styles.inputStyles}
                        />
                    </Form.Item>

                    <span className={styles.inputLabel}> New Password</span>

                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!'
                            },
                            {
                                pattern: PASSWORD_REGEX,
                                message: 'Please follow the note below.'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            placeholder='XXXXXXXXX'
                            className={styles.inputStyles}
                        />
                    </Form.Item>
                    <span className={styles.inputLabel}>
                        Confirm New Password
                    </span>

                    <Form.Item
                        name='confirm'
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(
                                            'The passwords that you entered do not match!'
                                        )
                                    );
                                }
                            })
                        ]}
                    >
                        <Input.Password
                            placeholder='XXXXXXXXX'
                            className={styles.inputStyles}
                        />
                    </Form.Item>
                    <div className={styles.validationContainer}>
                        <h3 className={styles.textColor}>Note</h3>

                        <ul>
                            <li className={styles.textColorMuted}>
                                Password should be between 8 & 124 characters
                            </li>
                            <li className={styles.textColorMuted}>
                                Contains at least one number
                            </li>
                            <li className={styles.textColorMuted}>
                                Contains at least one Symbol
                            </li>
                        </ul>
                    </div>

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            color='#116466'
                            className={styles.btn}
                        >
                            Update Password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
};

export default ChangePassword;
