import { Select } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.hook';
import {
    DURATION_LIST,
    COST_BASIS_METHOD_ENUM
} from '../../utils/constants.util';
import AccountRadioGroup from '../Common/AccountRadioGroup';
import styles from './index.module.css';
const { Option } = Select;

interface IDateSelectorProp {
    title?: string;
    duration?: string;
    setDuration?: Function;
    page?: string;
    accountId?: string | null;
}

const DateSelector: React.FC<IDateSelectorProp> = ({
    title = undefined,
    duration = '',
    setDuration = undefined,
    page = undefined
}: IDateSelectorProp) => {
    const auth = useAuth();
    const { accountId }: any = useParams();

    const handleGroupByChange = (groupByDropDown: string) => {
        setDuration && setDuration(groupByDropDown);
    };

    return (
        <div className={styles.mainContainer}>
            <h2 className={styles.mainHeading}>
                {title ? (
                    <>
                        {title === '' ? (
                            <>
                                {`${auth.user?.user.first_name}
                            ${auth.user?.user.last_name} Portfolio`}
                            </>
                        ) : (
                            <>{title}</>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </h2>

            <div className={styles.rightContainer}>
                {page != null && (
                    <AccountRadioGroup page={page} accountId={accountId} />
                )}

                {setDuration && (
                    <div>
                        <Select
                            value={duration}
                            onChange={handleGroupByChange}
                            style={{ width: '150px' }}
                        >
                            <Option value='' disabled>
                                Select duration
                            </Option>
                            {DURATION_LIST.map(
                                (elem: { [key: string]: string }) => {
                                    return (
                                        <Option
                                            key={elem.value}
                                            value={elem.value}
                                        >
                                            {elem.label}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateSelector;
