import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
    currencyToUSD,
    fixedDecimal,
    getRandomColorUsingIndex
} from '../../../utils/other.util';
import { IAssetAllocationData, IAssetAllocationProps } from './index.type';
import styles from './AssetAllocation.module.css';
import { useAppSetting } from '../../../hooks/useAppSetting.hooks';

interface IAssetData {
    data: IAssetAllocationData[];
    label?: any;
}

const AssetAllocation: React.FC<IAssetAllocationProps> = ({
    holdingAllocationData = [],
    showOtherCategory = true,
    pieChartSize = 'small'
}: IAssetAllocationProps) => {
    const list = holdingAllocationData ?? [];
    const firstMount = useRef(false);
    const { assetAllocation } = useAppSetting();
    let [allAssetDataArray, setAllAssetDataArray] = useState<
        IAssetAllocationData[]
    >([]);
    let [otherArr, setOtherArr] = useState<IAssetAllocationData[]>([]);
    const [overallSum, setOverallSum] = useState<number>(0);
    const [assetData, setAssetData] = useState<IAssetData>({
        data: allAssetDataArray,
        label: {
            title: 'All'
        }
    });

    const data = useMemo(() => {
        let sumOfOtherArr = 0;
        let sum = 0;

        list.forEach((item: IAssetAllocationData) => {
            sum += item.amount_in_usd;
        });

        setOverallSum(sum);
        const tempAllArr: IAssetAllocationData[] = [];
        const tempOtherArr: IAssetAllocationData[] = [];

        const colorsArray = list.map(
            (item: IAssetAllocationData, index: number) => {
                let percentage = (item.amount_in_usd / sum) * 100;
                if (
                    showOtherCategory === false ||
                    percentage > assetAllocation
                ) {
                    tempAllArr.push(item);
                } else {
                    sumOfOtherArr += item.amount_in_usd;
                    tempOtherArr.push(item);
                }
                return getRandomColorUsingIndex(index);
            }
        );

        showOtherCategory === true &&
            tempAllArr.push({
                amount: 0,
                amount_in_btc: 0,
                cost_basis_lifo: 0,
                cost_basis_fifo: 0,
                amount_in_usd: sumOfOtherArr,
                id: '',
                title: 'Other'
            });

        setAllAssetDataArray(tempAllArr);
        setOtherArr(tempOtherArr);

        const assetDataSorted = assetData?.data?.reverse() ?? [];

        return {
            labels: assetDataSorted.map(
                (value: IAssetAllocationData) => value.title
            ),
            datasets: [
                {
                    label: '',
                    data: assetDataSorted.map(
                        (value: IAssetAllocationData) => value.amount_in_usd
                    ),
                    backgroundColor: [
                        ...colorsArray,
                        getRandomColorUsingIndex(colorsArray.length)
                    ],
                    borderColor: [
                        ...colorsArray,
                        getRandomColorUsingIndex(colorsArray.length)
                    ],
                    borderWidth: 1
                }
            ]
        };
    }, [holdingAllocationData, assetData]);

    const handleOnLabel = (title: string) => {
        if (title === 'Other')
            setAssetData({
                data: otherArr,
                label: { title: otherArr?.[0]?.title }
            });
        else if (title === 'All') {
            setAssetData({
                data: allAssetDataArray,
                label: { title: allAssetDataArray?.[0]?.title }
            });
        } else {
            const filteredData = list.filter((value) => value.title === title);
            setAssetData({ data: filteredData });
        }
    };

    useEffect(() => {
        firstMount.current === false && handleOnLabel('All');
        firstMount.current = true;
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div
                className={
                    pieChartSize === 'small'
                        ? styles.pieContainerSmall
                        : styles.pieContainerLarge
                }
            >
                <Pie
                    data={data}
                    options={{
                        plugins: {
                            legend: {
                                display: showOtherCategory ? true : false
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: function (context: any) {
                                        const labelData = context?.label ?? '';

                                        return ` ${labelData}: ${currencyToUSD(
                                            context.parsed
                                        )} | ${fixedDecimal(
                                            (context.parsed / overallSum) * 100,
                                            2
                                        )}%`;
                                    }
                                }
                            }
                        }
                    }}
                />
            </div>
            {showOtherCategory && (
                <div>
                    {['All', 'Other'].map((value: string, index: number) => {
                        return (
                            <React.Fragment key={`${value}-${index}`}>
                                <br />
                                <button
                                    onClick={() => handleOnLabel(value)}
                                    className={styles.btn}
                                >
                                    {value}
                                </button>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default AssetAllocation;
