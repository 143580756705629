import React, { useEffect, useState } from 'react';
import { IUserResponse } from '../interfaces/user';
import {
    LS_ACCESS_TOKEN_KEY,
    LS_ACCOUNT_KEY,
    LS_REFRESH_TOKEN_KEY,
    LS_USER_KEY
} from '../utils/constants.util';
import {
    setEncValue,
    getLocalValue,
    removeLocalValue,
    setLocalValue
} from '../utils/local-storage.util';

type AuthContextType = {
    authToken?: string | null;
    login: (token: string, refreshToken: string, preSetup: Function) => void;
    logout: (callback: Function) => void;
    hasTransactions: boolean;
    setHasTransactionsFlag: Function;
    user?: IUserResponse;
    isLoggedIn: () => boolean;
};

let AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: React.FC = ({ children }) => {
    const [authToken, setAuthToken] = useState(
        getLocalValue(LS_ACCESS_TOKEN_KEY) ?? ''
    );
    const [user, setUser] = useState<IUserResponse>(
        getLocalValue(LS_USER_KEY) && JSON.parse(getLocalValue(LS_USER_KEY))
    );
    const [hasTransactions, setHasTransactions] = useState<boolean>(() => {
        if (getLocalValue(LS_USER_KEY)) {
            const user: IUserResponse = JSON.parse(getLocalValue(LS_USER_KEY));
            return user.hasTransactions ?? false;
        } else {
            return false;
        }
    });

    const setHasTransactionsFlag = (flag: boolean) => {
        const user: IUserResponse = JSON.parse(getLocalValue(LS_USER_KEY));
        setLocalValue(
            LS_USER_KEY,
            JSON.stringify({ ...user, hasTransactions: flag })
        );
        setHasTransactions(flag);
    };

    const login = async (
        token: string,
        refreshToken: string,
        preSetup: Function
    ) => {
        const data = await preSetup();
        const userData = data.whoami;
        const hasTransactions = data.hasTransactions;
        setLocalValue(
            LS_USER_KEY,
            JSON.stringify({ ...userData, hasTransactions })
        );
        setEncValue(LS_ACCESS_TOKEN_KEY, token);
        setEncValue(LS_REFRESH_TOKEN_KEY, refreshToken);
        setAuthToken(token);
        setHasTransactions(hasTransactions);
        setUser({ ...userData, hasTransactions });
    };

    const logout = (callback: Function) => {
        removeLocalValue(LS_ACCESS_TOKEN_KEY);
        removeLocalValue(LS_USER_KEY);
        removeLocalValue(LS_REFRESH_TOKEN_KEY);
        removeLocalValue(LS_ACCOUNT_KEY);
        setAuthToken('');
        callback();
    };

    const isLoggedIn = () => {
        return (
            authToken !== undefined && authToken !== null && authToken !== ''
        );
    };

    const auth: AuthContextType = {
        isLoggedIn,
        authToken,
        user,
        login,
        logout,
        hasTransactions,
        setHasTransactionsFlag
    };

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};
