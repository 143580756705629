import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth.hook';
import { useDataSetting } from '../../hooks/useDataSetting.hooks';
import { TABLET_SIZE } from '../../utils/constants.util';
import AccountSideBarPage from '../Layout/AccountSidebar';
import SideBar from '../Layout/SideBar';
import styles from './Drawer.module.css';

export interface IDrawerProps {
    activeItem?: string;
}

const DrawerPage = ({ activeItem }: IDrawerProps) => {
    const auth = useAuth();

    const { navbarClose, navbarOpen, setNavbarClose, setNavbarOpen } =
        useDataSetting();
    const [width, setWidth] = useState(window.innerWidth);

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen);
    };
    const handleCloseTab = () => {
        setNavbarClose(!navbarClose);
    };
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width]);

    return (
        <>
            {width < TABLET_SIZE && (
                <button onClick={handleToggle} className={styles.btn}>
                    <MenuUnfoldOutlined className={styles.btn_icon} />
                </button>
            )}

            <Drawer
                placement='left'
                closable={false}
                onClose={handleCloseTab}
                visible={navbarOpen}
                // open={navbarOpen}
            >
                <div>
                    <button onClick={handleToggle} className={styles.btn}>
                        <MenuFoldOutlined className={styles.btn_icon} />
                    </button>
                </div>
                {auth.hasTransactions ? (
                    <SideBar activeItem={activeItem} />
                ) : (
                    <AccountSideBarPage activeItem={activeItem} />
                )}
            </Drawer>
        </>
    );
};

export default DrawerPage;
