import React, { useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
    currencyToUSD,
    fixedDecimal,
    getRandomColorUsingIndex
} from '../../../utils/other.util';
import styles from './FeeAllocation.module.css';
import { IFeeAllocationData, IFeeAllocationProps } from './index.type';

const AssetAllocation: React.FC<IFeeAllocationProps> = ({
    holdingAllocationData = [],
    pieChartSize = 'small',
    showLegend = true
}: IFeeAllocationProps) => {
    const list = holdingAllocationData ?? [];
    const [overallSum, setOverallSum] = useState<number>(0);

    const data = useMemo(() => {
        let sum = 0;
        const colorsArray = list.map(
            (item: IFeeAllocationData, index: number) => {
                sum += item.fee;
                return getRandomColorUsingIndex(index);
            }
        );

        setOverallSum(sum);

        return {
            labels: holdingAllocationData.map(
                (value: IFeeAllocationData) => value.asset
            ),
            datasets: [
                {
                    label: '',
                    data: holdingAllocationData.map((value: any) => value.fee),
                    backgroundColor: [...colorsArray],
                    borderColor: [...colorsArray],
                    borderWidth: 1
                }
            ]
        };
    }, [holdingAllocationData]);

    return (
        <div className={styles.mainContainer}>
            <div
                className={
                    pieChartSize === 'small'
                        ? styles.pieContainerSmall
                        : styles.pieContainerLarge
                }
            >
                <Pie
                    data={data}
                    options={{
                        plugins: {
                            legend:{
                                display: showLegend?true:false
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: function (context: any) {
                                        const labelData = context?.label ?? '';

                                        return ` ${labelData}: ${currencyToUSD(
                                            context.parsed
                                        )} | ${fixedDecimal(
                                            (context.parsed / overallSum) * 100,
                                            2
                                        )}%`;
                                    }
                                }
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default AssetAllocation;
