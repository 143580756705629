import { fetchWithGet } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';

import { DEFAULT_PAGE_SIZE } from '../utils/constants.util';

export const getTransaction = async (
    user_id: any,
    page: Number,
    size: Number = DEFAULT_PAGE_SIZE,
    ascending: boolean = false,
    account_id: string | null = null,
    duration: string = 'as_of_date',
    transaction_type_id: string | undefined | null = '',
    token?: string
): Promise<IResponse<any>> => {
    let url = `transaction?page=${page}&size=${size}&user_id=${user_id}&ascending=${ascending}&duration=${duration}`;
    let response = null;

    if (account_id != null && account_id !== '')
        url += `&account_id=${account_id}`;
    if (
        transaction_type_id !== null &&
        transaction_type_id !== undefined &&
        transaction_type_id !== ''
    )
        url += `&transaction_type_id=${transaction_type_id}`;

    if (token) {
        response = await fetchWithGet(url, {
            Authorization: `Bearer ${token}`
        });
    } else response = await fetchWithGet(url);

    return response;
};
export const getTransactionById = async (
    transaction_id: string
): Promise<IResponse<any>> => {
    let url = `transaction/${transaction_id}`;
    let response = null;
    response = await fetchWithGet(url);

    return response;
};
export const getTransactionList = async (): Promise<IResponse<any>> => {
    let url = `transaction-type?page=1`;
    let response = null;
    response = await fetchWithGet(url);

    return response;
};
