import { Spin } from 'antd';
import { ISpinnerProps } from './index.type';
import styles from './Spinner.module.css';

const Spinner = (props: ISpinnerProps) => {
    return (
        <div className={`${styles.spinner}`}>
            <Spin />
        </div>
    );
};

export default Spinner;
