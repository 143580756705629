import React from 'react';
import { CustomInputProps } from './CustomInput.type';
import styles from './CustomInput.module.css';

const CustomInput: React.FC<CustomInputProps> = ({
    name = '',
    withLabel = false,
    label = '',
    placeholder = '',
    register = () => {},
    type = 'text',
    onChange
}: CustomInputProps) => {
    return (
        <div className={styles.inputWrapper}>
            {withLabel ? (
                <label className={styles.label}>{label}</label>
            ) : (
                <></>
            )}
            <input
                className={styles.inputStyles}
                type={type}
                placeholder={placeholder}
                ref={register}
                onChangeCapture={onChange}
                {...register(name)}
            />
        </div>
    );
};

export default CustomInput;
