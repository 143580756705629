import { Button } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { resendPhoneOtp } from '../../../api/otp';
import { verifyPhoneByOtp } from '../../../api/registration';
import { OTP_MAX_ATTEMPT, OTP_MAX_DIGIT } from '../../../utils/constants.util';
import { showInfoToast, showToast } from '../../../utils/notifications.util';
import CustomButton from '../../CustomButton';
import CustomInput from '../../CustomInput';
import TimerComponent from '../../Timer';
import styles from './VerificationForm.module.css';
import './index.css';
type Inputs = {
    OTP: number;
};

const VerificationForm = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [attempt, setAttempt] = useState<number>(parseInt(OTP_MAX_ATTEMPT));
    const [showResendButton, setShowResendButton] = useState<boolean>(false);
    const [timeInMinutes, setTimeInMinutes] = useState<number>(1);
    const [otpDigit, setOtpDigit] = useState<number>();

    const { register, handleSubmit } = useForm<Inputs>();

    const { push } = useHistory();

    const handleEmailFormSubmit = async (obj: any) => {
        setLoading(true);
        const newData = {
            otp: obj.OTP,
            otp_token: props.otpToken
        };
        try {
            const result = await verifyPhoneByOtp(newData);
            if (result.success) {
                push(`/create-account/${result.data.user_id}`);
                showToast(true, result.message);
                showToast(true, 'Please setup your account!');
            } else {
                showToast(false, result.message);
            }
        } catch (error) {
            console.log('error', error);
            showToast(false, 'Something went wrong');
        }
        setLoading(false);
    };

    const handleResendOpt = async () => {
        const newData = { otp_token: props.otpToken };
        setShowResendButton(false);
        try {
            setAttempt(attempt - 1);

            if (attempt - 1 >= 0) {
                setTimeInMinutes(1);
                const result = await resendPhoneOtp(newData);
                if (result.success) {
                    // showInfoToast(`OTP: ${result.data.otp} ${result.message}`);
                } else {
                    showToast(false, result.message);
                }
            } else {
                push('/login');
                showToast(false, "You've reached the maximum attempts");
            }
        } catch (error) {
            showToast(false, 'Something went wrong.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtpDigit(e.currentTarget.value.length);
    };

    return (
        <form
            onSubmit={handleSubmit(handleEmailFormSubmit)}
            className='formContainer'
        >
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <h3 className='stepVerification'>Second Step Verification</h3>
                <div style={{ display: 'inline' }}>
                    <img
                        className='verificationImg'
                        src='https://static.thenounproject.com/png/1241744-200.png'
                        alt='otpImage'
                    />
                    <p className='verificationCode'>
                        Enter the verification code we sent to
                        <h3 className='headingColor'>{props.phoneNumber}</h3>
                    </p>
                </div>

                <CustomInput
                    withForm={true}
                    name='OTP'
                    withLabel={false}
                    label=''
                    placeholder='Type code here'
                    type='number'
                    onChange={handleChange}
                    register={register}
                />
                <br />

                {otpDigit === JSON.parse(OTP_MAX_DIGIT) ? (
                    <CustomButton
                        withForm={true}
                        name='Submit'
                        loading={loading}
                        // disabled={showResendButton}
                    />
                ) : (
                    <p className='error'>
                        Please enter {OTP_MAX_DIGIT} OTP digits
                    </p>
                )}
                <span
                    className={`${styles.otpResendTimer} ${
                        showResendButton === false ? 'd-none' : 'd-initial'
                    }`}
                >
                    Didn’t get the code?{'  '}
                    <Button
                        onClick={() => handleResendOpt()}
                        className={styles.btn}
                    >
                        Resend
                    </Button>
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        marginTop: '2.5px'
                    }}
                >
                    <span
                        className={`${styles.otpResendTimer} ${
                            showResendButton === true ? 'd-none' : 'd-initial'
                        }`}
                    >
                        Resend in{' '}
                        <TimerComponent
                            reRender={showResendButton}
                            minutes={timeInMinutes}
                            handleEnd={() => setShowResendButton(true)}
                        />
                    </span>
                </div>
                <span
                    className={`${styles.remainingAttempts} ${
                        showResendButton === true ? 'd-none' : 'd-initial'
                    }`}
                >
                    (Attempts Remaining: {attempt})
                </span>
            </div>
        </form>
    );
};

export default VerificationForm;
