import { notification } from 'antd';
import { ConfigProps } from 'antd/lib/notification';

const defaultConfig: ConfigProps = {
    placement: 'bottomRight',
    closeIcon: true
};

const showToast = (status: boolean, message: string) => {
    notification.config({
        ...defaultConfig,
        duration: 1.5
    });

    status
        ? notification.success({ message })
        : notification.error({ message: message });
};

const showInfoToast = (message: string) => {
    notification.config({ ...defaultConfig, duration: 4 });
    notification.info({ message });
};

export { showToast, showInfoToast };
