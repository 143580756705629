import { fetchWithGet } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';

import { DEFAULT_PAGE_SIZE } from '../utils/constants.util';

export const getHoldingList = async (
    id: any,
    page: Number,
    size: Number = DEFAULT_PAGE_SIZE
): Promise<IResponse<any>> => {
    const response = await fetchWithGet(
        `holding?user_id=${id}&page=${page}&size=${size}`
    );
    return response;
};
export const getHoldingAllocation = async (
    id: any,
    market_value: string | undefined,
    account: boolean = true,
    accountId: string | null = null
): Promise<IResponse<any>> => {
    let accountIdParams = accountId != null ? `&account_id=${accountId}` : '';
    const response = await fetchWithGet(
        `reports/asset_allocation?user_id=${id}&market_value_param=${market_value}&account=${account}${accountIdParams}`
    );
    return response;
};
