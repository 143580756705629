import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getAccounts } from '../../../api/account';
import { useAuth } from '../../../hooks/useAuth.hook';
import { IAccountData } from '../../../interfaces/account';
const { Option } = Select;

export interface IAccountRadioGroupProps {
    accountId: string | null;
    page?: string | null;
}

const AccountRadioGroup: React.FC<IAccountRadioGroupProps> = ({
    accountId = null,
    page = ''
}: IAccountRadioGroupProps) => {
    const auth = useAuth();
    const { push } = useHistory();

    const [accounts, setAccounts] = useState<IAccountData[]>([]);

    const { isLoading, isFetching, data } = useQuery<any, any>(
        ['Account'],
        () => getAccounts(1, 20)
    );

    useEffect(() => {
        const response: IAccountData[] = data?.data ?? null;
        if (!response || !data.success) return;

        setAccounts(response);
    }, [data]);

    return !isLoading && !isFetching ? (
        accounts.length > 1 ? (
            <Select
                style={{ width: '150px' }}
                defaultValue={accountId ?? ''}
                onChange={(option: string) => {
                    push(`/${page}/${option}`);
                }}
            >
                <Option value=''>Portfolio</Option>
                {accounts.map((item: IAccountData) => (
                    <Option value={item.id}>{item.display_name}</Option>
                ))}
            </Select>
        ) : (
            <></>
        )
    ) : (
        <></>
    );
};

export default AccountRadioGroup;
