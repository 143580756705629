import React, { useState } from 'react';
import { Alert, Button, Space } from 'antd';
import PhoneInput, {
    formatPhoneNumberIntl,
    isValidPhoneNumber
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { verifyPhoneByOtp, verifyPhoneNumber } from '../../../api/registration';
import { showToast } from '../../../utils/notifications.util';
import styles from './index.module.css';
import { IRegistrationProps } from './index.type';
import { useHistory } from 'react-router-dom';

const RegistrationForm: React.FC<IRegistrationProps> = ({
    setPhoneNumber,
    setCurrent,
    setOtpToken
}: IRegistrationProps) => {
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);
    const [phoneInput, setPhoneInput] = useState(null);

    const handleEmailFormSubmit = async (phone_number: any) => {
        setLoading(true);
        try {
            const result = await verifyPhoneNumber({ phone_number });
            if (result.success) {
                setCurrent(1);
                setPhoneNumber(phone_number);
                setOtpToken(result.data.otp_token);
                //     const newData = {
                //         otp: '000000',
                //         otp_token: result.data.otp_token
                //     };
                //     const response = await verifyPhoneByOtp(newData);

                //     if (response.success) {
                //         push(`/create-account/${response.data.user_id}`);
                //         showToast(true, 'Please setup your account!');
                //     } else {
                //         showToast(false, response.message);
                //     }
            } else {
                showToast(false, result.message);
            }
        } catch (error) {
            showToast(false, 'Something went wrong');
        }

        setLoading(false);
    };

    return (
        <div>
            <h2 className={styles.Heading}>Registration</h2>
            <Space
                direction='vertical'
                size='large'
                className={styles.mainRegistrationSpace}
                style={{ width: '100%' }}
            >
                <div className={styles.textColor}>
                    Fill in the registration data. It will take a couple of
                    minutes. All you need is a phone number and e-mail
                </div>

                <Alert
                    className={styles.Alert}
                    message='We take privacy issues seriously. You can be sure that your personal data is securely protected.'
                    type='info'
                    showIcon
                />

                <div className={styles.phoneFieldContainer}>
                    <p className={styles.phoneFieldContainerHeading}>
                        Enter your phone number
                    </p>

                    <PhoneInput
                        name='phone_number'
                        defaultCountry='US'
                        placeholder='XXXXX--XX--XXX'
                        value={phoneInput ?? ''}
                        onChange={(e: any) => setPhoneInput(e)}
                        error={
                            phoneInput
                                ? isValidPhoneNumber(phoneInput)
                                : 'Phone number is required'
                        }
                    />
                </div>

                {phoneInput && isValidPhoneNumber(phoneInput) ? (
                    <Button
                        type='primary'
                        loading={loading}
                        className={styles.btn}
                        onClick={() => handleEmailFormSubmit(phoneInput)}
                    >
                        Next
                    </Button>
                ) : phoneInput != null && phoneInput !== '' ? (
                    <div className={styles.footerText}>
                        Phone number is Invalid
                    </div>
                ) : (
                    <></>
                )}
            </Space>
        </div>
    );
};

export default RegistrationForm;
