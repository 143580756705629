import React, { useEffect } from 'react';
import SideBar from './SideBar';

import TopNavBar from './TopNavbar';
import styles from './index.module.css';
import AccountSideBarPage from './AccountSidebar';
import { useAuth } from '../../hooks/useAuth.hook';

const Layout = ({ activeItem, topNavTitle, children }: any) => {
    const auth = useAuth();

    useEffect(() => {}, [auth.hasTransactions]);

    return (
        <div className={styles.layout}>
            {auth.hasTransactions ? (
                <SideBar activeItem={activeItem} />
            ) : (
                <AccountSideBarPage activeItem={activeItem} />
            )}
            <div className={styles.layoutInner}>
                <TopNavBar title={topNavTitle} activeItem={activeItem}/>
                <div className={styles.layoutContent}>{children}</div>
            </div>
        </div>
    );
};

export default Layout;
