import { message, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPosition } from '../../api/reports';
import CardHeader from '../../components/Common/CardHeader';
import DataTable from '../../components/DataTable';
import Spinner from '../../components/Spinner';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import { useAuth } from '../../hooks/useAuth.hook';
import {
    COST_BASIS_METHOD_ENUM,
    POSITION_TABLE_COLUMN,
    POSITION_TABLE_FILTERS
} from '../../utils/constants.util';
import { currencyToUSD, fixedDecimal } from '../../utils/other.util';
import { IPositionData } from './index.type';
import styles from './Position.module.css';
const { Option } = Select;

let PositionColumns = [
    {
        title: 'Name',
        dataIndex: 'asset',
        key: 'asset',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.asset.localeCompare(b.asset);
        }
    },
    {
        title: 'Quantity',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.amount - b.amount;
        },
        render: (amount: number) => {
            return amount >= 0 ? (
                `${fixedDecimal(amount, 4)}`
            ) : (
                <span style={{ color: 'red' }}>
                    {`${fixedDecimal(amount, 4)}`}
                </span>
            );
        }
    },

    {
        title: 'Last price',
        dataIndex: 'previous_rate',
        key: 'previous_rate',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.previous_rate - b.previous_rate;
        },
        render: (previous_rate: number) => {
            return previous_rate >= 0 ? (
                currencyToUSD(previous_rate)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(previous_rate)}
                </span>
            );
        }
    },
    {
        title: '$ Change',
        dataIndex: 'change_in_dollars',
        key: 'change_in_dollars',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.change_in_dollars - b.change_in_dollars;
        },
        render: (change_in_dollars: number) => {
            return change_in_dollars >= 0 ? (
                currencyToUSD(change_in_dollars)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(change_in_dollars)}
                </span>
            );
        }
    },
    {
        title: '% Change',
        dataIndex: 'change_in_percentage',
        key: 'change_in_percentage',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.change_in_percentage - b.change_in_percentage;
        },
        render: (change_in_percentage: number) => {
            return change_in_percentage >= 0 ? (
                `${fixedDecimal(change_in_percentage)}%`
            ) : (
                <span style={{ color: 'red' }}>
                    {`${fixedDecimal(change_in_percentage)}%`}
                </span>
            );
        }
    },
    {
        title: 'Current Value',
        dataIndex: 'current_value',
        key: 'current_value',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.current_value - b.current_value;
        },
        render: (current_value: number) => {
            return current_value >= 0 ? (
                currencyToUSD(current_value)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(current_value)}
                </span>
            );
        }
    },

    {
        title: '$ Daily gain/loss',
        dataIndex: 'daily_gain_loss_dollars',
        key: 'daily_gain_loss_dollars',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.daily_gain_loss_dollars - b.daily_gain_loss_dollars;
        },
        render: (daily_gain_loss_dollars: number) => {
            return daily_gain_loss_dollars >= 0 ? (
                currencyToUSD(daily_gain_loss_dollars)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(daily_gain_loss_dollars)}
                </span>
            );
        }
    },
    {
        title: '% Daily gain/loss',
        dataIndex: 'daily_gain_loss_percentage',
        key: 'daily_gain_loss_percentage',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.daily_gain_loss_percentage - b.daily_gain_loss_percentage;
        },
        render: (daily_gain_loss_percentage: number) => {
            return daily_gain_loss_percentage >= 0 ? (
                `${fixedDecimal(daily_gain_loss_percentage)}%`
            ) : (
                <span style={{ color: 'red' }}>
                    {`${fixedDecimal(daily_gain_loss_percentage)}%`}
                </span>
            );
        }
    },
    {
        title: '$ Total gain/loss',
        dataIndex: 'total_gain_loss_dollars',
        key: 'total_gain_loss_dollars',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.total_gain_loss_dollars - b.total_gain_loss_dollars;
        },
        render: (total_gain_loss_dollars: number) => {
            return total_gain_loss_dollars >= 0 ? (
                currencyToUSD(total_gain_loss_dollars)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(total_gain_loss_dollars)}
                </span>
            );
        }
    },
    {
        title: '% Total gain/loss',
        dataIndex: 'total_gain_loss_percentage',
        key: 'total_gain_loss_percentage',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.total_gain_loss_percentage - b.total_gain_loss_percentage;
        },
        render: (total_gain_loss_percentage: number) => {
            return total_gain_loss_percentage >= 0 ? (
                `${fixedDecimal(total_gain_loss_percentage)}%`
            ) : (
                <span style={{ color: 'red' }}>
                    {`${fixedDecimal(total_gain_loss_percentage)}%`}
                </span>
            );
        }
    },
    {
        title: 'Cost basis per share',
        dataIndex: 'cost_basis_per_share',
        key: 'cost_basis_per_share',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.cost_basis_per_share - b.cost_basis_per_share;
        },
        render: (cost_basis_per_share: number) => {
            return cost_basis_per_share >= 0 ? (
                currencyToUSD(cost_basis_per_share)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(cost_basis_per_share)}
                </span>
            );
        }
    },
    {
        title: 'Cost basis total',
        dataIndex: 'cost_basis_total',
        key: 'cost_basis_total',
        sorter: (a: IPositionData, b: IPositionData) => {
            return a.cost_basis_total - b.cost_basis_total;
        },
        render: (cost_basis_total: number) => {
            return cost_basis_total >= 0 ? (
                currencyToUSD(cost_basis_total)
            ) : (
                <span style={{ color: 'red' }}>
                    {currencyToUSD(cost_basis_total)}
                </span>
            );
        }
    }
];
interface IPositionProps {
    showMethodSelection: boolean;
}
const PositionComponent = ({ showMethodSelection = false }: IPositionProps) => {
    const auth = useAuth();
    let { accountId }: any = useParams();
    const { costBasis } = useAppSetting();
    const [loading, setLoading] = useState(false);
    const [positionData, setPositionData] = useState<IPositionData[]>([]);
    const [method, setMethod] = useState(costBasis);
    const [filter, setFilter] = useState('none');
    const [filterColumn, setFilterColumn] = useState<string[]>([]);
    const [positionFilteredData, setPositionFilteredData] = useState<
        IPositionData[]
    >([]);

    const filteredColumns = PositionColumns.filter(
        (item) => !filterColumn.includes(item.dataIndex)
    );

    if (accountId === undefined) accountId = null;
    const getAllPosition = async () => {
        setLoading(true);
        try {
            const result = await getPosition(
                auth.user?.user?.id,
                method,
                accountId
            );
            if (result.success) {
                setPositionData(result.data);
                setPositionFilteredData(result.data);
            } else {
                message.error(result.message);
            }
        } catch (error) {
            message.error('Something went wrong!!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPosition();
    }, [method]);

    useEffect(() => {
        let positionDataTemp: IPositionData[] = [];
        if (filter === 'none') {
            positionDataTemp = [...positionData];
        } else if (filter === 'performer') {
            positionDataTemp = [
                ...positionData.sort((a: IPositionData, b: IPositionData) => {
                    return (
                        b.total_gain_loss_dollars - a.total_gain_loss_dollars
                    );
                })
            ];
        } else if (filter === 'gainer') {
            positionDataTemp = [
                ...positionData.sort((a: IPositionData, b: IPositionData) =>
                    a.change_in_dollars < b.change_in_dollars ? 1 : -1
                )
            ];
        } else if (filter === 'loser') {
            positionDataTemp = [
                ...positionData.sort((a: IPositionData, b: IPositionData) =>
                    a.change_in_dollars < b.change_in_dollars ? -1 : 1
                )
            ];
        } else if (filter === 'volume') {
            positionDataTemp = [
                ...positionData.sort((a: IPositionData, b: IPositionData) =>
                    a.amount < b.amount ? 1 : -1
                )
            ];
        }
        setPositionFilteredData(positionDataTemp);
    }, [filter]);

    return loading ? (
        <div
            className={styles.loaderBackground}
            style={{
                width: '100%',
                padding: '2rem'
            }}
        >
            <Spinner />
        </div>
    ) : (
        <>
            <div>
                <CardHeader title={''} isOverviewHeader={false}>
                    <Space>
                        {showMethodSelection ? (
                            <Select
                                className={`${styles.antspaceitem} ${styles.antselect}`}
                                value={filter}
                                onChange={(targetValue) =>
                                    setFilter(targetValue)
                                }
                            >
                                {POSITION_TABLE_FILTERS.map(
                                    (elm: { [key: string]: string }) => (
                                        <Option
                                            key={elm.value}
                                            value={elm.value}
                                        >
                                            {elm.label}
                                        </Option>
                                    )
                                )}
                            </Select>
                        ) : (
                            <></>
                        )}
                        {showMethodSelection ? (
                            <Select
                                value={filterColumn}
                                placeholder='Exclude Columns'
                                className={`${styles.antspaceitem} ${styles.antselect}`}
                                mode='tags'
                                onChange={(targetValue) =>
                                    setFilterColumn(targetValue)
                                }
                            >
                                {POSITION_TABLE_COLUMN.map(
                                    (elm: { [key: string]: string }) => (
                                        <Option
                                            key={elm.value}
                                            value={elm.value}
                                        >
                                            {elm.label}
                                        </Option>
                                    )
                                )}
                            </Select>
                        ) : (
                            <></>
                        )}
                        {showMethodSelection ? (
                            <Select
                                value={method}
                                onChange={(targetValue) =>
                                    setMethod(targetValue)
                                }
                            >
                                {COST_BASIS_METHOD_ENUM.map(
                                    (elm: { [key: string]: string }) => (
                                        <Option
                                            key={elm.value}
                                            value={elm.value}
                                        >
                                            {elm.label}
                                        </Option>
                                    )
                                )}
                            </Select>
                        ) : (
                            <></>
                        )}
                    </Space>
                </CardHeader>
                <DataTable
                    size='small'
                    pagination={false}
                    data={positionFilteredData
                        .filter((data: IPositionData, index: number) => {
                            if (filter === 'none') {
                                return data;
                            }
                            if (filter !== 'none' && index < 10) {
                                return data;
                            }
                        })
                        .map((elem: IPositionData, index: number) => {
                            return {
                                ...elem
                            };
                        })}
                    columnData={filteredColumns}
                />
            </div>
        </>
    );
};

export default PositionComponent;
