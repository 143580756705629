import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityChart from '../../components/Chart/ActivityChart';
import DateSelector from '../../components/DateSelector';
import Layout from '../../components/Layout';

import styles from './summary.module.css';

const SummaryPage = () => {
    const [duration, setDuration] = useState('as_of_date');
    const { accountId }: any = useParams();

    useEffect(() => {}, [duration]);

    return (
        <Layout activeItem='Activity Summary'>
            <DateSelector
                {...{
                    title: 'Activity Summary',
                    duration,
                    setDuration,
                    page: 'summary'
                }}
            />
            <div className={styles.mainContainer}>
                <ActivityChart
                    label={''}
                    showTable={true}
                    duration={duration}
                    size={'large'}
                    accountId={accountId}
                    isOverviewHeader={false}
                />
            </div>
        </Layout>
    );
};

export default SummaryPage;
