import { fetchWithPost, fetchWithGet } from '../utils/fetch.utils';
import IResponse from '../interfaces/handle-response.interface';
import {
    IForgetPasswordByEmail,
    ILoginData,
    ILoginResponse,
    IResetPasswordData
} from '../interfaces/auth';
import { IUserResponse } from '../interfaces/user';

export const login = async (
    data: ILoginData
): Promise<IResponse<ILoginResponse>> => {
    const response = await fetchWithPost(`login/customer`, data);
    return response;
};

export const whoami = async (
    token: string
): Promise<IResponse<IUserResponse>> => {
    const response = await fetchWithGet('whoami', {
        Authorization: `Bearer ${token}`
    });
    return response;
};

export const forgetPasswordByEmail = async (
    email: string
): Promise<IResponse<IForgetPasswordByEmail>> => {
    const response = await fetchWithPost(`forgot-password?email=${email}`, {});
    return response;
};

export const resetPassword = async (
    data: IResetPasswordData
): Promise<IResponse<IResetPasswordData>> => {
    const response = await fetchWithPost(`reset-password`, data);
    return response;
};
