import { Button } from 'antd';
import React from 'react';
import { CustomButtonProps } from './CustomButton.type';
import styles from './CustomButton.module.css';

const CustomButton: React.FC<CustomButtonProps> = ({
    withForm = false,
    name = '',
    onClick,
    loading,
    disabled = false,
    danger = false
}: CustomButtonProps) => {
    return withForm ? (
        <div className={styles.ButtonWrapper}>
            <Button
                htmlType='submit'
                block={true}
                type={'primary'}
                onClick={onClick}
                loading={loading}
                className={styles.button}
                disabled={disabled}
            >
                {name}
            </Button>
        </div>
    ) : (
        <Button type={'primary'} onClick={onClick} danger={danger}>
            {name}
        </Button>
    );
};

export default CustomButton;
