import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHoldingAllocation } from '../../api/holding';
import { IAssetAllocationData } from '../../components/Chart/AssetAllocation/index.type';

type InitialState = {
    loading: boolean;
    fulfilled: boolean;
    failed: boolean;
    fetching: boolean;
    accountData: IAssetAllocationData[];
    accountGraphData: IAssetAllocationData[];
    data: IAssetAllocationData[];
    graphData: IAssetAllocationData[];
};
const initialState: InitialState = {
    data: [],
    graphData: [],
    accountData: [],
    accountGraphData: [],
    fetching: true,
    loading: true,
    fulfilled: false,
    failed: false
};

export interface IFetchAssetAllocationProps {
    marketValue: string;
    userId: string;
    account?: boolean;
    accountId?: string;
}

export const fetchAssetAllocationAsync = createAsyncThunk(
    'assetAllocation/fetchAsync',
    async ({ marketValue, userId, accountId }: IFetchAssetAllocationProps) => {
        const resAssetLvl = await getHoldingAllocation(
            userId ?? '',
            marketValue ?? 'emv',
            false,
            accountId
        );

        let resAccountLvl = {
            success: false,
            data: []
        };

        // if (accountId == null) {
        resAccountLvl = await getHoldingAllocation(
            userId ?? '',
            marketValue ?? 'emv',
            true,
            accountId
        );
        // }

        return Object.freeze({
            assetData: resAssetLvl.success ? resAssetLvl.data : [],
            accountData: resAccountLvl.success ? resAccountLvl.data : []
        });
    }
);

export const assetAllocationSlice = createSlice({
    name: 'AssetAllocation',
    initialState,
    reducers: {
        getAssetAllocationGraphData: (state, action) => {
            // If the data is being fetched from the API
            if (!state.fetching) {
                state.graphData = [];
                return;
            }

            const prevData = state.data;
            const nonNegativeItems = prevData.filter(
                (item: IAssetAllocationData) => item.amount_in_usd >= 0
            );
        },
        resetAssetAllocationGraphData: (state, action) => {
            state = { ...initialState };
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchAssetAllocationAsync.pending, (state) => {
            state.fetching = true;
            state.loading = true;
        });
        builder.addCase(
            fetchAssetAllocationAsync.fulfilled,
            (state, action: PayloadAction<any>) => {
                const newPl = action.payload;

                const accountData: IAssetAllocationData[] = newPl.accountData;
                const assetData: IAssetAllocationData[] = newPl.assetData;

                const nonNegativeData = assetData.filter(
                    (item: IAssetAllocationData) => item.amount_in_usd > 0
                );

                const nonNegativeAccountData = [
                    ...accountData.filter(
                        (item: IAssetAllocationData) => item.amount_in_usd > 0
                    )
                ];

                const newState = {
                    ...state,
                    fetching: false,
                    failed: false,
                    fulfilled: true,
                    loading: false,
                    data: assetData,
                    graphData: nonNegativeData,
                    accountData: accountData,
                    accountGraphData: nonNegativeAccountData
                };

                return newState;
            }
        );
        builder.addCase(
            fetchAssetAllocationAsync.rejected,
            (state, action: PayloadAction<any>) => {
                return {
                    ...state,
                    failed: true,
                    fetching: false,
                    fulfilled: false,
                    loading: false,
                    data: initialState.data
                };
            }
        );
    }
});

export const { getAssetAllocationData, resetAssetAllocationGraphData }: any =
    assetAllocationSlice.actions;
export default assetAllocationSlice.reducer;
