import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { forgetPasswordByEmail } from '../../../api/auth';
import { showToast } from '../../../utils/notifications.util';
import CustomButton from '../../CustomButton';
import CustomInput from '../../CustomInput';
import styles from './ForgetPassword.module.css';
type Inputs = {
    email: string;
};

const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const { push } = useHistory();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<Inputs>();

    const handleEmailFormSubmit = async (obj: any) => {
        setLoading(true);
        const result = await forgetPasswordByEmail(obj.email);

        if (result.success) {
            push('/');
            showToast(true, result.message);
        } else {
            showToast(false, 'Invalid Credentials');
        }

        setLoading(false);
    };
    return (
        <form
            onSubmit={handleSubmit(handleEmailFormSubmit)}
            className='formContainer'
        >
            <CustomInput
                withForm={true}
                name='email'
                withLabel={true}
                label='EMAIL'
                register={register}
            />

            <br />

            <CustomButton withForm={true} name='Login' loading={loading} />
        </form>
    );
};

export default ForgetPassword;
