import React, { useEffect, useMemo } from 'react';
import { IAssetAllocationData } from '../../components/Chart/AssetAllocation/index.type';
import AssetAllocation from '../../components/Chart/AssetAllocation';
import DataTable from '../../components/DataTable';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth.hook';
import { useAppSetting } from '../../hooks/useAppSetting.hooks';
import { currencyToUSD } from '../../utils/other.util';
import styles from './index.module.css';
import { useAppDispatch, useAppSelector } from '../../app/redux-hooks';
import {
    fetchAssetAllocationAsync,
    IFetchAssetAllocationProps
} from '../../app/slices/assetAllocation.slice';
import Spinner from '../../components/Spinner';
import CardHeader from '../../components/Common/CardHeader';
import DateSelector from '../../components/DateSelector';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';

const pieChartColumns = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Quantity',
        dataIndex: 'amount',
        key: 'amount'
    },
    {
        title: 'Amount (USD)',
        dataIndex: 'amount_in_usd',
        key: 'amount_in_usd'
    }
];

interface RenderAllAccountDataProps {
    showOtherCategory: boolean;
    showTable: boolean;
    data: IAssetAllocationData[];
    tableData?: IAssetAllocationData[];
}

const RenderAllAccountData: React.FC<RenderAllAccountDataProps> = ({
    showOtherCategory = true,
    showTable = false,
    data = [],
    tableData = []
}: RenderAllAccountDataProps) => {
    return (
        <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
            <AssetAllocation
                pieChartSize='large'
                holdingAllocationData={data}
                showOtherCategory={showOtherCategory}
            />
            {showTable && (
                <DataTable
                    style={{
                        flex: 1
                    }}
                    data={tableData.map((dataItem: IAssetAllocationData) => {
                        return {
                            ...dataItem,
                            key: dataItem.id,
                            amount_in_usd: currencyToUSD(dataItem.amount_in_usd)
                        };
                    })}
                    columnData={pieChartColumns}
                />
            )}
        </Space>
    );
};

const CustodianAllocationPage = () => {
    const { marketValue } = useAppSetting();
    const { accountId }: any = useParams();

    const selector = useAppSelector((state) => state.assetAllocationSelector);

    const dispatch = useAppDispatch();

    const auth = useAuth();
    const userId = useMemo(() => auth.user?.user?.id, [auth]);

    useEffect(() => {
        dispatch(
            fetchAssetAllocationAsync({
                marketValue,
                userId,
                accountId
            } as IFetchAssetAllocationProps)
        );
    }, []);

    return (
        <Layout activeItem='Custodian Allocation'>
            <DateSelector
                {...{
                    title: 'Custodian Allocation',
                    duration: '',
                    page: 'custodian-allocation'
                }}
            />
            <div className={styles.mainContainer}>
                <CardHeader title='' isOverviewHeader={false} />

                {selector.loading ? (
                    <Spinner />
                ) : (
                    <div className={styles.mainChartContainer}>
                        <RenderAllAccountData
                            showOtherCategory={false}
                            data={selector.accountGraphData}
                            tableData={selector.accountData}
                            showTable={true}
                        />
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default CustodianAllocationPage;
